import gqlClient from 'gql';
import { openConfirmAction } from '@appclose/core';

import notification from 'controllers/notification';
import hellosign from 'controllers/hellosign';
import { HelloSignEvents } from 'classes/HelloSign';
import { i18nMessage } from 'i18n';

import {
  CancelSignatureMutation,
  CancelSignatureMutationVariables,
  FetchSignatureTemplateUrlMutation,
  FetchSignatureTemplateUrlMutationVariables,
  FetchSignatureUrlMutation,
  FetchSignatureUrlMutationVariables,
  RemoveSignatureTemplateMutation,
  RemoveSignatureTemplateMutationVariables,
  SendSignatureReminderMutation,
  SendSignatureReminderMutationVariables,
} from './__generated__/signature.gql';
import {
  CANCEL_SIGNATURE,
  SEND_SIGNATURE_REMINDER,
  FETCH_SIGNATURE_URL,
  REMOVE_SIGNATURE_TEMPLATE,
  FETCH_SIGNATURE_TEMPLATE_URL,
} from './signature.gql';

export const openCancelSignatureConfirmAction = (
  id: string,
  onSuccess?: () => void,
) =>
  openConfirmAction({
    name: i18nMessage('store.signature.cancelSignatureConfirm.name'),
    content: i18nMessage('store.signature.cancelSignatureConfirm.content'),
    okButtonTitle: i18nMessage(
      'store.signature.cancelSignatureConfirm.okButton',
    ),
    cancelButtonTitle: i18nMessage(
      'store.signature.cancelSignatureConfirm.cancelButton',
    ),
    okButtonSkin: 'brand',
    async onConfirm() {
      await gqlClient.mutate<
        CancelSignatureMutation,
        CancelSignatureMutationVariables
      >({
        mutation: CANCEL_SIGNATURE,
        variables: { id },
      });

      notification().info(
        i18nMessage('store.signature.cancelSignatureConfirm.notify'),
      );

      onSuccess?.();
    },
  });

export const openSendSignatureReminderConfirmAction = (id: string) =>
  openConfirmAction({
    name: i18nMessage('store.signature.sendSignatureReminderConfirm.name'),
    content: i18nMessage(
      'store.signature.sendSignatureReminderConfirm.content',
    ),
    okButtonTitle: i18nMessage(
      'store.signature.sendSignatureReminderConfirm.okButton',
    ),
    cancelButtonTitle: i18nMessage(
      'store.signature.sendSignatureReminderConfirm.cancelButton',
    ),
    okButtonSkin: 'brand',
    async onConfirm() {
      await gqlClient.mutate<
        SendSignatureReminderMutation,
        SendSignatureReminderMutationVariables
      >({ mutation: SEND_SIGNATURE_REMINDER, variables: { id } });

      notification().info(
        i18nMessage('store.signature.sendSignatureReminderConfirm.notify'),
      );
    },
  });

export const openEditSignatureConfirmAction = (id: string) =>
  openConfirmAction({
    name: i18nMessage('store.signature.editSignatureConfirm.name'),
    content: i18nMessage('store.signature.editSignatureConfirm.content'),
    okButtonSkin: 'brand',
    okButtonTitle: i18nMessage('store.signature.editSignatureConfirm.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.signature.editSignatureConfirm.cancelButton',
    ),
    onConfirm: async () => {
      const { data } = await gqlClient.mutate<
        FetchSignatureUrlMutation,
        FetchSignatureUrlMutationVariables
      >({
        mutation: FETCH_SIGNATURE_URL,
        variables: {
          id,
        },
      });

      hellosign().open(data?.signature.url as string);

      hellosign().onceOn(HelloSignEvents.FINISH, () =>
        notification().entityUpdated(
          i18nMessage('store.signature.editSignatureConfirm.notify'),
        ),
      );
    },
  });

export const openRemoveSignatureTemplateConfirmAction = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.signature.deleteSignature.name'),
    content: i18nMessage('store.signature.deleteSignature.content'),
    okButtonSkin: 'brand',
    okButtonTitle: i18nMessage('store.signature.deleteSignature.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.signature.deleteSignature.cancelButton',
    ),
    onConfirm: async () => {
      await gqlClient.mutate<
        RemoveSignatureTemplateMutation,
        RemoveSignatureTemplateMutationVariables
      >({ mutation: REMOVE_SIGNATURE_TEMPLATE, variables: { id } });

      notification().entityDeleted(
        i18nMessage('store.signature.deleteSignature.notification.deleted'),
      );

      onSuccess && onSuccess();
    },
  });

export const openEditSignatureTemplateConfirmAction = ({
  id,
}: {
  id: string;
}) =>
  openConfirmAction({
    name: i18nMessage('store.signature.editSignature.name'),
    content: i18nMessage('store.signature.editSignature.content'),
    okButtonSkin: 'brand',
    okButtonTitle: i18nMessage('store.signature.editSignature.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.signature.editSignature.cancelButton',
    ),
    onConfirm: async () => {
      const result = await gqlClient.mutate<
        FetchSignatureTemplateUrlMutation,
        FetchSignatureTemplateUrlMutationVariables
      >({ mutation: FETCH_SIGNATURE_TEMPLATE_URL, variables: { id } });

      hellosign().open(result.data?.template.templateUrl.url as string);

      hellosign().onceOn(HelloSignEvents.FINISH, () =>
        notification().entityUpdated(
          i18nMessage('store.signature.editSignature.notification.updated'),
        ),
      );
    },
  });
