import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { dateManager } from '@appclose/core';

import { FETCH_FIRM_TIME_ZONE } from './useTimeZone.gql';
import {
  FetchFirmTimeZoneQuery,
  FetchFirmTimeZoneQueryVariables,
} from './__generated__/useTimeZone.gql';
import { TimeZoneAbbreviation, TIME_ZONE_NAMES } from './useTimeZone.constants';
import { UseTimeZoneOptions } from './useTimeZone.types';

export default function useTimeZone(options?: UseTimeZoneOptions) {
  const { loading, data } = useQuery<
    FetchFirmTimeZoneQuery,
    FetchFirmTimeZoneQueryVariables
  >(FETCH_FIRM_TIME_ZONE, {
    skip: options?.skip,
  });

  const fullTimeZone = useMemo(
    () => data?.firm.timezone?.full || dateManager().getTimezone(),
    [data],
  );
  const shortTimeZone = useMemo(
    () => data?.firm.timezone?.short,
    [data],
  ) as TimeZoneAbbreviation;
  const humanReadableTimeZone = useMemo(() => {
    if (TIME_ZONE_NAMES[shortTimeZone]) {
      return TIME_ZONE_NAMES[shortTimeZone];
    }

    return fullTimeZone;
  }, [fullTimeZone, shortTimeZone]);

  return {
    loading,
    shortTimeZone,
    fullTimeZone,
    humanReadableTimeZone,
  };
}
