import cloneDeepWith from 'lodash/cloneDeepWith';
import some from 'lodash/some';

export function omitDeep(object: any, excludeKeys: string[]) {
  function omitFn(value: any | any[]) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key) => {
        delete value[key];
      });
    }
  }

  return cloneDeepWith(object, omitFn);
}

export function hasFile(object: any): boolean {
  function hasFile(value: any | any[]): boolean {
    if (value instanceof File || value instanceof Blob) {
      return true;
    }

    if (value && typeof value === 'object') {
      return some(value, hasFile);
    }

    return false;
  }

  return some(object, hasFile);
}

export function flattenObject(
  object: Record<string, any>,
): Record<string, any> {
  function flatterObjectInner(
    object: Record<string, any>,
    prefix?: string,
  ): Record<string, any> {
    const toReturn: Record<string, any> = {};
    prefix = prefix ? prefix + '.' : '';

    for (let i in object) {
      if (!object.hasOwnProperty(i)) {
        continue;
      }

      if (
        typeof object[i] === 'object' &&
        object[i] !== null &&
        !(object[i] instanceof Date) &&
        !(object[i] instanceof File) &&
        !(object[i] instanceof Blob)
      ) {
        Object.assign(toReturn, flatterObjectInner(object[i], prefix + i));
      } else {
        toReturn[prefix + i] = object[i];
      }
    }

    return toReturn;
  }

  return flatterObjectInner(object);
}
