import { useCallback, useMemo } from 'react';

import { DrivesType } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

export default function useGoogleDriveSync() {
  const { t } = useIntl();
  const myDrive = useMemo<Omit<DrivesType, '__typename'>>(
    () => ({
      id: 'myDrive',
      name: t('hook.useGoogleDriveSync.myDrive'),
    }),
    [t],
  );

  const normalizeDriveId = useCallback(
    (driveId?: string | null) =>
      driveId && driveId !== myDrive.id ? driveId : null,
    [myDrive.id],
  );

  return {
    myDrive,
    normalizeDriveId,
  };
}
