import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { TableRowPropsType } from './TableRow.types';
import styles from './TableRow.module.scss';

const TableRow = forwardRef<HTMLTableRowElement, TableRowPropsType>(
  ({ className, withHover = true, children, onClick }, ref) => {
    return (
      <tr
        className={classnames(
          styles.row,
          {
            [styles.clickable]: onClick,
            [styles.hover]: withHover,
          },
          className,
        )}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </tr>
    );
  },
);

export default TableRow;
