import { gql } from '@apollo/client';

export const CLIENT_LIST_ITEM_FRAGMENT = gql`
  fragment ClientListItemData on ClientType {
    id
    name
    email
    status
  }
`;

export const FETCH_CLIENT_LIST = gql`
  query FetchClientList {
    clients: listClients {
      items {
        ...ClientListItemData
      }
      total
    }
  }
  ${CLIENT_LIST_ITEM_FRAGMENT}
`;
