import { gql } from '@apollo/client';

const GOOGLE_DRIVE_SYNC_OPTIONS_FRAGMENT = gql`
  fragment GoogleDriveSyncOptions on FileStorageSyncOptionType {
    folderId
    driveId
    folderName
    syncType
    driveName
    fileSyncActive
    breadcrumbs {
      id
      name
    }
  }
`;

export const FETCH_GOOGLE_DRIVE_SYNC_OPTIONS = gql`
  query FetchGoogleDriveSyncOptions {
    options: getGoogleSyncOptions {
      ...GoogleDriveSyncOptions
    }
  }
  ${GOOGLE_DRIVE_SYNC_OPTIONS_FRAGMENT}
`;

export const CHECK_GOOGLE_FOLDER_NAME = gql`
  query checkGoogleFolderName($driveId: ID, $folderName: String!) {
    checkResult: googleCheckFolderName(
      googleCheckFolderNameInput: { driveId: $driveId, folderName: $folderName }
    ) {
      exists
    }
  }
`;

export const UPDATE_GOOGLE_DRIVE_SYNC_OPTIONS = gql`
  mutation UpdateGoogleDriveSyncOptions($input: UpdateSyncOptionsInput!) {
    updateGoogleFileStorageSyncOptions(updateSyncOptionsInput: $input) {
      ...GoogleDriveSyncOptions
    }
  }
  ${GOOGLE_DRIVE_SYNC_OPTIONS_FRAGMENT}
`;
