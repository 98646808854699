import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import { traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import hellosign from 'controllers/hellosign';
import { HelloSignEvents } from 'classes/HelloSign';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import { SignatureDocumentModalPropsType } from './SignatureDocumentModal.types';
import SignatureDocumentFromFile from './components/SignatureDocumentFromFile';
import {
  CANCEL_SIGNATURE,
  FINISH_SIGNATURE,
} from './SignatureDocumentModal.gql';
import styles from './SignatureDocumentModal.module.scss';
import {
  CancelSignatureMutation,
  CancelSignatureMutationVariables,
  FinishSignatureMutation,
  FinishSignatureMutationVariables,
} from './__generated__/SignatureDocumentModal.gql';
import { TourProvider } from 'components/common/Tour/TourProvider';
import { esignDocumentModalTourConfig } from './SignatureDocumentModalTour.config';
import { PermissionResources } from 'constants/permissions';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';

export default function SignatureDocumentModal({
  initialValuesFromFile,
  templateId,
  onClose,
}: SignatureDocumentModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.SIGNATURE,
    ActionType.CREATE,
  );
  const [signatureId, setSignatureId] = useState<string | null>(null);
  const [cancelSignature] = useMutation<
    CancelSignatureMutation,
    CancelSignatureMutationVariables
  >(CANCEL_SIGNATURE);
  const [finishSignature] = useMutation<
    FinishSignatureMutation,
    FinishSignatureMutationVariables
  >(FINISH_SIGNATURE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const onSignatureCreate = useCallback((id: string) => {
    setSignatureId(id);
  }, []);

  const isHandledRef = useRef(false);

  const onFinishSignature = useCallback(async () => {
    if (isHandledRef.current) {
      return;
    }

    isHandledRef.current = true;

    hellosign().off(HelloSignEvents.CLOSE, onCancelSignatureRef.current);
    hellosign().off(HelloSignEvents.FINISH, onFinishSignature);

    onClose();

    notification().entityCreated(
      t('modal.signatureDocument.notification.created'),
    );

    if (signatureId) {
      try {
        await finishSignature({ variables: { id: signatureId } });
      } catch (e) {
        traceError(e as Error);
      }
    }

    trackModalClose();
  }, [onClose, trackModalClose, t, signatureId, finishSignature]);

  const onCancelSignatureRef = useRef<() => Promise<void>>(async () => {});

  const onCancelSignature = useCallback(async () => {
    if (isHandledRef.current) {
      return;
    }

    isHandledRef.current = true;

    hellosign().off(HelloSignEvents.CLOSE, onCancelSignatureRef.current);
    hellosign().off(HelloSignEvents.FINISH, onFinishSignature);

    onClose();

    notification().error(
      new Error(t('modal.signatureDocument.notification.cancelled')),
    );

    if (signatureId) {
      try {
        await cancelSignature({ variables: { id: signatureId } });
      } catch (e) {
        traceError(e as Error);
      }
    }

    trackModalClose(ResolutionType.CANCEL);
  }, [
    onClose,
    trackModalClose,
    t,
    signatureId,
    cancelSignature,
    onFinishSignature,
  ]);

  onCancelSignatureRef.current = onCancelSignature;

  useEffect(() => {
    hellosign().on(HelloSignEvents.CLOSE, onCancelSignatureRef.current);
    hellosign().on(HelloSignEvents.FINISH, onFinishSignature);

    return () => {
      hellosign().off(HelloSignEvents.CLOSE, onCancelSignatureRef.current);
      hellosign().off(HelloSignEvents.FINISH, onFinishSignature);
    };
  }, [onCancelSignature, onFinishSignature]);

  return (
    <ModalPage
      className={classnames({
        [styles.hidden]: signatureId,
      })}
      onClose={onConfirmClose}
    >
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.signatureDocument.create.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <TourProvider config={esignDocumentModalTourConfig}>
          <SignatureDocumentFromFile
            initialValues={initialValuesFromFile}
            onCancel={onConfirmClose}
            onCreate={onSignatureCreate}
            onChange={onFormChange}
          />
        </TourProvider>
      </ModalPageContent>
    </ModalPage>
  );
}
