import { dateManager } from '@appclose/core';

export function serializer<T>(value: T) {
  return JSON.stringify(
    value
      ? {
          date: new Date().toISOString(),
          data: value,
        }
      : {},
  );
}

export function deserializer<T>(ttl: number) {
  return (value: string) => {
    if (!value) {
      return;
    }

    const data = JSON.parse(value);

    if (!data?.data || dateManager().parse().diff(data?.date, 'd') > ttl) {
      return;
    }

    return data?.data as T;
  };
}
