import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';

import { DiscountsAndRefundsStepValuesType } from './DiscountsAndRefundsStep.types';

export const DiscountsAndRefundsStepSchema =
  Yup.object().shape<DiscountsAndRefundsStepValuesType>({
    isEsqash: Yup.boolean().required(),
    refundItem: Yup.string()
      .nullable()
      .required(
        getI18nErrorMessage(
          'modal.qboIntegrationSetup.step.discountsAndRefunds.error.refundItem.required',
        ),
      ),
    discountEnabled: Yup.boolean(),
    creditCardFeesAccount: Yup.string()
      .nullable()
      .required(
        getI18nErrorMessage(
          'modal.qboIntegrationSetup.step.discountsAndRefunds.error.creditCardFeesAccount.required',
        ),
      ),
  });
