import * as Yup from 'yup';

import { CONTACTS_VALIDATION_SCHEMA } from 'schemas/validations/contacts';

import { EntityPopupFieldsetValuesType } from '../../ContactPopupForm.types';
import { CONTACT_ENTITY_NAME_VALIDATION_SCHEMA } from 'schemas/validations/contact';

export const EntityFieldsetSchema =
  Yup.object().shape<EntityPopupFieldsetValuesType>({
    name: CONTACT_ENTITY_NAME_VALIDATION_SCHEMA,
    billingContacts: CONTACTS_VALIDATION_SCHEMA.required(),
  });
