import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByBase64, downloadFileByUrl } from '@appclose/lib';
import { traceError } from '@appclose/core';
import { publicSession } from 'controllers/publicSession';

import useQboResync from 'hooks/useQboResync';

import {
  QboResyncCreditMemoMutation,
  QboResyncCreditMemoMutationVariables,
  QboResyncPaymentMutation,
  QboResyncPaymentMutationVariables,
  QboResyncRefundPaymentMutation,
  QboResyncRefundPaymentMutationVariables,
} from 'gql/shared/__generated__/qbo.gql';
import {
  QBO_RESYNC_CREDIT_MEMO,
  QBO_RESYNC_PAYMENT,
  QBO_RESYNC_REFUND_PAYMENT,
} from 'gql/shared/qbo.gql';

import { TransactionTypes } from '__generated__/globalTypes';

import { usePaymentActionsPropsType } from './usePaymentActions.types';
import {
  FetchCreditMemoPdfQuery,
  FetchCreditMemoPdfQueryVariables,
  FetchPaymentPdfQuery,
  FetchPaymentPdfQueryVariables,
  FetchRefundPdfQuery,
  FetchRefundPdfQueryVariables,
} from './__generated__/usePaymentActions.gql';
import {
  FETCH_CREDIT_MEMO_PDF,
  FETCH_PAYMENT_PDF,
  FETCH_REFUND_PDF,
} from './usePaymentActions.gql';

export default function usePaymentActions({
  id,
  type,
}: usePaymentActionsPropsType) {
  const [fetchPaymentPdf, { loading: downloadPaymentPdfLoading }] =
    useLazyQuery<FetchPaymentPdfQuery, FetchPaymentPdfQueryVariables>(
      FETCH_PAYMENT_PDF,
      {
        variables: {
          id,
        },
        onCompleted: ({ pdf: { url } }) => {
          downloadFileByUrl(url);
        },
        context: {
          token: publicSession.getAccessToken(),
        },
        fetchPolicy: 'network-only',
      },
    );

  const [fetchRefundPdf, { loading: downloadRefundPdfLoading }] = useLazyQuery<
    FetchRefundPdfQuery,
    FetchRefundPdfQueryVariables
  >(FETCH_REFUND_PDF, {
    variables: {
      id,
    },
    onCompleted: ({ pdf: { data, mimetype, name } }) => {
      downloadFileByBase64(data, mimetype, name);
    },
    fetchPolicy: 'network-only',
  });

  const [fetchCreditMemoPdf, { loading: downloadCreditMemoPdfLoading }] =
    useLazyQuery<FetchCreditMemoPdfQuery, FetchCreditMemoPdfQueryVariables>(
      FETCH_CREDIT_MEMO_PDF,
      {
        variables: {
          id,
        },
        onCompleted: ({ pdf: { data, mimetype, name } }) => {
          downloadFileByBase64(data, mimetype, name);
        },
        fetchPolicy: 'network-only',
      },
    );

  const [qboResyncPayment] = useQboResync<
    QboResyncPaymentMutation,
    QboResyncPaymentMutationVariables
  >(QBO_RESYNC_PAYMENT, { entityName: 'Payment' });

  const [qboResyncRefundPayment] = useQboResync<
    QboResyncRefundPaymentMutation,
    QboResyncRefundPaymentMutationVariables
  >(QBO_RESYNC_REFUND_PAYMENT, { entityName: 'Refund payment' });

  const [qboResyncCreditMemo] = useQboResync<
    QboResyncCreditMemoMutation,
    QboResyncCreditMemoMutationVariables
  >(QBO_RESYNC_CREDIT_MEMO, { entityName: 'Credit memo' });

  const qboResync = useCallback(() => {
    if (type === TransactionTypes.REFUND) {
      qboResyncRefundPayment({ id });
    } else if (type === TransactionTypes.CREDIT_MEMO) {
      qboResyncCreditMemo({ id });
    } else if (type === TransactionTypes.PAYMENT) {
      qboResyncPayment({ id });
    }
  }, [id, qboResyncCreditMemo, qboResyncPayment, qboResyncRefundPayment, type]);

  const downloadPaymentPdf = useCallback(async () => {
    try {
      if (!downloadPaymentPdfLoading && type === TransactionTypes.PAYMENT) {
        await fetchPaymentPdf();
      }
    } catch (e) {
      traceError(e as Error);
    }
  }, [downloadPaymentPdfLoading, fetchPaymentPdf, type]);

  const downloadRefundPdf = useCallback(async () => {
    try {
      if (!downloadRefundPdfLoading && type === TransactionTypes.REFUND) {
        await fetchRefundPdf();
      }
    } catch (e) {
      traceError(e as Error);
    }
  }, [downloadRefundPdfLoading, fetchRefundPdf, type]);

  const downloadCreditMemoPdf = useCallback(async () => {
    try {
      if (
        !downloadCreditMemoPdfLoading &&
        type === TransactionTypes.CREDIT_MEMO
      ) {
        await fetchCreditMemoPdf();
      }
    } catch (e) {
      traceError(e as Error);
    }
  }, [downloadCreditMemoPdfLoading, fetchCreditMemoPdf, type]);

  return {
    qboResync,
    downloadPaymentPdf,
    downloadPaymentPdfLoading,
    downloadRefundPdf,
    downloadRefundPdfLoading,
    downloadCreditMemoPdf,
    downloadCreditMemoPdfLoading,
  };
}
