import { useContext, useMemo } from 'react';

import { PermissionsContext } from 'components/common/App/components/PermissionsProvider';

import { PermissionGuardPropsType } from './PermissionGuard.types';

export default function PermissionGuard({
  action,
  resource,
  resourceId,
  children,
}: PermissionGuardPropsType) {
  const { hasPermission } = useContext(PermissionsContext);
  const isAllowed = useMemo(
    () => hasPermission(resource, action, resourceId),
    [action, hasPermission, resource, resourceId],
  );

  return isAllowed ? children : null;
}
