import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader, traceError } from '@appclose/core';

import { AccessLevels, CreateTeamMemberInput } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { getContactName } from 'controllers/contact';
import { INITIAL_ADDRESS } from 'constants/address';
import { INITIAL_PHONE } from 'constants/phone';
import { SERVER_DATE_FORMAT } from 'constants/date';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';

import {
  CreateTeamMemberMutation,
  CreateTeamMemberMutationVariables,
  FetchProfileQuery,
  FetchProfileQueryVariables,
  FetchTeamMemberQuery,
  FetchTeamMemberQueryVariables,
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables,
} from './__generated__/TeamMemberModal.gql';
import TeamMemberForm from './components/TeamMemberForm';
import {
  TeamMemberBaseFormValuesType,
  TeamMemberFormActionsType,
  TeamMemberFormValuesType,
} from './components/TeamMemberForm/TeamMemberForm.types';
import {
  CREATE_TEAM_MEMBER,
  FETCH_PROFILE,
  FETCH_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
} from './TeamMemberModal.gql';
import { TeamMemberModalPropsType } from './TeamMemberModal.types';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function TeamMemberModal({
  id,
  onClose,
}: TeamMemberModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const { trackModalClose } = useModalTracking(
    PermissionResources.TEAM_MANAGEMENT,
    isEdit ? ActionType.EDIT : ActionType.CREATE,
  );
  const { loading: profileLoading, data: profileData } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE, {
    skip: !isEdit,
  });
  const { loading: teamMemberLoading, data } = useQuery<
    FetchTeamMemberQuery,
    FetchTeamMemberQueryVariables
  >(FETCH_TEAM_MEMBER, {
    variables: { id: id as string },
    skip: !isEdit,
  });
  const [createTeamMember] = useMutation<
    CreateTeamMemberMutation,
    CreateTeamMemberMutationVariables
  >(CREATE_TEAM_MEMBER);
  const [updateTeamMember] = useMutation<
    UpdateTeamMemberMutation,
    UpdateTeamMemberMutationVariables
  >(UPDATE_TEAM_MEMBER);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    async (
      { ...values }: TeamMemberFormValuesType,
      { setSubmitting }: TeamMemberFormActionsType,
    ) => {
      try {
        if (isEdit) {
          const { email, ...rest } = values;
          // const practiceAreas = formPracticeAreas
          //   ?.split(',')
          //   .map((practiceArea) => practiceArea.trim());

          await updateTeamMember({
            variables: {
              input: {
                id: id as string,
                ...rest,
              },
            },
          });

          notification().entityUpdated(
            t('modal.teamMember.notification.updated'),
          );
        } else {
          const input = { ...values } as CreateTeamMemberInput;

          await createTeamMember({
            variables: { input },
          });

          notification().entityCreated(
            t('modal.teamMember.notification.created'),
          );
        }

        onClose();
        trackModalClose();
      } catch (e) {
        traceError(e as Error);
        setSubmitting(false);
      }
    },
    [
      createTeamMember,
      id,
      isEdit,
      onClose,
      trackModalClose,
      t,
      updateTeamMember,
    ],
  );

  const isExtendedForm =
    isEdit && profileData?.profile.accessLevel === AccessLevels.SUPER_ADMIN;

  const {
    id: teamMemberId,
    firstName,
    lastName,
    email,
    role,
    accessLevel,
    hourlyRate,
    practiceAreas,
    phoneNumbers,
    addresses,
    isOwner: teamMemberIsOwner,
    ...lastTeamMemberInfo
  } = data?.teamMember || ({} as FetchTeamMemberQuery['teamMember']);

  const baseTeamMemberInfo: TeamMemberBaseFormValuesType = {
    id: teamMemberId,
    firstName,
    lastName,
    email,
    role,
    accessLevel,
    hourlyRate,
  };

  const initialValues: TeamMemberFormValuesType = {
    ...baseTeamMemberInfo,
    ...(isExtendedForm
      ? {
          ...lastTeamMemberInfo,
          practiceAreas: practiceAreas ? practiceAreas.join(', ') : '',
          phoneNumbers: !!phoneNumbers?.length
            ? phoneNumbers
            : [{ ...INITIAL_PHONE }],

          addresses: !!addresses?.length ? addresses : [{ ...INITIAL_ADDRESS }],
          licensedSince: lastTeamMemberInfo.licensedSince
            ? dateManager()
                .parse(lastTeamMemberInfo.licensedSince)
                .format(SERVER_DATE_FORMAT)
            : null,
        }
      : {}),
  };

  const canSetAccessLevel =
    !isEdit ||
    (profileData?.profile.accessLevel === AccessLevels.SUPER_ADMIN &&
      !teamMemberIsOwner);

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          {!isEdit
            ? t('modal.teamMember.create.title')
            : data?.teamMember
              ? t('modal.teamMember.edit.title', {
                  name: getContactName(data.teamMember),
                })
              : null}
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {profileLoading || teamMemberLoading ? (
          <Loader />
        ) : (
          <TeamMemberForm
            isExtended={isExtendedForm}
            initialValues={initialValues}
            isEdit={isEdit}
            canSetAccessLevel={canSetAccessLevel}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
