import { isElementInScroll, scrollIntoView } from '@appclose/lib';
import React, { useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { TickIcon } from '@appclose/icons';

import { Ellipsis } from '../../../Ellipsis';
import { SelectType } from '../../Select.types';
import { OptionType } from './Options.types';
import styles from './Option.module.scss';

export default function Option<T extends SelectType>({
  value,
  label,
  highlighted = false,
  isSelected = false,
  isMulti,
  theme,
  onHighlight,
  onSelect,
}: OptionType<T>) {
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlighted) {
      // @ts-ignore
      if (
        optionRef?.current &&
        optionRef?.current.parentElement &&
        !isElementInScroll(optionRef?.current, optionRef?.current.parentElement)
      ) {
        scrollIntoView(optionRef?.current as HTMLElement);
      }
    }
  }, [highlighted]);

  const handleOnMouseOver = () => {
    onHighlight(value);
  };
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      onSelect(value);
    },
    [value, onSelect],
  );

  return (
    <div
      ref={optionRef}
      className={classnames(
        styles.option,
        {
          [styles.highlighted]: highlighted,
          [styles.selected]: isSelected,
          [styles.isMulti]: isMulti,
        },
        theme?.option,
      )}
      onMouseOver={handleOnMouseOver}
      onClick={handleOnClick}
    >
      <div className={classnames(styles.label, theme?.label)}>
        <Ellipsis as="div">{label}</Ellipsis>
      </div>
      <TickIcon className={styles.icon} />
    </div>
  );
}
