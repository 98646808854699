import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { CaptionPropsType, CaptionNPropsType } from './Caption.types';
import styles from './Caption.module.scss';

export const Caption = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  CaptionPropsType
>(
  (
    {
      children,
      as: Component = 'p',
      weight = 'regular',
      level,
      color,
      offset,
      align: textAlign,
      uppercase = false,
      className,
      onClick,
    },
    ref,
  ) => {
    const margins = {
      marginTop: offset?.top,
      marginRight: offset?.right,
      marginBottom: offset?.bottom,
      marginLeft: offset?.left,
    };

    return (
      <Component
        onClick={onClick}
        style={{ ...margins, textAlign }}
        className={classnames(styles.caption, className, {
          [styles.captionLevel1]: level === 1,
          [styles.captionLevel2]: level === 2,
          [styles.captionLevel3]: level === 3,
          [styles.light]: weight === 'light',
          [styles.regular]: weight === 'regular',
          [styles.semibold]: weight === 'semibold',
          [styles.bold]: weight === 'bold',
          [styles.extraBold]: weight === 'extraBold',
          [styles.black]: weight === 'black',
          [styles.primary]: color === 'primary',
          [styles.secondary]: color === 'secondary',
          [styles.contrast]: color === 'contrast',
          [styles.gray]: color === 'gray',
          [styles.gray030]: color === 'gray030',
          [styles.warning]: color === 'warning',
          [styles.links]: color === 'links',
          [styles.uppercase]: uppercase,
        })}
        ref={ref as any}
      >
        {children}
      </Component>
    );
  },
);

export const Caption1 = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  CaptionNPropsType
>(({ children, ...props }, ref) => {
  return (
    <Caption level={1} {...props} ref={ref}>
      {children}
    </Caption>
  );
});

export const Caption2 = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  CaptionNPropsType
>(({ children, ...props }, ref) => {
  return (
    <Caption level={2} {...props} ref={ref}>
      {children}
    </Caption>
  );
});

export const Caption3 = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  CaptionNPropsType
>(({ children, ...props }, ref) => {
  return (
    <Caption level={3} {...props} ref={ref}>
      {children}
    </Caption>
  );
});
