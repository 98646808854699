import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { SelectFormField } from '@appclose/core';
import { useIntl } from 'i18n';

import {
  FetchQboAccountsQuery,
  FetchQboAccountsQueryVariables,
  QboAccountSelectFragment,
} from './__generated__/QboAccountSelectFormField.gql';
import { FETCH_QBO_ACCOUNTS } from './QboAccountSelectFormField.gql';
import { ContactSelectFormFieldPropsType } from './QboAccountSelectFormField.types';

export default function QboAccountSelectFormField({
  showPlaceholder = 'always',
  placeholder,
  label = '',
  accountType,
  exclude = [],
  ...rest
}: ContactSelectFormFieldPropsType) {
  const { t } = useIntl();

  placeholder =
    placeholder || t('modal.qboIntegrationSetup.accountSelect.placeholder');

  const { loading, data } = useQuery<
    FetchQboAccountsQuery,
    FetchQboAccountsQueryVariables
  >(FETCH_QBO_ACCOUNTS);
  const accounts = (data?.getQboResourcesDetails.accounts || []).filter(
    ({ type, id }) =>
      (!accountType || type === accountType) && !exclude.includes(id),
  );

  const optionValueResolver = useCallback(
    (account: QboAccountSelectFragment) => account?.id,
    [],
  );
  const optionLabelResolver = useCallback(
    (account: QboAccountSelectFragment) => account?.name,
    [],
  );
  const selectedOptionsResolver = useCallback(
    (selectedOptions: QboAccountSelectFragment[]) =>
      selectedOptions.map(({ name }) => name).join(', '),
    [],
  );

  return (
    <SelectFormField
      label={label}
      showPlaceholder={showPlaceholder}
      placeholder={placeholder}
      {...rest}
      clearable
      isLoading={loading}
      options={accounts}
      selectedOptionsResolver={selectedOptionsResolver}
      optionLabelResolver={optionLabelResolver}
      optionValueResolver={optionValueResolver}
    />
  );
}
