import { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './ActionCard.module.scss';
import { Flex, Text4 } from '@appclose/ui';

export type ActionCardPropsType = {
  icon?: ReactNode;
  className?: string;
  title?: string;
  onClick?(): void;
};

export function ActionCard({
  icon,
  title,
  onClick,
  className,
}: ActionCardPropsType) {
  return (
    <Flex
      direction="column"
      justify="space-between"
      className={classnames(styles.container, className)}
      onClick={onClick}
    >
      <div className={styles.iconWrapper}>{icon}</div>
      <Text4>{title}</Text4>
    </Flex>
  );
}
