import {
  SelectFormField,
  SelectFormFieldPropsType,
  SelectType,
} from '@appclose/core';

import { useSignerTypes } from 'hooks/useSignerTypes';

export type SignerSelectFormFieldPropsType = Omit<
  SelectFormFieldPropsType<SelectType>,
  'name' | 'options'
> & {
  name?: string;
};

export default function SignerSelectFormField({
  name = 'signer',
  label = 'Signer',
  ...props
}: SignerSelectFormFieldPropsType) {
  const { signerTypesOptions } = useSignerTypes();

  return (
    <SelectFormField
      name={name}
      label={label}
      options={signerTypesOptions}
      {...props}
    />
  );
}
