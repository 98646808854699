import React from 'react';
import classnames from 'classnames';
import { QuestionIcon } from '@appclose/icons';

import { NotePropsType } from './Note.types';
import styles from './Note.module.scss';

export default function Note({
  theme,
  offset,
  className,
  children,
}: NotePropsType) {
  const margins = {
    marginTop: offset?.top,
    marginRight: offset?.right,
    marginBottom: offset?.bottom,
    marginLeft: offset?.left,
  };

  return (
    <aside
      style={margins}
      className={classnames(
        styles.note,
        {
          [styles.important]: theme === 'important',
          [styles.info]: theme === 'info',
          [styles.importantSmall]: theme === 'important-small',
          [styles.error]: theme === 'error',
        },
        className,
      )}
    >
      {!theme && (
        <QuestionIcon width={12} height={12} className={styles.icon} />
      )}
      <div>{children}</div>
    </aside>
  );
}
