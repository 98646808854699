import React from 'react';
import classnames from 'classnames';
import { MINUS_SIGN, NO_BREAK_SPACE } from '@appclose/lib';

import currency from '../../../controllers/currency';
import percent from '../../../controllers/percent';

import { AmountPropsType } from './Amount.types';
import styles from './Amount.module.scss';

export default function Amount({
  value,
  color,
  strong = false,
  className,
  showPlusSign = false,
  format = 'currency',
}: AmountPropsType) {
  const Component = strong ? 'strong' : 'span';

  return (
    <Component
      className={classnames(
        {
          [styles.info]: color === 'info',
          [styles.warning]: color === 'warning',
          [styles.success]: color === 'success',
          [styles.strong]: strong,
        },
        className,
      )}
    >
      {value < 0 && `${MINUS_SIGN}${NO_BREAK_SPACE}`}
      {showPlusSign && value > 0 && `+${NO_BREAK_SPACE}`}
      {format === 'currency'
        ? currency().format(Math.abs(value))
        : percent().format(Math.abs(value) / 100)}
    </Component>
  );
}
