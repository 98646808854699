import * as Yup from 'yup';

import { FilesFormFieldFilesType } from 'components/fields/FilesFormField';
import { getI18nErrorMessage } from 'i18n';

export const FILES_SCHEMA = Yup.object<FilesFormFieldFilesType>().test(
  'uploadFilesWithoutError',
  getI18nErrorMessage('form.errors.uploadFilesWithoutError'),
  (files) => !files?.hasError,
);

export const AT_LEAST_ONE_FILE_SCHEMA = FILES_SCHEMA.test(
  'atLeastOneUploadedFile',
  getI18nErrorMessage('validation.files.minLength'),
  (files) => (files?.newFiles.length || 0) >= 1,
).required(getI18nErrorMessage('validation.files.required'));
