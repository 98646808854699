import { createContext } from 'react';

import { ConfirmProviderContextPropsType } from './ConfirmProvider.types';

export const ConfirmProviderContext =
  createContext<ConfirmProviderContextPropsType>({
    onChangeState() {
      throw new Error("ConfirmProvider context hasn't been defined");
    },
  });
