import { useEffect } from 'react';
import {
  ConfirmProvider,
  GqlErrorContext,
  NotificationProvider,
  PopupModalProvider,
} from '@appclose/core';

import { getGqlErrorResolvers } from './AppContent.constants';
import Router from 'components/common/Router';
import Zendesk from 'components/common/Zendesk';
import useEnvForwardingParams from 'hooks/useEnvForwardingParams';
import { useIntl } from 'i18n';
import { useLocation } from 'react-router-dom';
import { analytics, track } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';
import { usePrevLocation } from 'hooks/usePrevLocation';
import { isDevelopmentMode } from '@appclose/lib';
import { getPathMeta } from 'controllers/routesMeta';

export default function AppContent() {
  const { t } = useIntl();
  const { savePrams } = useEnvForwardingParams();
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  useEffect(() => {
    savePrams();
    // Save query params only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analytics().page();

    const meta = getPathMeta(location.pathname);

    if (meta) {
      track(EventNames.PAGE_OPENED, {
        page: meta.page,
        tab: meta.tab,
        referrer: getPathMeta(prevLocation.pathname) || document.referrer,
      });
    } else if (isDevelopmentMode()) {
      console.error(`No meta found for route ${location.pathname}`);
    }
  }, [location, prevLocation]);

  return (
    <GqlErrorContext.Provider
      value={{
        errorResolver: getGqlErrorResolvers(t),
      }}
    >
      <PopupModalProvider>
        <Router />
      </PopupModalProvider>
      <ConfirmProvider />
      <NotificationProvider />
      <Zendesk />
    </GqlErrorContext.Provider>
  );
}
