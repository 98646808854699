import React from 'react';
import { sortByDateField } from '@appclose/lib';
import { Fieldset } from '@appclose/core';

import { InvoiceHistoryTypes } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

import InvoiceHistoryItem from './components/InvoiceHistoryItem';
import { InvoiceHistoryPropsType } from './InvoiceHistory.types';
import styles from './InvoiceHistory.module.scss';

export default function InvoiceHistory({
  invoice,
  className,
}: InvoiceHistoryPropsType) {
  const { t } = useIntl();
  const { history } = invoice;
  const firstSentHistoryRecord = history.find(
    (item) => item.status === InvoiceHistoryTypes.SENT,
  );

  return (
    <Fieldset
      title={t('modal.invoiceDetails.invoiceHistory.fieldset.invoiceHistory')}
      className={className}
    >
      <ul className={styles.invoiceHistory}>
        {sortByDateField(history, { order: 'DESC', fieldName: 'date' }).map(
          (historyItem) => (
            <InvoiceHistoryItem
              key={historyItem.date}
              historyItem={historyItem}
              isFirstSent={firstSentHistoryRecord === historyItem}
            />
          ),
        )}
      </ul>
    </Fieldset>
  );
}
