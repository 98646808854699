import { useCallback } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import omit from 'lodash/omit';
import { dateManager, Loader, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { SERVER_DATE_FORMAT } from 'constants/date';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl, I18n } from 'i18n';

import { FirmModalPropsType } from './FirmModal.types';
import FirmForm from './components/FirmForm';
import { UPDATE_FIRM } from './FirmModal.gql';
import {
  UpdateFirmMutation,
  UpdateFirmMutationVariables,
} from './__generated__/FirmModal.gql';
import {
  FirmFormActionsType,
  FirmFormValuesType,
} from './components/FirmForm/FirmForm.types';
import useFirm from 'hooks/useFirm';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function FirmModal({ onClose }: FirmModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.FIRM,
    ActionType.EDIT,
  );
  const { loading, firm } = useFirm();
  const [updateFirm] = useMutation<
    UpdateFirmMutation,
    UpdateFirmMutationVariables
  >(UPDATE_FIRM);
  const initialValues = {
    ...omit(firm, 'id'),
    phoneNumber: firm.phoneNumber || { number: '' },
    ...(firm?.businessStartedSince
      ? {
          businessStartedSince: dateManager()
            .parse(firm.businessStartedSince)
            .format(SERVER_DATE_FORMAT),
        }
      : {}),
  };

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    async (
      { firmLogo, ...input }: FirmFormValuesType,
      { setSubmitting }: FirmFormActionsType,
    ) => {
      try {
        await updateFirm({
          variables: {
            input,
            logo: firmLogo,
          },
        });

        notification().entityUpdated(t('modal.firm.notification.updated'));
        onClose();
        trackModalClose();
      } catch (e) {
        setSubmitting(false);
        traceError(e as Error | ApolloError);
      }
    },
    [onClose, trackModalClose, updateFirm, t],
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.firm.confirm.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <FirmForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onClose={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
