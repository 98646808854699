import React, { memo } from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import GenderSelectFormField from 'components/fields/GenderSelectFormField';
import PhoneFieldset from 'components/form/PhoneFieldset';
import AddressesFieldset from 'components/form/AddressesFieldset';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import FilesFormField from 'components/fields/FilesFormField';
import { useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import styles from './ContactFieldset.module.scss';
import { ContactFieldsetPropsType } from './ContactFieldset.types';

const ContactFieldset = memo(function ContactFieldset({
  id,
}: ContactFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('modal.contact.form.contact.fieldset.info.title')}>
        <FormGrid>
          <UpperFirstInputFormField
            name="contact.firstName"
            label={t('modal.contact.form.contact.fieldset.info.firstName')}
          />
          <GenderSelectFormField name="contact.gender" />
          <UpperFirstInputFormField
            name="contact.middleName"
            label={t('modal.contact.form.contact.fieldset.info.middleName')}
          />
          <InputFormField
            name="contact.email"
            label={t('modal.contact.form.contact.fieldset.info.email')}
          />
          <UpperFirstInputFormField
            name="contact.lastName"
            label={t('modal.contact.form.contact.fieldset.info.lastName')}
          />
        </FormGrid>
      </Fieldset>
      <PhoneFieldset name="contact.phoneNumbers" allowRemoveLast />
      <AddressesFieldset name="contact.addresses" allowRemoveLast />
      <Fieldset title={t('modal.contact.form.contact.fieldset.other.title')}>
        <FormGroup>
          <TextAreaFormField
            name="contact.notes"
            label={t('modal.contact.form.contact.fieldset.other.notes')}
          />
        </FormGroup>

        <FormGroup>
          <FormGrid>
            <TeamMemberSelectFormField
              name="contact.originatingAttorney"
              label={t(
                'modal.contact.form.contact.fieldset.other.originatingAttorney',
              )}
            />
          </FormGrid>
        </FormGroup>

        <FormGroup>
          <FilesFormField entityType={Entities.CONTACT} entityId={id} />
        </FormGroup>

        <FormGroup className={styles.conflictsCheck}>
          <ToggleFormField
            name="contact.conflictsCheckCompleted"
            label={t('modal.contact.form.contact.fieldset.other.conflicts')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="contact.engagementHasBeenSigned"
            label={t('modal.contact.form.contact.fieldset.other.engagement')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="contact.taxable"
            label={t('modal.contact.form.contact.fieldset.other.taxable')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="contact.w9FormSentToClient"
            label={t('modal.contact.form.contact.fieldset.other.w9FormSent')}
          />
        </FormGroup>
      </Fieldset>
    </>
  );
});

export default ContactFieldset;
