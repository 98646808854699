import React from 'react';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { FieldArray } from 'formik';

import { FormArrayFieldPropsType } from './FormArrayField.types';

function resolveName(arrayField: string, index: number, fieldName?: string) {
  return `${arrayField}.${index}${fieldName ? `.${fieldName}` : ''}`;
}

function isArrayFieldDirty(
  fieldName: string,
  values: any,
  initialValues: any,
  index: number,
) {
  return !isEqual(
    get(values, `${fieldName}[${index}]`),
    get(initialValues, `${fieldName}[${index}]`),
  );
}

export default function FormArrayField({
  name,
  validateOnChange,
  children,
}: FormArrayFieldPropsType) {
  return (
    <FieldArray name={name} validateOnChange={validateOnChange}>
      {(props) => {
        const {
          form: { values: formValues, initialValues },
        } = props;
        const values = (get(formValues, name) as any[]) || [];

        return children({
          ...props,
          values,
          resolveName: (index: number, fieldName?: string) =>
            resolveName(name, index, fieldName),
          isArrayFieldDirty: (index: number) =>
            isArrayFieldDirty(name, formValues, initialValues, index),
        });
      }}
    </FieldArray>
  );
}
