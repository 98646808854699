import isEqual from 'lodash/isEqual';
import { history, traceError } from '@appclose/core';

const MODAL_KEY = 'modal';
const MODAL_HISTORY_STATE = Symbol('MODAL_HISTORY_STATE');
const MODAL_STATE_PREVIOUS_SAVE_DATA = Symbol('MODAL_STATE_PREVIOUS_SAVE_DATA');
const MODAL_STATE_SAVE_DATA = Symbol('MODAL_STATE_SAVE_DATA');

function encodeModalData(type: string, data?: any) {
  return btoa(unescape(encodeURIComponent(JSON.stringify({ type, data }))));
}

function decodeModalData(search: string) {
  if (!search) {
    return;
  }

  try {
    return JSON.parse(atob(search));
  } catch (e) {
    traceError(e as Error);
  }
}

export function openModal(type: string, data?: any, saveData?: any) {
  const { location } = history;
  const { pathname, search } = location;

  if (!isSameModalOpen(type, data)) {
    const searchParams = new URLSearchParams(search);
    const modalData = encodeModalData(type, data);
    searchParams.set(MODAL_KEY, modalData);

    history.push({
      pathname,
      search: searchParams.toString(),
      state: {
        [MODAL_HISTORY_STATE]: location,
        [MODAL_STATE_PREVIOUS_SAVE_DATA]: saveData,
      },
    });
  } else {
    console.warn('An attempt to open the same modal with the same data', {
      type,
      data,
    });
  }
}

export function closeModal() {
  const { location } = history;
  const { state } = location as any as {
    state: {
      [MODAL_HISTORY_STATE]: typeof location;
      [MODAL_STATE_PREVIOUS_SAVE_DATA]: any;
    };
  };
  const previousState = state?.[MODAL_HISTORY_STATE] as any;
  const previousSaveDataState = state?.[MODAL_STATE_PREVIOUS_SAVE_DATA];

  if (previousState) {
    history.push({
      ...previousState,
      state: {
        ...(previousState?.state || {}),
        [MODAL_STATE_SAVE_DATA]: previousSaveDataState,
      },
    });
  } else {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(MODAL_KEY);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }
}

export function getModalData(): {
  type: string;
  data?: any;
} | null {
  const {
    location: { search },
  } = history;

  const searchParams = new URLSearchParams(search);
  const encodedModalData = searchParams.get(MODAL_KEY) || '';

  return decodeModalData(encodedModalData);
}

export function isSameModalOpen(type: string, data?: any) {
  const currentModal = getModalData();

  return isEqual(
    { type: currentModal?.type, data: currentModal?.data },
    { type, data },
  );
}

export function getSavedModalData<SavedDataType>(): SavedDataType | undefined {
  const { location } = history;
  const { state } = location as any as {
    state: { [MODAL_STATE_SAVE_DATA]: SavedDataType };
  };

  return state?.[MODAL_STATE_SAVE_DATA];
}
