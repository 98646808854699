import { useContext, useEffect, useMemo } from 'react';
import { useMount } from '@appclose/core';
import {
  TourStepElementType,
  TourStep as TourStepType,
} from '../../Tour.types';

import { TourStepElement } from '../TourStepElement';
import TourTooltip, { TourTooltipPositionType } from '../TourTooltip';
import { TourContext } from '../../Tour.context';

type TourStepPropsType = {
  types: TourStepElementType[];
  elements: (Element | null)[];
  classNames?: (string | undefined)[];
} & Omit<TourStepType, 'elements'>;

const topBottomPositions: TourTooltipPositionType[] = [
  'bottom',
  'top',
  'bottom-start',
  'top-start',
];

export const TourStep: React.FC<TourStepPropsType> = ({
  popoverPosition,
  content,
  elements,
  types,
  name,
  classNames = [],
  skipScroll,
}) => {
  const { setStepSeen } = useContext(TourContext);

  useMount(() => {
    setStepSeen(name);
  });

  useEffect(() => {
    if (skipScroll) {
      return;
    }

    elements[0]?.scrollIntoView({ block: 'center' });
  }, [elements, skipScroll]);

  const tooltipOffsetLeft = useMemo(() => {
    if (
      popoverPosition &&
      topBottomPositions.includes(popoverPosition) &&
      elements.length > 1
    ) {
      return (
        elements.reduce((acc, curr) => {
          acc += curr?.getBoundingClientRect().width ?? 0;

          return acc;
        }, 0) / 2
      );
    }

    return 0;
  }, [elements, popoverPosition]);

  return (
    <TourTooltip
      content={content}
      position={popoverPosition}
      offsetLeft={tooltipOffsetLeft}
    >
      {elements.map((element, index) => (
        <TourStepElement
          key={index}
          element={element}
          type={types[index]}
          className={classNames[index]}
        />
      ))}
    </TourTooltip>
  );
};
