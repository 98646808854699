import { matchPath } from 'react-router-dom';
import { routesMeta } from 'constants/routesMeta';

export const getPathMeta = (route: string) => {
  const key = Object.keys(routesMeta).find((path) =>
    matchPath(route, {
      path,
      exact: true,
    }),
  );

  if (key) {
    return routesMeta[key];
  }

  return null;
};
