import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loader from '../../common/Loader';

export default function preloader(
  processor: Function,
  Loading: React.ComponentType<any> = Loader,
): Function {
  return function _preloader(
    WrappedComponent: React.ComponentType,
  ): React.FunctionComponent {
    return function Preload(props = {}): React.ReactElement {
      const dispatch = useDispatch();
      const [isProcessing, setProcessingStatus] = useState(true);
      const [payload, setPayload] = useState({});

      useEffect(() => {
        const processorGenerator = (function* () {
          yield processor({ ...props, dispatch });
        })();

        (async () => {
          try {
            const payload = await processorGenerator.next().value;

            setPayload(payload);
          } catch (e) {
            console.warn(e);
          } finally {
            setProcessingStatus(false);
          }
        })();

        return () => {
          processorGenerator.return();
        };
      }, []); // eslint-disable-line

      return isProcessing ? (
        <Loading />
      ) : (
        <WrappedComponent {...props} {...payload} />
      );
    };
  };
}
