import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { DUE_DAYS } from 'components/fields/DueDaysSelectFormField';
import { sanitizeAmount } from 'controllers/amount';
import { openModal } from 'controllers/modal';
import notification from 'controllers/notification';
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from 'constants/date';
import { INVOICE_DETAILS_MODAL } from 'constants/modals';
import useBankAccounts from 'hooks/useBankAccounts';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
} from './__generated__/InvoiceModal.gql';
import InvoiceForm from './components/InvoiceForm';
import { InvoiceFormValuesType } from './components/InvoiceForm/InvoiceForm.types';
import {
  CREATE_INVOICE,
  FETCH_CONTACT,
  FETCH_INVOICE,
  UPDATE_INVOICE,
} from './InvoiceModal.gql';
import { InvoiceModalPropsType } from './InvoiceModal.types';
import styles from './InvoiceModal.module.scss';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function InvoiceModal({
  id,
  contactId,
  account,
  onClose,
}: InvoiceModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const { trackModalClose } = useModalTracking(
    PermissionResources.INVOICE,
    isEdit ? ActionType.EDIT : ActionType.VIEW,
  );
  const title = isEdit
    ? t('modal.invoice.title.edit')
    : t('modal.invoice.title.add');
  const { onUpdateFiles } = useUpdateFiles(Entities.INVOICE);
  const { loading: loadingOnboardedBankAccounts, bankAccounts } =
    useBankAccounts();
  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || isEdit,
  });
  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id: id as string },
    skip: !isEdit,
  });
  const [createInvoice] = useMutation<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
  >(CREATE_INVOICE);
  const [updateInvoice] = useMutation<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >(UPDATE_INVOICE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    async ({
      contact,
      dueDays,
      total: expensesAndTimeEntriesTotal,
      amount: paymentRequest,
      scheduleRecurringPayments,
      scheduleSettings,
      schedulePlan,
      scheduleLoading,
      destinationAccount,
      invoiceNumber: formInvoiceNumber,
      files,
      createdAt,
      ...invoice
    }: InvoiceFormValuesType) => {
      const isInvoiceForOperating =
        destinationAccount === BankAccountClasses.OPERATING;
      const withDiscount = isInvoiceForOperating;
      const amount = sanitizeAmount(
        withDiscount ? expensesAndTimeEntriesTotal : paymentRequest,
      );
      const contactId = contact?.id as string;
      const invoiceNumber = formInvoiceNumber?.length
        ? formInvoiceNumber
        : undefined;

      let invoiceId = id;

      if (!isEdit) {
        const { data } = await createInvoice({
          variables: {
            invoice: {
              ...invoice,
              amount,
              destinationAccount,
              contactId,
              invoiceNumber,
              scheduleSettings: scheduleRecurringPayments
                ? scheduleSettings
                : null,
            },
          },
        });

        notification().entityCreated(t('modal.invoice.notification.created'));

        invoiceId = data?.createInvoice.id;
      } else {
        await updateInvoice({
          variables: {
            invoice: {
              ...invoice,
              id: id as string,
              amount,
              contactId,
              scheduleSettings: scheduleRecurringPayments
                ? scheduleSettings
                : null,
            },
          },
        });

        notification().entityUpdated(t('modal.invoice.notification.updated'));
      }

      if (invoiceId && files) {
        await onUpdateFiles({ entityId: invoiceId, ...files });
      }

      onClose();
      trackModalClose();

      openModal(INVOICE_DETAILS_MODAL, {
        id: invoiceId,
      });
    },
    [
      id,
      t,
      onClose,
      trackModalClose,
      onUpdateFiles,
      createInvoice,
      updateInvoice,
      isEdit,
    ],
  );

  const isLoading = loadingContact || loading || loadingOnboardedBankAccounts;

  const {
    balanceDue,
    discount,
    percentageDiscount,
    dueDate,
    scheduledPayments,
    destinationAccount,
    contact,
    ...invoice
  } = {
    ...data?.invoice,
  };
  const dueDays = !isEdit ? DUE_DAYS['30_DAYS'] : DUE_DAYS.CUSTOM;

  let initialValues: InvoiceFormValuesType = {
    ...invoice,
    contact: contact || contactData?.contact,
    destinationAccount:
      destinationAccount || account || BankAccountClasses.OPERATING,
    amount: sanitizeAmount(balanceDue),
    dueDays,
    dueDate: !isEdit
      ? dateManager()
          .parse()
          .add(parseInt(dueDays) || 0, 'days')
          .format(SERVER_DATE_TIME_FORMAT)
      : dateManager().format(dueDate, SERVER_DATE_TIME_FORMAT),
    percentageDiscount: percentageDiscount || false,
    discount: sanitizeAmount(discount),
    scheduleSettings: scheduledPayments?.settings
      ? {
          ...scheduledPayments?.settings,
          startDate: dateManager()
            .parse(scheduledPayments.settings.startDate)
            .format(SERVER_DATE_FORMAT),
        }
      : undefined,
    schedulePlan: scheduledPayments?.plan,
    scheduleRecurringPayments: !!scheduledPayments,
    scheduleLoading: false,
  };

  return (
    <ModalPage className={styles.modal} onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <InvoiceForm
            initialValues={initialValues}
            isEdit={isEdit}
            bankAccounts={bankAccounts}
            onCancel={onConfirmClose}
            onSubmit={handleOnSubmit}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
