import { ChangeEvent, useMemo, useRef, useCallback } from 'react';
import mime from 'mime';
import { Caption3 } from '@appclose/ui';
import { ClearIcon, RetryIcon } from '@appclose/icons';
import { Hint, Loader, FileInput } from '@appclose/core';
import classnames from 'classnames';

import AttachedFile from 'components/common/AttachedFile';
import { DEFAULT_MAX_ATTACH_LENGTH, MEGABYTE_MULTIPLIER } from 'constants/file';
import notification from 'controllers/notification';
import { useIntl } from 'i18n';

import { AttachFilesPropsType } from './AttachFiles.types';
import styles from './AttachFiles.module.scss';

import { isFileTypeAllowed } from './utils';

export default function AttachFiles({
  loading,
  files,
  attaches,
  processingFiles = [],
  failedFiles = [],
  accept = '',
  showAttachedFiles = true,
  label,
  tooltip,
  theme,
  maxSizeMb,
  maxFilesLength = DEFAULT_MAX_ATTACH_LENGTH,
  className,
  children,
  onChange,
  onRetry,
  ...rest
}: AttachFilesPropsType) {
  const { t } = useIntl();
  const fieldLabel = (label === undefined && t('attachFiles.label')) || label;
  const inputRef = useRef<HTMLInputElement>(null);
  const acceptArr = useMemo(
    () => accept?.split(',').map((v) => v.trim()),
    [accept],
  );

  const handleDropFiles = (droppedFiles: File[]) => {
    const acceptedFiles = droppedFiles.filter(
      (file) =>
        (!accept || acceptArr.includes(mime.getType(file.name) || '')) &&
        isFileTypeAllowed(file),
    );

    const newFiles = [...(files || []), ...acceptedFiles];
    handleOnChange(newFiles, attaches);
  };

  const handleOnChange = useCallback<AttachFilesPropsType['onChange']>(
    (newFiles, updatedAttaches) => {
      const files = newFiles || [];
      const attaches = updatedAttaches || [];
      const filesAndAttaches = [...files, ...attaches];

      if (files.find(({ size }) => !size)) {
        return notification().error(new Error(t('attachFiles.error.minSize')));
      }

      if (
        files.find(
          (file) =>
            (accept && !accept.includes(file.type)) || !isFileTypeAllowed(file),
        )
      ) {
        return notification().error(
          new Error(t('attachFiles.error.formatNotSupported')),
        );
      }

      const currentFilesLength = filesAndAttaches.length;
      const filesSize = filesAndAttaches
        .map((file) => file.size || 0)
        .reduce((a, b) => a + b, 0);

      if (maxFilesLength && currentFilesLength > maxFilesLength) {
        return notification().error(
          new Error(
            t('attachFiles.error.maxLength', {
              maxLength: maxFilesLength,
            }),
          ),
        );
      }

      if (maxSizeMb && filesSize > maxSizeMb * MEGABYTE_MULTIPLIER) {
        return notification().error(
          new Error(
            t('attachFiles.error.maxSize', {
              maxSize: maxSizeMb,
            }),
          ),
        );
      }

      onChange(newFiles, attaches);
    },
    [onChange, t, accept, maxFilesLength, maxSizeMb],
  );

  const onFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newFiles = [...(files || []), ...(e.target.files || [])];
      handleOnChange(newFiles, attaches);

      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [handleOnChange, attaches, files],
  );

  return (
    <div className={className} {...rest}>
      {fieldLabel && (
        <Caption3 color="secondary" offset={{ bottom: 12 }}>
          {fieldLabel}
          {tooltip && (
            <Hint
              content={tooltip}
              iconClassName={classnames(styles.tooltipIcon)}
            />
          )}
        </Caption3>
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {showAttachedFiles && (attaches || files) && (
            <div>
              {attaches &&
                attaches.map((attach, i) => (
                  <AttachedFile key={i} className={styles.file} file={attach}>
                    <ClearIcon
                      className={styles.remove}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onChange(
                          files,
                          attaches.filter(({ id }) => id !== attach.id),
                        );
                      }}
                    />
                  </AttachedFile>
                ))}
              {files &&
                files.map((file, i) => (
                  <AttachedFile
                    key={i}
                    className={styles.file}
                    file={file}
                    failed={failedFiles[i]}
                    processing={processingFiles[i]}
                  >
                    <ClearIcon
                      className={styles.remove}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onChange(
                          files.filter((filteredFile) => filteredFile !== file),
                          attaches,
                        );
                      }}
                    />
                    {onRetry && failedFiles[i] && !processingFiles[i] && (
                      <RetryIcon
                        className={styles.remove}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRetry(file);
                        }}
                      />
                    )}
                  </AttachedFile>
                ))}
            </div>
          )}
          <FileInput
            ref={inputRef}
            accept={accept}
            theme={theme}
            multiple
            dndLabelText={t('attachFiles.dndFile')}
            selectFileLabelText={t('attachFiles.selectFile')}
            onDropFiles={handleDropFiles}
            onFileInputChange={onFileInputChange}
          >
            {children}
          </FileInput>
        </>
      )}
    </div>
  );
}
