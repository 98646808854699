import { useCallback } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { Router as ReactRouterDom } from 'react-router-dom';
import {
  configureStore,
  history,
  Loader,
  RawIntlProvider,
  useConfigureIntl,
  initErrorTracing,
} from '@appclose/core';

import gqlClient from 'gql';
import { analytics } from 'controllers/analytics';
import { GQL_ERRORS_TO_SKIP_TRACING } from 'controllers/error';
import { initPermissionProvider } from 'controllers/permission';

import AppContent from './components/AppContent';
import PermissionsProvider from './components/PermissionsProvider';
import { useAutoTrackSessionStatusChange } from 'hooks/useAutoTrackSessionStatusChange';
import { useServiceWorkerUpdate } from 'hooks/useServiceWorkerUpdate';
import './App.module.scss';
import { TourManagerProvider } from '../Tour/TourManagerProvider';

initErrorTracing({
  errorCodesToSkip: GQL_ERRORS_TO_SKIP_TRACING,
});

analytics();
initPermissionProvider();

const store = configureStore();
const locale = 'en-US';

export default function App() {
  const clientMessagesProvider = useCallback(
    () =>
      import(`i18n/messages/${locale}.json`).then((module) => ({
        messages: module.default,
      })),
    [],
  );
  const { intl } = useConfigureIntl({
    locale,
    clientMessagesProvider,
  });

  useServiceWorkerUpdate();
  useAutoTrackSessionStatusChange();

  if (!intl) {
    return <Loader />;
  }

  return (
    <Provider store={store}>
      <ReactRouterDom history={history}>
        <PermissionsProvider>
          <ApolloProvider
            client={
              gqlClient as any /* TODO: Should be fixed after `apollo-link-token-refresh` updated */
            }
          >
            <RawIntlProvider value={intl}>
              <TourManagerProvider>
                <AppContent />
              </TourManagerProvider>
            </RawIntlProvider>
          </ApolloProvider>
        </PermissionsProvider>
      </ReactRouterDom>
    </Provider>
  );
}
