import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { FormDataType } from '@appclose/core';

import { openCancelConfirmAction } from 'store/actions';

import { UseCloseConfirmPropsType } from './useCloseConfirm.types';

export default function useCloseConfirm({
  onClose,
  defaultShowConfirm = false,
}: UseCloseConfirmPropsType) {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(defaultShowConfirm);

  const onFormChange = useCallback(({ touched }: FormDataType<any>) => {
    setShowConfirm(!isEmpty(touched));
  }, []);

  const onConfirmClose = useCallback(() => {
    if (showConfirm) {
      dispatch(
        openCancelConfirmAction({
          onClose,
        }),
      );
    } else {
      onClose();
    }
  }, [dispatch, showConfirm, onClose]);

  return {
    showConfirm,
    setShowConfirm,
    onConfirmClose,
    onFormChange,
  };
}
