import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { SelectFormField } from '@appclose/core';
import { useIntl } from 'i18n';

import {
  FetchServiceItemsQuery,
  FetchServiceItemsQueryVariables,
  QboServiceItemSelectFragment,
} from './__generated__/QboServiceItemSelectFormField.gql';
import { FETCH_QBO_SERVICE_ITEMS } from './QboServiceItemSelectFormField.gql';
import { ContactSelectFormFieldPropsType } from './QboServiceItemSelectFormField.types';

export default function QboServiceItemSelectFormField({
  showPlaceholder = 'always',
  placeholder,
  label = '',
  ...rest
}: ContactSelectFormFieldPropsType) {
  const { t } = useIntl();

  placeholder =
    placeholder || t('modal.qboIntegrationSetup.serviceItemSelect.placeholder');

  const { loading, data } = useQuery<
    FetchServiceItemsQuery,
    FetchServiceItemsQueryVariables
  >(FETCH_QBO_SERVICE_ITEMS);
  const serviceItems = data?.getQboResourcesDetails.serviceItems || [];

  const optionValueResolver = useCallback(
    (serviceItem: QboServiceItemSelectFragment) => serviceItem?.id,
    [],
  );
  const optionLabelResolver = useCallback(
    (serviceItem: QboServiceItemSelectFragment) => serviceItem?.name,
    [],
  );
  const selectedOptionsResolver = useCallback(
    (selectedOptions: QboServiceItemSelectFragment[]) =>
      selectedOptions.map(({ name }) => name).join(', '),
    [],
  );

  return (
    <SelectFormField
      label={label}
      showPlaceholder={showPlaceholder}
      placeholder={placeholder}
      {...rest}
      clearable
      isLoading={loading}
      options={serviceItems}
      selectedOptionsResolver={selectedOptionsResolver}
      optionLabelResolver={optionLabelResolver}
      optionValueResolver={optionValueResolver}
    />
  );
}
