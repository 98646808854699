import React from 'react';
import { InputFormField, FormGroup } from '@appclose/core';

import { useIntl } from 'i18n';

import { NonSignatoryRecipientPropsType } from './NonSignatoryRecipient.types';
import { trackWithPageMeta } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';

export default function NonSignatoryRecipient({
  resolveName,
}: NonSignatoryRecipientPropsType) {
  const { t } = useIntl();

  const handleTooltipOpen = () => {
    trackWithPageMeta(EventNames.HINT_VIEWED, {
      id: 'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.tooltip',
    });
  };

  return (
    <FormGroup>
      <InputFormField
        name={resolveName('email')}
        label={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.label',
        )}
        placeholder={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.placeholder',
        )}
        tooltip={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.tooltip',
        )}
        onTooltipOpen={handleTooltipOpen}
      />
    </FormGroup>
  );
}
