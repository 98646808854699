import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';

import { BankAccountsAndServiceItemsStepValuesType } from './BankAccountsAndServiceItemsStep.types';

export const BankAccountsAndServiceItemsStepSchema =
  Yup.object().shape<BankAccountsAndServiceItemsStepValuesType>({
    isEsqash: Yup.boolean().required(),
    operatingAccount: Yup.string()
      .nullable()
      .required(
        getI18nErrorMessage(
          'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.error.operatingAccount.required',
        ),
      ),
    timeEntryItem: Yup.string()
      .when('isEsqash', {
        is: false,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.error.serviceItem.required',
          ),
        ),
      })
      .nullable(),
    expenseItem: Yup.string()
      .when('isEsqash', {
        is: false,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.error.serviceItem.required',
          ),
        ),
      })
      .nullable(),
    flatFeeItem: Yup.string()
      .when('isEsqash', {
        is: false,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.error.serviceItem.required',
          ),
        ),
      })
      .nullable(),
    commonInvoiceItem: Yup.string()
      .when('isEsqash', {
        is: true,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.error.serviceItem.required',
          ),
        ),
      })
      .nullable(),
  });
