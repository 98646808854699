import classnames from 'classnames';
import { ReactNode, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Manager, usePopper } from 'react-popper';
import observeRect from '@reach/observe-rect';
import { useLifecycles } from 'react-use';
import { Flex, Text5 } from '@appclose/ui';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { TourNavigation } from '../TourNavigation';

import styles from './TourTooltip.module.scss';

export type TourTooltipPositionType =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-start'
  | 'bottom-start';

export type TourTooltipPropsType = {
  position?: TourTooltipPositionType;
  content?: ReactNode;
  className?: string;
  offsetLeft?: number;
  offsetTop?: number;
  children: ReactNode;
};

export default function TourTooltip({
  position = 'bottom',
  content,
  className,
  offsetLeft = 0,
  offsetTop = 12,
  children,
}: TourTooltipPropsType) {
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {
    styles: { popper: popperStyles },
    attributes,
    state,
    forceUpdate,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetLeft, offsetTop],
        },
      },
    ],
    placement: position,
  });

  const rectObserver = useMemo(() => {
    if (!referenceElement || !forceUpdate) {
      return;
    }

    return observeRect(referenceElement, forceUpdate);
  }, [referenceElement, forceUpdate]);

  rectObserver?.observe();

  useLifecycles(() => undefined, rectObserver?.unobserve);

  return (
    <Manager>
      <div
        className={styles.tooltipTarget}
        ref={(r) => {
          setReferenceElement(r?.firstChild as Element);
        }}
      >
        {children}
      </div>
      {createPortal(
        <div
          ref={setPopperElement}
          style={popperStyles}
          className={classnames(styles.tooltip, className)}
          data-placement={state?.placement}
          onClick={(e) => e.stopPropagation()}
          {...attributes}
        >
          <Flex direction="column" gap={[0, 10]}>
            <Text5 as="div" color="contrast" weight="semibold">
              {content}
            </Text5>
            <TourNavigation />
          </Flex>
          <ArrowIcon className={styles.arrow} />
        </div>,
        document.body,
      )}
    </Manager>
  );
}
