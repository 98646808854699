import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';

export const E_CHECK_BANK_ROUTING_NUMBER_VALIDATION_SCHEMA = Yup.string()
  .required(getI18nErrorMessage('error.routingNumber.required'))
  .min(9, ({ min }) =>
    getI18nErrorMessage('error.routingNumber.minLength', { min }),
  )
  .max(9, ({ max }) =>
    getI18nErrorMessage('error.routingNumber.maxLength', { max }),
  )
  .test(
    'validRoutingNumber',
    getI18nErrorMessage('error.routingNumber.testFormat'),
    (routingNumber) => {
      if (!routingNumber) {
        return false;
      }

      if (routingNumber.length !== 9) {
        return false;
      }

      const first =
        3 *
        (parseInt(routingNumber[0], 10) +
          parseInt(routingNumber[3], 10) +
          parseInt(routingNumber[6], 10));
      const second =
        7 *
        (parseInt(routingNumber[1], 10) +
          parseInt(routingNumber[4], 10) +
          parseInt(routingNumber[7], 10));
      const third =
        parseInt(routingNumber[2], 10) +
        parseInt(routingNumber[5], 10) +
        parseInt(routingNumber[8], 10);
      const result = first + second + third;

      return result % 10 === 0;
    },
  );
