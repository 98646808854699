import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import _merge from 'lodash/merge';
import _isEmpty from 'lodash/isEmpty';

import coreReducers from './reducers';
import coreSagas from './sagas';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

export default function configureStore({
  reducers = {},
  sagas = undefined,
  initialState = {},
  middlewares = [],
}: { [key: string]: any } = {}) {
  const { common, data, ui } = _merge({}, coreReducers, reducers);
  const appReducers = combineReducers<{}>({
    common: !_isEmpty(common) ? combineReducers(common) : () => ({}),
    data: !_isEmpty(data) ? combineReducers(data) : () => ({}),
    ui: !_isEmpty(ui) ? combineReducers(ui) : () => ({}),
  });

  const store = createStore(
    (state: any, action) => {
      return appReducers(state, action);
    },
    initialState,
    composeEnhancers(applyMiddleware(...middlewares, sagaMiddleware)),
  );

  sagaMiddleware.run(function* root() {
    yield all([coreSagas, sagas].filter(Boolean).map((saga) => saga()));
  });

  return store;
}
