import React from 'react';
import classnames from 'classnames';

import styles from './Grid.module.scss';
import { GridType } from './Grid.types';

const Grid = React.forwardRef<HTMLElement, GridType>(
  (
    {
      as: Component = 'div',
      inline = false,
      align,
      justify,
      alignContent,
      justifyContent,
      autoColumns,
      autoRows,
      autoFlow,
      template,
      columns,
      rows,
      areas,
      gap,
      className,
      children,
    },
    ref,
  ) => (
    <Component
      ref={ref}
      className={classnames(
        styles.relative,
        {
          [styles.grid]: !inline,
          [styles.inlineGrid]: inline,
        },
        className,
      )}
      style={{
        gridTemplate: template,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gridTemplateAreas: areas,
        alignItems: align,
        justifyItems: justify,
        alignContent,
        justifyContent,
        gridAutoColumns: autoColumns,
        gridAutoRows: autoRows,
        gridAutoFlow: autoFlow,
        ...(gap
          ? {
              columnGap: gap[0],
              gridColumnGap: gap[0],
              gridRowGap: gap[1],
              rowGap: gap[1],
            }
          : {}),
      }}
    >
      {children}
    </Component>
  ),
);

export default Grid;
