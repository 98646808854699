import {
  BILLING_INVOICES_ROUTE,
  BILLING_ROUTE,
  BILLING_TRANSACTIONS_ROUTE,
  CALENDAR_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CHATS_ROUTE,
  CHAT_MESSAGE_ROUTE,
  CHAT_ROUTE,
  CLIENTS_OUTGOING_REQUESTS_ROUTE,
  CLIENTS_PENDING_REQUESTS_ROUTE,
  CLIENTS_ROUTE,
  CLIENTS_SHARED_CHATS_ROUTE,
  CLIENTS_SHARED_CHAT_MESSAGE_ROUTE,
  CLIENTS_SHARED_CHAT_ROUTE,
  CLIENT_CALENDAR_ROUTE,
  CLIENT_CHATS_ROUTE,
  CLIENT_CHAT_MESSAGE_ROUTE,
  CLIENT_CHAT_ROUTE,
  CLIENT_INFO_ROUTE,
  CLIENT_ROUTE,
  CLIENT_SHARED_CHATS_ROUTE,
  CLIENT_SHARED_CHAT_MESSAGE_ROUTE,
  CLIENT_SHARED_CHAT_ROUTE,
  CONTACTS_ROUTE,
  CONTACT_FILES_NODE_ROUTE,
  CONTACT_FILES_ROOT_ROUTE,
  CONTACT_FLAT_FEES_ROUTE,
  CONTACT_INVOICES_ROUTE,
  CONTACT_OVERVIEW_ROUTE,
  CONTACT_ROUTE,
  CONTACT_TRANSACTIONS_ROUTE,
  DASHBOARD_ROUTE,
  ENTITIES_ROUTE,
  EXCHANGE_TOKENS_ROUTE,
  EXPORT_CHAT_REQUEST_ROUTE,
  FORBIDDEN_ROUTE,
  KYB_ROUTE,
  NOT_FOUND_ROUTE,
  ONBOARDING_BLOCKED_ROUTE,
  ONBOARDING_COMPLETE_ROUTE,
  ONBOARDING_MERCHANT_APPLICATION_ROUTE,
  ONBOARDING_PROFILE_ROUTE,
  ONBOARDING_ROUTE,
  PROFILE_CALENDAR_SYNC_ROUTE,
  PROFILE_ROUTE,
  PROFILE_SECURITY_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  REPORTS_CONTACTS_ROUTE,
  REPORTS_INVOICES_ROUTE,
  REPORTS_ROUTE,
  REPORTS_TRANSACTIONS_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETUP_PASSWORD_ROUTE,
  SHARE_CHAT_REQUEST_ROUTE,
  SIGNATURE_DOCUMENTS_ROUTE,
  SIGNATURE_ROUTE,
  SIGNATURE_TEMPLATES_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_ROUTE,
  FILES_ROUTE,
  FILE_STORAGE_ROOT_ROUTE,
  FILE_STORAGE_NODE_ROUTE,
} from './routes';

export type RoutesMetaItem = { page: string; tab?: string };
type RoutesMeta = Record<string, RoutesMetaItem>;

export const routesMeta: RoutesMeta = {
  [SIGN_IN_ROUTE]: {
    page: 'SIGN_IN',
  },
  [NOT_FOUND_ROUTE]: {
    page: 'NOT_FOUND',
  },
  [EXCHANGE_TOKENS_ROUTE]: {
    page: 'EXCHANGE_TOKENS',
  },
  [SHARE_CHAT_REQUEST_ROUTE]: {
    page: 'SHARE_CHAT_REQUEST',
  },
  [EXPORT_CHAT_REQUEST_ROUTE]: {
    page: 'EXPORT_CHAT_REQUEST',
  },
  [FORBIDDEN_ROUTE]: {
    page: 'FORBIDDEN',
  },
  [SETUP_PASSWORD_ROUTE]: {
    page: 'SETUP_PASSWORD',
  },
  [RESET_PASSWORD_ROUTE]: {
    page: 'RESET_PASSWORD',
  },
  [CHANGE_PASSWORD_ROUTE]: {
    page: 'CHANGE_PASSWORD',
  },
  [KYB_ROUTE]: {
    page: 'KYB',
  },
  [ONBOARDING_ROUTE]: {
    page: 'ONBOARDING',
  },
  [ONBOARDING_PROFILE_ROUTE]: {
    page: 'ONBOARDING',
    tab: 'PROFILE',
  },
  [ONBOARDING_MERCHANT_APPLICATION_ROUTE]: {
    page: 'ONBOARDING',
    tab: 'MERCHANT_APPLICATION',
  },
  [ONBOARDING_COMPLETE_ROUTE]: {
    page: 'ONBOARDING',
    tab: 'COMPLETE',
  },
  [ONBOARDING_BLOCKED_ROUTE]: {
    page: 'ONBOARDING',
    tab: 'BLOCKED',
  },
  [DASHBOARD_ROUTE]: {
    page: 'DASHBOARD',
  },
  [CLIENTS_ROUTE]: {
    page: 'CLIENTS',
  },
  [CLIENTS_OUTGOING_REQUESTS_ROUTE]: {
    page: 'CLIENTS',
    tab: 'OUTGOING_REQUESTS',
  },
  [CLIENTS_PENDING_REQUESTS_ROUTE]: {
    page: 'CLIENTS',
    tab: 'PENDING_REQUESTS',
  },
  [CLIENTS_SHARED_CHATS_ROUTE]: {
    page: 'CLIENTS',
    tab: 'SHARED_CHATS',
  },
  [CLIENTS_SHARED_CHAT_ROUTE]: {
    page: 'CLIENTS',
    tab: 'SHARED_CHAT',
  },
  [CLIENTS_SHARED_CHAT_MESSAGE_ROUTE]: {
    page: 'CLIENTS',
    tab: 'SHARED_CHAT',
  },
  [CLIENT_ROUTE]: {
    page: 'CLIENT',
  },
  [CLIENT_CALENDAR_ROUTE]: {
    page: 'CLIENT',
    tab: 'CALENDAR',
  },
  [CLIENT_CHATS_ROUTE]: {
    page: 'CLIENT',
    tab: 'CHATS',
  },
  [CLIENT_CHAT_ROUTE]: {
    page: 'CLIENT',
    tab: 'CHAT',
  },
  [CLIENT_CHAT_MESSAGE_ROUTE]: {
    page: 'CLIENT',
    tab: 'CHAT',
  },
  [CLIENT_INFO_ROUTE]: {
    page: 'CLIENT',
    tab: 'INFO',
  },
  [CLIENT_SHARED_CHATS_ROUTE]: {
    page: 'CLIENT',
    tab: 'SHARED_CHATS',
  },
  [CLIENT_SHARED_CHAT_ROUTE]: {
    page: 'CLIENT',
    tab: 'SHARED_CHAT',
  },
  [CLIENT_SHARED_CHAT_MESSAGE_ROUTE]: {
    page: 'CLIENT',
    tab: 'SHARED_CHAT',
  },
  [ENTITIES_ROUTE]: {
    page: 'ENTITIES',
  },
  [CONTACTS_ROUTE]: {
    page: 'CONTACTS',
  },
  [CONTACT_ROUTE]: {
    page: 'CONTACT',
  },
  [CONTACT_OVERVIEW_ROUTE]: {
    page: 'CONTACT',
    tab: 'OVERVIEW',
  },
  [CONTACT_INVOICES_ROUTE]: {
    page: 'CONTACT',
    tab: 'INVOICES',
  },
  [CONTACT_TRANSACTIONS_ROUTE]: {
    page: 'CONTACT',
    tab: 'TRANSACTIONS',
  },
  [CONTACT_FLAT_FEES_ROUTE]: {
    page: 'CONTACT',
    tab: 'FLAT_FEES',
  },
  [CONTACT_FILES_ROOT_ROUTE]: {
    page: 'CONTACT',
    tab: 'FILES',
  },
  [CONTACT_FILES_NODE_ROUTE]: {
    page: 'CONTACT',
    tab: 'FILES',
  },
  [BILLING_ROUTE]: {
    page: 'BILLING',
  },
  [BILLING_INVOICES_ROUTE]: {
    page: 'BILLING',
    tab: 'INVOICES',
  },
  [BILLING_TRANSACTIONS_ROUTE]: {
    page: 'BILLING',
    tab: 'TRANSACTIONS',
  },
  [SIGN_ROUTE]: {
    page: 'SIGN',
  },
  [REPORTS_ROUTE]: {
    page: 'REPORTS',
  },
  [REPORTS_TRANSACTIONS_ROUTE]: {
    page: 'REPORTS',
    tab: 'TRANSACTIONS',
  },
  [REPORTS_INVOICES_ROUTE]: {
    page: 'REPORTS',
    tab: 'INVOICES',
  },
  [REPORTS_CONTACTS_ROUTE]: {
    page: 'REPORTS',
    tab: 'CONTACTS',
  },
  [SIGNATURE_ROUTE]: {
    page: 'SIGNATURE',
  },
  [SIGNATURE_DOCUMENTS_ROUTE]: {
    page: 'SIGNATURE',
    tab: 'DOCUMENTS',
  },
  [SIGNATURE_TEMPLATES_ROUTE]: {
    page: 'SIGNATURE',
    tab: 'TEMPLATES',
  },
  [PROFILE_ROUTE]: {
    page: 'PROFILE',
  },
  [PROFILE_SETTINGS_ROUTE]: {
    page: 'PROFILE',
    tab: 'SETTINGS',
  },
  [PROFILE_SECURITY_ROUTE]: {
    page: 'PROFILE',
    tab: 'SECURITY',
  },
  [PROFILE_CALENDAR_SYNC_ROUTE]: {
    page: 'PROFILE',
    tab: 'CALENDAR_SYNC',
  },
  [CHATS_ROUTE]: {
    page: 'CHATS',
  },
  [CHAT_ROUTE]: {
    page: 'CHAT',
  },
  [CHAT_MESSAGE_ROUTE]: {
    page: 'CHAT',
  },
  [CALENDAR_ROUTE]: {
    page: 'CALENDAR',
  },
  [FILES_ROUTE]: {
    page: 'FILES',
  },
  [FILE_STORAGE_ROOT_ROUTE]: {
    page: 'FILE_STORAGE',
  },
  [FILE_STORAGE_NODE_ROUTE]: {
    page: 'FILE_STORAGE_NODE',
  },
};
