import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  dateManager,
  Loader,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@appclose/core';

import { I18n } from 'i18n';
import {
  BankAccountClasses,
  StripeBankAccountStatus,
} from '__generated__/globalTypes';
import BankAccountNotLinked from 'components/common/BankAccountNotLinked';
import useBankAccounts from 'hooks/useBankAccounts';

import { SERVER_DATE_FORMAT } from 'constants/date';

import {
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
} from '../../__generated__/ReceivePaymentModal.gql';

import { FETCH_CONTACT, FETCH_INVOICE } from '../../ReceivePaymentModal.gql';
import ToOperatingForm from './components/ToOperatingForm';
import {
  getInitialContact,
  getInitialInvoices,
} from '../../ReceivePaymentModa.utils';

import {
  MainInfoBlockPropsType,
  ReceivePaymentFormValuesType,
} from './PaymentInfoFormsBlock.types';

export default function MainInfoBlock({
  invoiceId,
  contactId,
  onSubmit,
  onFormChange,
  onClose,
}: MainInfoBlockPropsType) {
  const [isSubmitting] = useState(false);
  const [invoices, setInvoices] =
    useState<ReceivePaymentFormValuesType['invoices']>();
  const [contact, setContact] =
    useState<ReceivePaymentFormValuesType['contact']>();
  const { loading: loadingBankAccounts, bankAccounts } = useBankAccounts();

  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || !!invoiceId,
  });
  const { loading: loadingInvoice, data: invoiceData } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id: invoiceId as string },
    skip: !invoiceId,
  });

  const isLoading =
    loadingBankAccounts || loadingInvoice || loadingContact || isSubmitting;

  useEffect(() => {
    setInvoices(getInitialInvoices(invoiceData));
  }, [invoiceData]);

  useEffect(() => {
    setContact((prev) => prev || getInitialContact(contactData, invoiceData));
  }, [contactData, invoiceData]);

  const initialValues = useMemo(() => {
    return {
      invoices: invoices || getInitialInvoices(invoiceData),
      contact: contact || getInitialContact(contactData, invoiceData),
      paymentDate: dateManager()
        .parse()
        .startOf('day')
        .format(SERVER_DATE_FORMAT),
    };
  }, [invoices, contact, contactData, invoiceData]);

  const invoiceDestinationAccount = invoiceData?.invoice.destinationAccount;
  const operatingAccount = bankAccounts?.find(
    (acc) => acc.class === BankAccountClasses.OPERATING,
  );

  const isOperatingConnected =
    operatingAccount?.stripeBankAccountStatus ===
    StripeBankAccountStatus.CONNECTED;

  return isLoading ? (
    <Loader />
  ) : (
    <Tabs
      onSelect={() => setInvoices([])}
      defaultIndex={
        invoiceDestinationAccount === BankAccountClasses.TRUST ? 1 : 0
      }
    >
      <TabList>
        <Tab>
          <I18n id="modal.receivePayment.tab.toOperating" />
        </Tab>
      </TabList>
      <TabPanel>
        {isOperatingConnected ? (
          <ToOperatingForm
            initialValues={{
              ...initialValues,
              destinationAccount: BankAccountClasses.OPERATING,
            }}
            onSubmit={onSubmit}
            onCancel={onClose}
            onChange={onFormChange}
            setContact={setContact}
          />
        ) : (
          <BankAccountNotLinked type={BankAccountClasses.OPERATING} />
        )}
      </TabPanel>
    </Tabs>
  );
}
