import { SignerTypes } from 'constants/esign';
import { useIntl } from 'i18n';
import { useCallback, useMemo } from 'react';

export function useSignerTypes() {
  const { t } = useIntl();
  const signerTypes: Record<SignerTypes, string> = useMemo(
    () => ({
      [SignerTypes.CLIENT]: t('enum.signerTypes.client'),
      [SignerTypes.YOU]: t('enum.signerTypes.you'),

      // [SignerTypes.CONTACT]: t('enum.signerTypes.contact'),
      // [SignerTypes.MEMBER]: t('enum.signerTypes.teamMember'),
      // [SignerTypes.CUSTOM]: t('enum.signerTypes.custom'),
    }),
    [t],
  );

  const signerTypesOptions: {
    label: string;
    value: SignerTypes;
  }[] = useMemo(
    () =>
      Object.entries(signerTypes).map(([value, label]) => ({
        value: value as SignerTypes,
        label,
      })),
    [signerTypes],
  );

  const getSignerTypeLabel = useCallback(
    (type?: SignerTypes | null) => {
      if (!type) {
        return '';
      }

      return signerTypes[type];
    },
    [signerTypes],
  );

  return { signerTypesOptions, getSignerTypeLabel };
}
