import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import noop from 'lodash/noop';

import { Entities } from 'constants/entities';

import {
  OnUpdateFilesPropsType,
  UpdateFilesMutation,
  UpdateFilesMutationVariables,
  UseUpdateFilesType,
} from './useUpdateFiles.types';
import { Mutations } from './useUpdateFiles.const';

export default function useUpdateFiles(
  entityType: Exclude<
    Entities,
    Entities.SIGNATURE | Entities.SIGNATURE_TEMPLATE
  >,
): UseUpdateFilesType {
  const [updateFiles] = useMutation<
    UpdateFilesMutation,
    UpdateFilesMutationVariables
  >(Mutations[entityType]);

  const onUpdateFiles = useCallback(
    async ({ entityId, newFiles, fileIdsToDelete }: OnUpdateFilesPropsType) => {
      await updateFiles({
        variables: {
          input: {
            newFiles,
            fileIdsToDelete,
            entityId,
          },
        },
      }).catch(noop);
    },
    [updateFiles],
  );

  return {
    onUpdateFiles,
  };
}
