import React, { useEffect, useMemo } from 'react';
import set from 'lodash/set';
import { useQuery, useSubscription } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  FormArrayField,
  FormError,
  getFieldError,
  MediaQueryDesktop,
  OverlayLoader,
  useFormContext,
  useIsTabletDevice,
  Fieldset,
  traceError,
} from '@appclose/core';
import { InvoiceIcon } from '@appclose/icons';

import { InvoiceStatusesTypes, OrderTypes } from '__generated__/globalTypes';
import Date from 'components/common/Date';
import InvoiceStatus from 'components/common/InvoiceStatus';
import InvoiceDateAndPaymentStatus from 'components/common/InvoiceDateAndPaymentStatus';
import MobileTable from 'components/common/MobileTable';
import { sanitizeAmount } from 'controllers/amount';
import { I18n, useIntl } from 'i18n';

import InvoicesFieldTableHeader from './components/InvoicesFieldTableHeader';
import {
  FETCH_INVOICES,
  ON_INVOICE_CREATE,
  ON_INVOICE_UPDATE,
} from './InvoicesField.gql';
import {
  FetchInvoicesQuery,
  FetchInvoicesQueryVariables,
  InvoiceCreateSubscription,
  InvoiceCreateSubscriptionVariables,
  InvoiceUpdateSubscription,
  InvoiceUpdateSubscriptionVariables,
} from './__generated__/InvoicesField.gql';
import { ReceivePaymentFormValuesType } from '../../../../PaymentInfoFormsBlock.types';
import styles from './InvoicesField.module.scss';

export default function InvoicesField() {
  const { t } = useIntl();
  const {
    values: { destinationAccount, contact, invoices: values = [] },
    setValues,
  } = useFormContext<ReceivePaymentFormValuesType>();
  const contactId = contact?.id;
  const isMobile = useIsTabletDevice();

  const fetchFilter = {
    contactId,
    destinationAccount,
    statuses: [
      InvoiceStatusesTypes.CREATED,
      InvoiceStatusesTypes.PARTIALLY_PAID,
      InvoiceStatusesTypes.SENT,
      InvoiceStatusesTypes.VIEWED,
    ],
  };

  const { loading, data, refetch } = useQuery<
    FetchInvoicesQuery,
    FetchInvoicesQueryVariables
  >(FETCH_INVOICES, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        filter: fetchFilter,
        order: { dueDate: OrderTypes.ASC },
      },
    },
  });
  const invoices = useMemo(
    () => data?.invoices?.items || [],
    [data?.invoices?.items],
  );
  const count = data?.invoices?.total || '';
  const selected = useMemo(() => values || [], [values]);
  const total = sanitizeAmount(
    selected.reduce((sum, { balanceDue }) => sum + balanceDue, 0),
  );
  const hasInvoices = !!invoices.length;

  useSubscription<
    InvoiceCreateSubscription,
    InvoiceCreateSubscriptionVariables
  >(ON_INVOICE_CREATE, {
    variables: {
      invoiceSubscriptionInput: fetchFilter,
    },
    onSubscriptionData: async () => {
      try {
        await refetch({
          input: {
            skip: 0,
            take: invoices.length + 1,
            filter: fetchFilter,
          },
        });
      } catch (e: any) {
        traceError(e);
      }
    },
  });

  useSubscription<
    InvoiceUpdateSubscription,
    InvoiceUpdateSubscriptionVariables
  >(ON_INVOICE_UPDATE, {
    variables: {
      invoiceSubscriptionInput: fetchFilter,
    },
  });

  useEffect(() => {
    setValues((values) => set(values, 'amount', total), true);
  }, [setValues, total]);

  useEffect(() => {
    if (
      !loading &&
      invoices.length === 1 &&
      !selected.find((item) => item.id === invoices[0].id)
    ) {
      setValues((values) => set(values, 'invoices', invoices), true);
    }
  }, [invoices, loading, selected, setValues]);

  useEffect(() => {
    if (!loading && !hasInvoices && selected.length) {
      setValues((values) => set(values, 'invoices', []), true);
    }
  }, [loading, hasInvoices, selected, setValues]);

  const TableComponent = isMobile ? MobileTable : Table;

  return (
    <FormArrayField name="invoices">
      {({ push, remove, name, form, values }) => {
        let error = getFieldError(form, name);
        error = typeof error === 'string' ? error : undefined;

        return (
          <Fieldset
            title={t('modal.receivePayment.form.invoices.title', { count })}
          >
            {hasInvoices || loading ? (
              <OverlayLoader loading={loading} className={styles.content}>
                {hasInvoices && (
                  <TableComponent>
                    <InvoicesFieldTableHeader invoices={invoices} />
                    <TableBody>
                      <>
                        {invoices.map((invoice) => {
                          const {
                            id,
                            invoiceNumber,
                            createdAt,
                            dueDate,
                            balanceDue,
                            status,
                            overdueDayQty,
                            missedPaymentQty,
                          } = invoice;
                          const checked = !!selected.find(
                            (item) => item.id === id,
                          );

                          return (
                            <TableRow
                              key={`invoice-${id}`}
                              onClick={() => {
                                if (
                                  !values?.some(
                                    ({ id: valueId }) => id === valueId,
                                  )
                                ) {
                                  push(invoice);
                                } else {
                                  const index = selected.findIndex(
                                    (item) => item.id === id,
                                  );

                                  if (index !== -1) {
                                    remove(index);
                                  }
                                }
                              }}
                            >
                              <TableCell width={`${isMobile ? 30 : 50}px`}>
                                <Checkbox readOnly checked={checked} />
                              </TableCell>
                              <MediaQueryDesktop>
                                <TableCell skin="icon">
                                  <InvoiceIcon />
                                </TableCell>
                              </MediaQueryDesktop>
                              <TableCell theme="light">
                                <p className={styles.activity}>
                                  {invoiceNumber}
                                </p>
                                <MediaQueryDesktop>
                                  <Date value={createdAt} />
                                </MediaQueryDesktop>
                              </TableCell>
                              <MediaQueryDesktop>
                                <TableCell theme="light">
                                  <InvoiceDateAndPaymentStatus
                                    dueDate={dueDate}
                                    overdueDayQty={overdueDayQty}
                                    missedPaymentQty={missedPaymentQty}
                                  />
                                </TableCell>
                              </MediaQueryDesktop>
                              <TableCell>
                                <InvoiceStatus status={status} />
                              </TableCell>
                              <TableCell theme="strong" align="right">
                                <Amount value={balanceDue} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    </TableBody>
                  </TableComponent>
                )}
              </OverlayLoader>
            ) : (
              <p className={styles.emptyResult}>
                <I18n
                  id="modal.receivePayment.form.invoices.empty"
                  values={{
                    type: t('modal.receivePayment.form.invoices.contact'),
                  }}
                />
              </p>
            )}
            {hasInvoices && (
              <Flex
                alignItems="center"
                justify="space-between"
                className={styles.total}
              >
                <p className={styles.totalTitle}>
                  <I18n
                    id="modal.receivePayment.form.invoices.total"
                    values={{ total: selected.length || 0 }}
                  />
                </p>
                <Amount value={total} className={styles.totalAmount} />
              </Flex>
            )}
            <div id={name}>
              {error && <FormError className={styles.error}>{error}</FormError>}
            </div>
          </Fieldset>
        );
      }}
    </FormArrayField>
  );
}
