import React from 'react';

export enum TourStepElementType {
  Block = 'block',
  Raw = 'raw',
  WithBackground = 'withBackground',
}

export type TourStepElement = {
  selector: string;
  type: TourStepElementType;
  className?: string;
};

export type TourStep = {
  popoverPosition?:
    | 'top'
    | 'top-start'
    | 'bottom'
    | 'bottom-start'
    | 'left'
    | 'right';
  content: React.ReactNode;
  elements: TourStepElement[];
  name: string;
  skipScroll?: boolean;
};

export type TourConfig = {
  name: string;
  steps: TourStep[];
  autoStart?: boolean;
};
