import React from 'react';
import classnames from 'classnames';

import { MenuTypes } from './Menu.types';
import styles from './Menu.module.scss';

export default function Menu({
  theme,
  className,
  children,
}: MenuTypes): React.ReactElement {
  return (
    <ul
      className={classnames(
        styles.menu,
        {
          [styles.simple]: theme === 'simple',
        },
        className,
      )}
    >
      {children}
    </ul>
  );
}
