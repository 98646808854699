import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { TitlePropsType, TitleNPropsType } from './Title.types';
import styles from './Title.module.scss';

export const Title = forwardRef<HTMLHeadingElement, TitlePropsType>(
  (
    {
      children,
      as: Component = 'h1',
      className,
      color,
      align: textAlign,
      offset,
      onClick,
    },
    ref,
  ) => {
    const margins = {
      marginTop: offset?.top,
      marginRight: offset?.right,
      marginBottom: offset?.bottom,
      marginLeft: offset?.left,
    };

    return (
      <Component
        onClick={onClick}
        style={{ ...margins, textAlign }}
        className={classnames(styles.title, className, {
          [styles.titleLevel1]: Component === 'h1',
          [styles.titleLevel2]: Component === 'h2',
          [styles.titleLevel3]: Component === 'h3',
          [styles.titleLevel4]: Component === 'h4',
          [styles.titleLevel5]: Component === 'h5',
          [styles.titleLevel6]: Component === 'h6',
          [styles.primary]: color === 'primary',
          [styles.secondary]: color === 'secondary',
          [styles.contrast]: color === 'contrast',
          [styles.gray]: color === 'gray',
          [styles.gray030]: color === 'gray030',
          [styles.warning]: color === 'warning',
          [styles.links]: color === 'links',
        })}
        ref={ref}
      >
        {children}
      </Component>
    );
  },
);

export const Title1 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h1" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);

export const Title2 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h2" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);

export const Title3 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h3" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);

export const Title4 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h4" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);

export const Title5 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h5" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);

export const Title6 = forwardRef<HTMLHeadingElement, TitleNPropsType>(
  ({ children, ...props }, ref) => {
    return (
      <Title as="h6" {...props} ref={ref}>
        {children}
      </Title>
    );
  },
);
