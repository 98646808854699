import { PhoneNumberInput, PhoneTypes } from '../__generated__/globalTypes';

export const INITIAL_PRIMARY_PHONE: PhoneNumberInput = {
  type: PhoneTypes.MOBILE,
  number: '',
  isPrimary: true,
};

export const INITIAL_PHONE: PhoneNumberInput = {
  type: PhoneTypes.MOBILE,
  number: '',
  isPrimary: false,
};

export const DEFAULT_COUNTRY_CODE = 'US';
