import { gql } from '@apollo/client';

export const CALENDAR_FRAGMENT = gql`
  fragment CalendarData on AppcloseCalendarType {
    id
    title
    calendarGroupId
    isDefault
    color
    clientId
  }
`;

export const FETCH_CALENDARS = gql`
  query FetchCalendars {
    calendars: listCalendars {
      ...CalendarData
    }
  }
  ${CALENDAR_FRAGMENT}
`;
