import { useCallback } from 'react';
import { FormGroup, useFormContext, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { useIntl } from 'i18n';

import { SignerPropsType } from './Signer.types';
import { SignersFormFieldValuesType } from '../../SignersFormField.types';
import ClientSelectFormField from 'components/fields/ClientSelectFormField';
import SignerSelectFormField from 'components/fields/SignerSelectFormField';
import { SignerTypes } from 'constants/esign';

export default function Signer({
  role,
  index,
  resolveName,
  className,
  profileId,
}: SignerPropsType) {
  const { t } = useIntl();
  const { id: roleId = '', roleName } = role || {};
  const {
    setFieldValue,
    values: { signers },
  } = useFormContext<SignersFormFieldValuesType>();
  const { type } = signers[index];

  const onTypeChange = useCallback(
    (selectedOption: { value: string }) => {
      const value = selectedOption.value as SignerTypes;

      if (value === SignerTypes.YOU && profileId) {
        setFieldValue(
          resolveName(),
          { type: value, teamMember: { id: profileId }, roleId },
          true,
        );
      } else {
        setFieldValue(resolveName(), { type: value, roleId }, true);
      }
    },
    [roleId, resolveName, setFieldValue, profileId],
  );

  return (
    <Fieldset
      className={className}
      title={t('modal.signatureDocument.form.signers.signer', {
        info: `${index + 1}${roleName ? ` - ${roleName}` : ''}`,
      })}
    >
      <FormGroup>
        <FormGrid>
          <SignerSelectFormField
            name={resolveName('type')}
            label={t('modal.signatureDocument.form.signers.signer.type.label')}
            onChange={onTypeChange}
          />
          {type === SignerTypes.CLIENT && (
            <ClientSelectFormField
              name={resolveName('client')}
              onChange={(client) =>
                setFieldValue(resolveName('email'), client?.email, true)
              }
            />
          )}

          {/* {type === SignerTypes.CONTACT && (
            <ContactSelectFormField
              allowAddNew
              name={resolveName('contact')}
              onChange={(contact) =>
                setFieldValue(resolveName('email'), contact?.email, true)
              }
            />
          )} */}
          {/* {type === SignerTypes.MEMBER && (
            <TeamMemberSelectFormField
              name={resolveName('teamMember')}
              label={t(
                'modal.signatureDocument.form.signers.signer.member.teamMember',
              )}
              placeholder={t(
                'modal.signatureDocument.form.signers.signer.member.teamMember.placeholder',
              )}
              showLabel="onFocus"
              showPlaceholder="always"
              onChange={(teamMember) =>
                setFieldValue(resolveName('email'), teamMember?.email, true)
              }
            />
          )} */}
          {/* {type === SignerTypes.CUSTOM && (
            <InputFormField
              name={resolveName('externalSigner.name')}
              label={t(
                'modal.signatureDocument.form.signers.signer.custom.name',
              )}
            />
          )}
          <InputFormField
            name={resolveName(
              type === SignerTypes.CUSTOM ? 'externalSigner.email' : 'email',
            )}
            label={t('modal.signatureDocument.form.signers.signer.email')}
            disabled={type !== SignerTypes.CUSTOM}
          /> */}
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
