import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import get from 'lodash/get';
import set from 'lodash/set';
import { TourContext, TourContextType } from './Tour.context';
import { TourConfig } from './Tour.types';
import {
  useIsMobileDevice,
  useLocalStorage,
  useLockBodyScroll,
} from '@appclose/core';
import { TourOverlay } from './components/TourOverlay';
import { TourStep } from './components/TourStep';
import { TOUR_SEEN } from 'constants/localStorage';
import { session } from 'controllers/session';
import { getTokenPayload } from '@appclose/lib';
import { TourManagerContext } from './TourManagerProvider';

type TourProviderPropsType = {
  config: TourConfig;
  children: React.ReactNode;
  autoStart?: boolean;
  onStarted?: () => void;
  onEnded?: () => void;
  onBeforeNextStep?: (
    index: number,
    startTour: () => void,
    endTour: () => void,
  ) => void;
  onBeforePrevStep?: (
    index: number,
    startTour: () => void,
    endTour: () => void,
  ) => void;
};

export type TourLocalState = {
  [name: string]: {
    [id: string]: {
      [stepName: string]: boolean;
    };
  };
};

const defaultConfig: TourConfig = {
  name: '',
  steps: [],
};

const findFirstNotEmptyStep = (elements: (Element | null)[][]) =>
  elements.findIndex((el) => el.length > 0);

export function TourProvider({
  autoStart = true,
  config,
  children,
  onStarted,
  onEnded,
  onBeforeNextStep,
  onBeforePrevStep,
}: TourProviderPropsType) {
  const isMobile = useIsMobileDevice();

  const { registerTour, unregisterTour } = useContext(TourManagerContext);

  const resolvedConfig = useMemo(
    () => ({ ...defaultConfig, ...config }),
    [config],
  );

  const [tourSeenState, setTourSeenState] = useLocalStorage<TourLocalState>(
    TOUR_SEEN,
    {},
  );
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [tourStarted, setTourStarted] = useState<boolean>(false);
  const [stepsElements, setStepsElements] = useState<(Element | null)[][]>([]);
  const { esq } = getTokenPayload(session.getAccessToken() || '');
  const userId = esq?.userId;

  useLockBodyScroll(tourStarted);

  const tourName = resolvedConfig.name;

  useEffect(() => {
    registerTour(resolvedConfig);

    return () => {
      unregisterTour(tourName);
    };
  }, [resolvedConfig, registerTour, unregisterTour, tourName]);

  const isStepSeen = useCallback(
    (stepName: string) => {
      return get(tourSeenState, [userId, tourName, stepName], false);
    },
    [tourName, userId, tourSeenState],
  );

  const findFirstUnseenStep = useCallback(
    (elements: (Element | null)[][]) => {
      const index = resolvedConfig.steps.findIndex(
        ({ name }) => !isStepSeen(name),
      );

      const res = index === -1 ? 0 : index;

      if (elements[res].length === 0) {
        return findFirstNotEmptyStep(elements);
      }

      return res;
    },
    [resolvedConfig.steps, isStepSeen],
  );

  const areAllStepsSeen = useCallback(
    (elements: (Element | null)[][]) => {
      return elements.every(
        (e, index) =>
          e.length === 0 || isStepSeen(resolvedConfig.steps[index].name),
      );
    },
    [resolvedConfig.steps, isStepSeen],
  );

  const setStepSeen = useCallback(
    (stepName: string) => {
      setTourSeenState(() => {
        // hack to get global localStorage value instead of local state of useLocalStorage hook
        // to prevent from overwriting multiple tours states on same page
        const newState = JSON.parse(localStorage.getItem(TOUR_SEEN) || '');

        set(newState, [userId, tourName, stepName], true);

        return newState;
      });
    },
    [tourName, userId, setTourSeenState],
  );

  const getElements = useCallback(() => {
    const res = resolvedConfig.steps.reduce(
      (acc, curr) => {
        acc.push(
          curr.elements
            .map((element) => document.querySelector(element.selector))
            .filter(Boolean),
        );

        return acc;
      },
      [] as (Element | null)[][],
    );

    setStepsElements(res);

    return res;
  }, [resolvedConfig.steps]);

  const startTour = useCallback(() => {
    const elements = getElements();

    if (elements.length > 0 && !areAllStepsSeen(elements)) {
      setCurrentStep(findFirstUnseenStep(elements));
      setTourStarted(true);
      onStarted?.();
    }
  }, [getElements, areAllStepsSeen, onStarted, findFirstUnseenStep]);

  const endTour = useCallback(() => {
    setTourStarted(false);
    onEnded?.();
  }, [onEnded]);

  const skipTour = useCallback(() => {
    resolvedConfig.steps.forEach(({ name }) => setStepSeen(name));

    endTour();
  }, [resolvedConfig.steps, setStepSeen, endTour]);

  useEffect(() => {
    getElements();
  }, [getElements, resolvedConfig.steps]);

  useEffect(() => {
    if (isMobile) {
      setTourStarted(false);

      return;
    }

    if (autoStart && !tourStarted && !areAllStepsSeen(stepsElements)) {
      startTour();
    }
  }, [
    isMobile,
    autoStart,
    tourStarted,
    stepsElements,
    areAllStepsSeen,
    startTour,
  ]);

  const nextStep = useCallback(() => {
    if (currentStep === stepsElements.length - 1) {
      return;
    }

    onBeforeNextStep?.(currentStep, startTour, endTour);
    setCurrentStep((prev) => prev + 1);
  }, [currentStep, stepsElements, onBeforeNextStep, startTour, endTour]);

  const prevStep = useCallback(() => {
    if (currentStep === 0) {
      return;
    }

    onBeforePrevStep?.(currentStep, startTour, endTour);
    setCurrentStep((prev) => prev - 1);
  }, [currentStep, onBeforePrevStep, startTour, endTour]);

  const value = useMemo(
    () =>
      ({
        startTour,
        endTour,
        skipTour,
        prevStep,
        nextStep,
        setStepSeen,
        getElements,
        tourName: resolvedConfig.name,
        currentStepName: resolvedConfig.steps[currentStep].name,
        tourStarted,
        isFirstStep: currentStep === findFirstNotEmptyStep(stepsElements),
        isLastStep: currentStep === stepsElements.length - 1,
        areAllStepsSeen: areAllStepsSeen(stepsElements),
      }) as TourContextType,
    [
      startTour,
      endTour,
      skipTour,
      prevStep,
      nextStep,
      setStepSeen,
      getElements,
      resolvedConfig.steps,
      resolvedConfig.name,
      currentStep,
      stepsElements,
      tourStarted,
      areAllStepsSeen,
    ],
  );

  return (
    <TourContext.Provider value={value}>
      {children}
      {tourStarted
        ? createPortal(
            <TourOverlay>
              <TourStep
                key={resolvedConfig.steps[currentStep].name}
                name={resolvedConfig.steps[currentStep].name}
                popoverPosition={
                  resolvedConfig.steps[currentStep].popoverPosition
                }
                content={resolvedConfig.steps[currentStep].content}
                types={resolvedConfig.steps[currentStep].elements.map(
                  ({ type }) => type,
                )}
                classNames={resolvedConfig.steps[currentStep].elements.map(
                  ({ className }) => className,
                )}
                elements={stepsElements[currentStep]}
                skipScroll={resolvedConfig.steps[currentStep].skipScroll}
              />
            </TourOverlay>,
            document.body,
          )
        : null}
    </TourContext.Provider>
  );
}
