import { ReactNode, isValidElement } from 'react';

export function isEmptyReactNode(children: ReactNode): boolean {
  if (children === null || children === undefined || children === '') {
    return true;
  }

  if (Array.isArray(children)) {
    return children.every((child) => isEmptyReactNode(child));
  }

  if (isValidElement(children)) {
    const { props } = children;

    if (props.children === null) {
      return true;
    }

    return false;
  }

  return false;
}
