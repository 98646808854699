import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';
import useGoogleDriveSync from 'hooks/useGoogleDriveSync';

import {
  GoogleDriveSelectFormFieldPropsType,
  GoogleDriveSelectType,
} from './GoogleDriveSelectFormField.types';
import { FETCH_GOOGLE_DRIVES } from './GoogleDriveSelectFormField.gql';
import {
  FetchGoogleDrivesQuery,
  FetchGoogleDrivesQueryVariables,
} from './__generated__/GoogleDriveSelectFormField.gql';

export default function GoogleCalendarSelectFormField({
  name = 'drive',
  ...props
}: GoogleDriveSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { myDrive } = useGoogleDriveSync();
  const { loading, data, fetchMore } = useQuery<
    FetchGoogleDrivesQuery,
    FetchGoogleDrivesQueryVariables
  >(FETCH_GOOGLE_DRIVES, {
    fetchPolicy: 'network-only',
  });
  const { drives, nextPageToken } = data?.googleDrives || {};
  const options = useMemo<GoogleDriveSelectType[]>(
    () => [myDrive, ...(drives || [])],
    [drives, myDrive],
  );

  const handleOnLoadOptions = useCallback(async () => {
    const {
      data: { googleDrives },
    } = await fetchMore({
      variables: { nextPageToken },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        googleDrives: {
          ...previousResult.googleDrives,
          drives: [
            ...previousResult.googleDrives.drives,
            ...(fetchMoreResult?.googleDrives.drives || []),
          ],
        },
      }),
    });

    return Boolean(googleDrives.nextPageToken);
  }, [fetchMore, nextPageToken]);

  const optionValueResolver = useCallback(
    (drive: GoogleDriveSelectType) => drive,
    [],
  );
  const optionLabelResolver = useCallback(
    ({ name }: GoogleDriveSelectType) => name,
    [],
  );
  const selectedOptionsResolver = useCallback(
    (selectedOptions: GoogleDriveSelectType[]) =>
      selectedOptions.map((option) => option?.name).join(', '),
    [],
  );

  return (
    <SelectFormField
      label={t('form.googleCalendarSelectFormField.label')}
      name={name}
      {...props}
      isLoading={loading}
      options={options}
      onLoadOptions={handleOnLoadOptions}
      clearable={false}
      optionValueResolver={optionValueResolver}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
    />
  );
}
