import React, { Ref } from 'react';
import classnames from 'classnames';

import { ButtonTypes } from './Button.types';
import styles from './Button.module.scss';

const Button = React.forwardRef(
  // @ts-ignore
  <Link extends string | undefined>(
    {
      onClick,
      loading = false,
      children,
      className,
      role = 'button',
      disabled,
      skin = 'secondary',
      style,
      small = false,
      link,
      // @ts-ignore
      type,
      // @ts-ignore
      target,
      ...rest
    }: ButtonTypes<Link>,
    ref: null | Link extends string
      ? Ref<HTMLAnchorElement | null>
      : Ref<HTMLButtonElement | null>,
  ) => {
    const commonProps = {
      disabled: disabled || loading,
      className: classnames(
        styles.button,
        {
          [styles.loading]: loading,
          [styles.disabled]: disabled,
          [styles.small]: small,
          [styles.primary]: !loading && skin === 'primary',
          [styles.success]: !loading && skin === 'success',
          [styles.secondary]: !loading && skin === 'secondary',
          [styles.brand]: !loading && skin === 'brand',
          [styles.info]: !loading && skin === 'info',
          [styles.shell]: skin === 'shell',
          [styles.link]: skin === 'link',
        },
        className,
      ),
      role,
      style,
      ...rest,
    };

    if (link !== undefined) {
      return (
        <a
          {...commonProps}
          ref={ref as Ref<HTMLAnchorElement>}
          href={!disabled ? link : undefined}
          target={target}
          onClick={
            !disabled
              ? (onClick as (
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ) => void | undefined)
              : undefined
          }
        >
          {children}
        </a>
      );
    }

    return (
      <button
        {...commonProps}
        ref={ref as Ref<HTMLButtonElement>}
        type={type || 'button'}
        onClick={
          onClick as (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => void | undefined
        }
      >
        {children}
      </button>
    );
  },
);

export default Button;
