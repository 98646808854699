import { DEFAULT_COUNTRY_CODE } from 'constants/phone';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';

export function formatLabel(name?: string | null) {
  return name ? capitalize(lowerCase(name)) : '';
}

export const isUSCountry = (country?: string): boolean => {
  return country === DEFAULT_COUNTRY_CODE;
};
