import { useCallback } from 'react';
import { useCoreIntl, defaultIntlValueResolvers } from '@appclose/core';

import { VALUE_RESOLVERS } from '../../constants';
import { IntlMessageIdType } from '../../types';
import { I18nPropsType } from '../../components/I18n';
import { TFuncType } from './useIntl.types';

export default function useIntl() {
  const { formatMessage } = useCoreIntl();

  const t: TFuncType = useCallback(
    (id: IntlMessageIdType, values?: I18nPropsType['values']) =>
      formatMessage(
        { id },
        {
          ...defaultIntlValueResolvers,
          ...values,
          ...VALUE_RESOLVERS,
        },
      ) as unknown as string,
    [formatMessage],
  );

  return {
    t,
  };
}
