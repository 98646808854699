import { useCallback } from 'react';
import { Field } from 'formik';

import { ChipsInputField } from '../ChipsInputField';
import { getFieldError } from '../../../../../utils/formik';

import {
  ChipsInputFormFieldType,
  ChipsInputFormFieldWrapperType,
} from './ChipsInputFormField.types';

const ChipsInputFormFiledWrapper = ({
  field: { value, ...field },
  form,
  onChange,
  ...props
}: ChipsInputFormFieldWrapperType) => {
  const handleOnChange = useCallback(
    (value: string[]) => {
      onChange?.(value);
      form.setFieldValue(field.name, value, true);
    },
    [onChange, form, field.name],
  );

  return (
    <ChipsInputField
      {...{ ...field, ...props }}
      showLabel={value.length ? 'alwaysMinified' : 'always'}
      items={value || []}
      onChange={handleOnChange}
      error={getFieldError(form, field.name)}
    />
  );
};

export default function ChipsInputFormField(props: ChipsInputFormFieldType) {
  return <Field {...props} component={ChipsInputFormFiledWrapper} />;
}
