// @ts-ignore
import { store } from 'react-notifications-component';
import { v1 as uuid } from 'uuid';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';

import {
  NotificationParamsType,
  NotificationOptionsType,
  NotificationType,
  NotificationMethodPropsType,
} from './Notification.types';

export default class Notification {
  protected options: Partial<NotificationParamsType> = {
    container: 'top-center',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
  };

  protected store: Map<string, NotificationType> = new Map();

  constructor(options: NotificationOptionsType = {}) {
    this.options = { ...this.options, ...options };
  }

  private addNotificationToStore(notification: NotificationType): void {
    this.store.set(notification.id, notification);
  }

  private isNotificationInStore({
    id,
    ...notification
  }: NotificationType): boolean {
    return [...this.store.values()].some(({ id, ...storedNotification }) =>
      isEqual(storedNotification, notification),
    );
  }

  private removeNotificationFromStore(id: string): void {
    this.store.delete(id);
  }

  private addNotification(options: NotificationParamsType) {
    const { id = uuid(), type, title, message, onRemoval = noop } = options;
    const notification = {
      id,
      type,
      title,
      message,
    };

    if (!this.isNotificationInStore(notification)) {
      this.addNotificationToStore(notification);

      store.addNotification({
        ...this.options,
        ...options,
        ...notification,
        onRemoval: (notificationId: string) => {
          onRemoval(notificationId);

          this.removeNotificationFromStore(notificationId);
        },
      });
    }
  }

  public default({ title, message }: NotificationMethodPropsType) {
    this.addNotification({
      ...this.options,
      title,
      message,
      type: 'default',
    });
  }

  public success({ title, message }: NotificationMethodPropsType) {
    this.addNotification({
      ...this.options,
      title,
      message,
      type: 'success',
    });
  }

  public info({ title, message }: NotificationMethodPropsType) {
    this.addNotification({
      ...this.options,
      title,
      message,
      type: 'info',
    });
  }

  public warning({ title, message }: NotificationMethodPropsType) {
    this.addNotification({
      ...this.options,
      title,
      message,
      type: 'warning',
    });
  }

  public danger({ title, message }: NotificationMethodPropsType) {
    this.addNotification({
      ...this.options,
      title,
      message,
      type: 'danger',
    });
  }

  public show(options: NotificationParamsType) {
    this.addNotification(options);
  }

  public clear() {
    this.store.forEach(({ id }) => store.removeNotification(id));
  }
}
