import { handleActions } from 'redux-actions';

import { closeModalAction, openModalAction } from './actions';

export default handleActions<any>(
  {
    [`${openModalAction}`]: (state, { payload }) => payload,
    [`${closeModalAction}`]: () => ({ type: null, data: null }),
  },
  { type: '', data: null },
);
