import { useCallback } from 'react';
import {
  FormGroup,
  InputFormField,
  DatePickerFormField,
  PhoneNumberFormField,
  dateManager,
  Fieldset,
  useFormContext,
} from '@appclose/core';

import { useIntl } from 'i18n';
import OwnershipTypeSelectFormField from 'components/fields/OwnershipTypeSelectFormField';
import FormGrid from 'components/common/FormGrid/FormGrid';
import { SERVER_DATE_FORMAT } from 'constants/date';
import BusinessActivityTypeSelectFormField from 'components/fields/BusinessActivityTypeSelectFormField';

import { Checkbox } from '@appclose/ui';
import { KybInput } from '__generated__/globalTypes';
import { trackWithPageMeta } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';

export default function BusinessInfoFieldset({
  isDisplayField,
}: {
  isDisplayField: boolean;
}) {
  const { t } = useIntl();

  const { values, setValues } = useFormContext<KybInput>();

  const handleCheckboxChange = () => {
    setValues((values) => {
      // @ts-ignore
      const businessInfoUrl = !values.businessInfo.noWebsite
        ? undefined
        : values.businessInfo.url;

      return {
        ...values,
        businessInfo: {
          ...values.businessInfo,
          url: businessInfoUrl,
          // @ts-ignore
          noWebsite: !values.businessInfo.noWebsite,
        },
      };
    });
  };

  const handleUnmaskValue = useCallback(
    (maskedValue: string) => maskedValue.replace(/-/g, ''),
    [],
  );

  const handleTooltipOpen = (id: string) => {
    trackWithPageMeta(EventNames.HINT_VIEWED, { id });
  };

  return (
    <Fieldset title={t('form.merchantApplication.businessInfoFieldset.title')}>
      <FormGroup>
        <FormGrid align="end">
          <InputFormField
            name="businessInfo.businessName"
            label={t(
              'form.merchantApplication.businessInfoFieldset.businessName',
            )}
          />
          <InputFormField
            name="businessInfo.legalBusinessName"
            label={t(
              'form.merchantApplication.businessInfoFieldset.legalBusinessName',
            )}
            tooltip={t(
              'form.merchantApplication.businessInfoFieldset.legalBusinessName.tooltip',
            )}
            onTooltipOpen={() =>
              handleTooltipOpen(
                'form.merchantApplication.businessInfoFieldset.legalBusinessName.tooltip',
              )
            }
          />
          <OwnershipTypeSelectFormField />
          <BusinessActivityTypeSelectFormField />
          <DatePickerFormField
            name="businessInfo.businessStartDate"
            label={t(
              'form.merchantApplication.businessInfoFieldset.businessStartedSince',
            )}
            outputDateFormat={SERVER_DATE_FORMAT}
            maxDate={dateManager()
              .parse()
              .endOf('day')
              .subtract(1, 'day')
              .valueOf()}
          />
          <InputFormField
            name="businessInfo.taxId"
            label={t('form.merchantApplication.businessInfoFieldset.taxId')}
            placeholder="XXX-XX-XXXX"
            mask="999999999"
            unmaskValue={handleUnmaskValue}
            tooltip={t(
              'form.merchantApplication.businessInfoFieldset.taxId.tooltip',
            )}
            onTooltipOpen={() =>
              handleTooltipOpen(
                'form.merchantApplication.businessInfoFieldset.taxId.tooltip',
              )
            }
          />
          <InputFormField
            name="businessInfo.url"
            label={t('form.merchantApplication.businessInfoFieldset.website')}
            placeholder={t(
              'form.merchantApplication.businessInfoFieldset.website',
            )}
            // @ts-ignore
            disabled={values.businessInfo.noWebsite || false}
            tooltip={t(
              'form.merchantApplication.businessInfoFieldset.website.note',
            )}
            onTooltipOpen={() =>
              handleTooltipOpen(
                'form.merchantApplication.businessInfoFieldset.website.note',
              )
            }
          />
          <Checkbox
            // @ts-ignore
            checked={values.businessInfo.noWebsite || false}
            label={t('form.merchantApplication.businessInfoFieldset.noWebsite')}
            onChange={handleCheckboxChange}
          />
          {isDisplayField && <PhoneNumberFormField name="businessInfo.phone" />}
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
