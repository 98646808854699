export {
  SIGN_IN_ROUTE,
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
  EXPERT_SUPPORT_ROUTE,
  SUPPORT_PAGE_URL,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from '@appclose/core';

// DO NOT FORGET TO ADD ROUTES META AFTER ADDING NEW ROUTES //

/* OAuth redirect */
export const EXCHANGE_TOKENS_ROUTE = '/exchange-tokens';

/* Public Share requests */
export const SHARE_CHAT_REQUEST_ROUTE = '/review-share-chat-request';
export const EXPORT_CHAT_REQUEST_ROUTE = '/review-export-chat-request';

/* Forbidden */
export const FORBIDDEN_ROUTE = '/forbidden';

/* Account */
export const SETUP_PASSWORD_ROUTE = '/invite';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

/* Onboarding */
export const ONBOARDING_ROUTE = '/onboarding';
export const ONBOARDING_ON_REVIEW_ROUTE = ONBOARDING_ROUTE + '/on-review';
export const ONBOARDING_PROFILE_ROUTE = ONBOARDING_ROUTE + '/profile';
export const ONBOARDING_MERCHANT_APPLICATION_ROUTE =
  ONBOARDING_ROUTE + '/merchant-application';
export const ONBOARDING_COMPLETE_ROUTE = ONBOARDING_ROUTE + '/complete';
export const ONBOARDING_BLOCKED_ROUTE = ONBOARDING_ROUTE + '/update-needed';

/* KYB */
export const KYB_ROUTE = '/kyb';

/* Common */
export const BILLING_ROUTE = '/billing';
export const CALENDAR_ROUTE = '/calendar';
export const CONTACTS_ROUTE = '/contacts';
export const CLIENTS_ROUTE = '/clients';
export const DASHBOARD_ROUTE = '/dashboard';
export const FILE_STORAGE_ROOT_ROUTE = '/file-storage';
export const FILE_STORAGE_NODE_ROUTE = FILE_STORAGE_ROOT_ROUTE + '/:nodeId';
export const FILES_ROUTE = '/files';
export const FLAT_FEES_ROUTE = '/flat-fees';
export const INVOICES_ROUTE = '/invoices';
export const OPERATING_ACCOUNT_COMPLETED = '/operating-account-completed';
export const OVERVIEW_ROUTE = '/overview';
export const PROFILE_ROUTE = '/profile';
export const REFUNDS_ROUTE = '/refunds';
export const REPORTS_ROUTE = '/reports';
export const SETTINGS_ROUTE = '/settings';
export const SIGNATURE_ROUTE = '/signature';
export const TRANSACTIONS_ROUTE = '/transactions';
export const ADMIN_ROUTE = '/admin';

/* Entities */
export const ENTITIES_ROUTE = '/entities/:entityName/:entityId';

/* Dashboard */
export const DASHBOARD_OUTSTANDING_INVOICES =
  DASHBOARD_ROUTE + '/outstanding-invoices';
export const DASHBOARD_PAID_INVOICES = DASHBOARD_ROUTE + '/paid-invoices';
export const DASHBOARD_CONTACTS_REVENUE = DASHBOARD_ROUTE + '/contacts-revenue';

/* Contact */
export const CONTACT_ROUTE = CONTACTS_ROUTE + '/:id';
export const CONTACT_OVERVIEW_ROUTE = CONTACT_ROUTE + OVERVIEW_ROUTE;
export const CONTACT_INVOICES_ROUTE = CONTACT_ROUTE + INVOICES_ROUTE;
export const CONTACT_TRANSACTIONS_ROUTE = CONTACT_ROUTE + TRANSACTIONS_ROUTE;
export const CONTACT_FLAT_FEES_ROUTE = CONTACT_ROUTE + FLAT_FEES_ROUTE;
export const CONTACT_FILES_ROOT_ROUTE = CONTACT_ROUTE + FILES_ROUTE;
export const CONTACT_FILES_NODE_ROUTE =
  CONTACT_ROUTE + FILES_ROUTE + '/:nodeId';

/* Clients */
export const CLIENT_ROUTE = `/client/:id`;
export const CLIENT_CALENDAR_ROUTE = `${CLIENT_ROUTE}/calendar`;
export const CLIENT_CHATS_ROUTE = `${CLIENT_ROUTE}/chats`;
export const CLIENT_CHAT_ROUTE = `${CLIENT_ROUTE}/chats/:chatId`;
export const CLIENT_CHAT_MESSAGE_ROUTE = `${CLIENT_ROUTE}/chats/:chatId/:messageId`;
export const CLIENT_INFO_ROUTE = `${CLIENT_ROUTE}/info`;
export const CLIENT_SHARED_CHATS_ROUTE = `${CLIENT_ROUTE}/shared-chats`;
export const CLIENT_SHARED_CHAT_ROUTE = `${CLIENT_ROUTE}/shared-chats/:chatId`;
export const CLIENT_SHARED_CHAT_MESSAGE_ROUTE = `${CLIENT_ROUTE}/shared-chats/:chatId/:messageId`;
export const CLIENTS_OUTGOING_REQUESTS_ROUTE = `${CLIENTS_ROUTE}/outgoing-requests`;
export const CLIENTS_PENDING_REQUESTS_ROUTE = `${CLIENTS_ROUTE}/pending-requests`;
export const CLIENTS_SHARED_CHATS_ROUTE = `${CLIENTS_ROUTE}/shared-chats`;
export const CLIENTS_SHARED_CHAT_ROUTE = `${CLIENTS_ROUTE}/shared-chats/:chatId`;
export const CLIENTS_SHARED_CHAT_MESSAGE_ROUTE = `${CLIENTS_ROUTE}/shared-chats/:chatId/:messageId`;

/* Lead */
export const LEAD_ROUTE = '/lead/:id';
export const LEAD_OVERVIEW_ROUTE = LEAD_ROUTE + OVERVIEW_ROUTE;
export const LEAD_FILES_ROOT_ROUTE = LEAD_ROUTE + FILES_ROUTE;
export const LEAD_FILES_NODE_ROUTE = LEAD_ROUTE + FILES_ROUTE + '/:nodeId';

/* Billing */
export const BILLING_INVOICES_ROUTE = BILLING_ROUTE + INVOICES_ROUTE;
export const BILLING_TRANSACTIONS_ROUTE = BILLING_ROUTE + TRANSACTIONS_ROUTE;

/* Public */
export const SIGN_ROUTE = '/sign';

/* Reports */
export const REPORTS_TRANSACTIONS_ROUTE = REPORTS_ROUTE + TRANSACTIONS_ROUTE;
export const REPORTS_INVOICES_ROUTE = REPORTS_ROUTE + INVOICES_ROUTE;
export const REPORTS_CONTACTS_ROUTE = REPORTS_ROUTE + CONTACTS_ROUTE;

/* Settings */
export const SETTINGS_FIRM_PROFILE_ROUTE = SETTINGS_ROUTE + '/firm-profile';
export const SETTINGS_TEAM_MEMBERS_ROUTE = SETTINGS_ROUTE + '/team-members';
export const SETTINGS_ACCOUNTS_ROUTE = SETTINGS_ROUTE + '/accounts';
export const SETTINGS_INTEGRATIONS_ROUTE = SETTINGS_ROUTE + '/integrations';
export const SETTINGS_INTEGRATIONS_QBO_SYNC_HISTORY_ROUTE =
  SETTINGS_INTEGRATIONS_ROUTE + '/qbo-sync-history';
export const SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE =
  SETTINGS_ROUTE + '/custom-data-entries';
export const SETTINGS_CUSTOM_DATA_ENTRIES_EXPENSE_CATEGORIES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/expense-categories';
export const SETTINGS_CUSTOM_DATA_ENTRIES_TIME_ENTRY_ACTIVITIES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/time-entry-activities';
export const SETTINGS_CUSTOM_DATA_ENTRIES_THIRD_PARTY_PAYEES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/third-party-payees';

/* eSignature */
export const SIGNATURE_DOCUMENTS_ROUTE = SIGNATURE_ROUTE + '/documents';
export const SIGNATURE_TEMPLATES_ROUTE = SIGNATURE_ROUTE + '/templates';

/* Mobile */
export const MOBILE_ROUTE = '/mobile';
export const MOBILE_SUCCESS_ROUTE = MOBILE_ROUTE + '/success';
export const MOBILE_MERCHANT_OPERATING_ROUTE =
  MOBILE_ROUTE + '/merchant-application/operating';
export const MOBILE_MERCHANT_TRUST_ROUTE =
  MOBILE_ROUTE + '/merchant-application/trust';

/* Profile */
export const PROFILE_SETTINGS_ROUTE = PROFILE_ROUTE + '/settings';
export const PROFILE_SECURITY_ROUTE = PROFILE_ROUTE + '/security';
export const PROFILE_CALENDAR_SYNC_ROUTE = PROFILE_ROUTE + '/calendar';

/* CHATS */

export const CHATS_ROUTE = '/chats';
export const CHAT_ROUTE = '/chats/:chatId';
export const CHAT_MESSAGE_ROUTE = '/chats/:chatId/:messageId';

/* BACKOFFICE */

export const ADMIN_WAITLIST_ROUTE = ADMIN_ROUTE + '/waitlist';
export const ADMIN_DASHBOARD_ROUTE = ADMIN_ROUTE + '/dashboard';
export const ADMIN_SETTINGS_ROUTE = ADMIN_ROUTE + '/settings';
export const ADMIN_SETTINGS_EMAIL_BLACKLIST_ROUTE =
  ADMIN_SETTINGS_ROUTE + '/email-blacklist';
export const ADMIN_USERS_ROUTE = ADMIN_ROUTE + '/users';
export const ADMIN_USERS_WAITLIST_ROUTE = ADMIN_USERS_ROUTE + '/waitlist';
