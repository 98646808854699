import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import { Flex, Text } from '@appclose/ui';
import { SyncIcon } from '@appclose/icons';
import { permissionProvider } from '@appclose/core';

import notification from 'controllers/notification';
import { useIntl } from 'i18n';
import useQboSyncHistoryActions from 'hooks/useQboSyncHistoryActions';
import useEntityQboSyncStatusTypes from 'hooks/useEntityQboSyncStatusTypes';
import { PermissionActions } from 'constants/permissions';

import { EntityQboSyncStatusPropsTypes } from './EntityQboSyncStatus.types';
import useQboEntitySyncStatus from './hooks/useQboEntitySyncStatus';
import styles from './EntityQboSyncStatus.module.scss';
import {
  FetchQboSyncContactStatusQuery,
  FetchQboSyncContactStatusQueryVariables,
  QboResyncContactMutation,
  QboResyncContactMutationVariables,
} from './__generated__/EntityQboSyncStatus.gql';

export default function EntityQboSyncStatus({
  entityId,
  entityType,
  qboSyncState,
}: EntityQboSyncStatusPropsTypes) {
  const { t } = useIntl();
  const { qboSyncStatusMap, getEntityType, qboSyncStatusResourceMap } =
    useQboEntitySyncStatus();
  const { getEntityQboSyncStatus } = useEntityQboSyncStatusTypes();
  const { getQboSyncHistoryAction } = useQboSyncHistoryActions();

  const isFailed = qboSyncState === 'FAILED';

  const { loading: qboSyncStatusLoading, data: qboSyncStatusData } = useQuery<
    FetchQboSyncContactStatusQuery,
    FetchQboSyncContactStatusQueryVariables
  >(qboSyncStatusMap[entityType].fetch, {
    variables: { id: entityId },
    skip: !isFailed,
  });

  const [qboResyncEntity] = useMutation<
    QboResyncContactMutation,
    QboResyncContactMutationVariables
  >(qboSyncStatusMap[entityType].resync);

  const qboSyncAction = qboSyncStatusData?.syncStatus?.action;

  const handleOnResyncEntity = useCallback(async () => {
    try {
      await qboResyncEntity({
        variables: { id: entityId },
      });

      notification().successAction(
        t('common.qboResync.processStarted', {
          entity: getEntityType(entityType),
        }),
      );
    } catch {}
  }, [entityId, entityType, getEntityType, qboResyncEntity, t]);

  if (qboSyncStatusLoading) {
    return null;
  }

  return (
    <>
      <Flex gap={isFailed || !qboSyncState ? [10] : [0]}>
        <div
          className={classnames(styles.container, {
            [styles.success]: qboSyncState === 'SUCCESS',
            [styles.pending]: qboSyncState === 'PENDING' || !qboSyncState,
            [styles.failed]: qboSyncState === 'FAILED',
          })}
        >
          {getEntityQboSyncStatus(qboSyncState)}
        </div>
        {permissionProvider().hasPermission(
          qboSyncStatusResourceMap[entityType],
          PermissionActions.UPDATE,
        ) &&
          isFailed && (
            <SyncIcon
              className={styles.resyncIcon}
              onClick={handleOnResyncEntity}
            />
          )}
      </Flex>
      {isFailed && qboSyncAction && (
        <Text offset={{ left: 8, top: 8 }}>
          {getQboSyncHistoryAction(qboSyncAction)}
        </Text>
      )}
    </>
  );
}
