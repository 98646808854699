import { PeriodTypes } from '__generated__/globalTypes';

import { useIntl } from 'i18n';

import { PeriodSelectFormFieldOptionsType } from './PeriodSelectFormField.types';
import { useMemo } from 'react';

export function useOptions() {
  const { t } = useIntl();
  const periodTypes: Record<PeriodTypes, string> = useMemo(
    () => ({
      [PeriodTypes.DAY]: t('enum.periodTypes.day'),
      [PeriodTypes.MONTH]: t('enum.periodTypes.month'),
      [PeriodTypes.WEEK]: t('enum.periodTypes.week'),
    }),
    [t],
  );
  const options: PeriodSelectFormFieldOptionsType = useMemo(
    () =>
      Object.entries(periodTypes).map(([value, label]) => ({
        value: value as PeriodTypes,
        label,
      })),
    [periodTypes],
  );

  return {
    options,
  };
}
