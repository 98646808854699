import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { FileStorageSyncTypes } from '__generated__/globalTypes';

import {
  GoogleDriveSyncOptionsFormValuesType,
  SyncFolderType,
} from './GoogleDriveSyncOptionsModal.types';
import { GoogleDriveSelectType } from './components/GoogleDriveSelectFormField';

export const GoogleDriveSyncOptionsModalSchema =
  Yup.object().shape<GoogleDriveSyncOptionsFormValuesType>({
    drive: Yup.mixed<GoogleDriveSelectType>().required(
      getI18nErrorMessage(
        'modal.googleDriveSyncOptions.schema.driveId.required',
      ),
    ),
    folderId: Yup.string()
      .when('syncFolderType', {
        is: (syncFolderType) => syncFolderType === SyncFolderType.EXISTING,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.googleDriveSyncOptions.schema.folderId.required',
          ),
        ),
      })
      .nullable(),
    newFolderName: Yup.string()
      .when('syncFolderType', {
        is: (syncFolderType) => syncFolderType === SyncFolderType.NEW,
        then: Yup.string().required(
          getI18nErrorMessage(
            'modal.googleDriveSyncOptions.schema.folderName.required',
          ),
        ),
      })
      .nullable(),
    syncFolderType: Yup.mixed<SyncFolderType>().required(),
    syncType: Yup.mixed<FileStorageSyncTypes>().required(),
  });
