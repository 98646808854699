import { Location } from 'history';
import { useEffect, useRef } from 'react';

export const usePrevLocation = (location: Location<unknown>) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};
