import { getNameValidationSchema } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';

export const CONTACT_FIRST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'First',
  30,
  2,
).required(getI18nErrorMessage('error.contact.firstName.required'));

export const CONTACT_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'Name',
  30,
  2,
).required(getI18nErrorMessage('error.contact.name.required'));

export const CONTACT_LAST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'Last',
  30,
  2,
).required(getI18nErrorMessage('error.contact.lastName.required'));

export const CONTACT_ENTITY_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'Entity',
  50,
  1,
).required(getI18nErrorMessage('error.contact.entityName.required'));
