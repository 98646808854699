import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { AT_LEAST_ONE_FILE_SCHEMA } from 'schemas/validations/files';

import { SIGNERS_SCHEMA } from '../../../SignersFormField';
import { NON_SIGNATORY_RECIPIENTS_SCHEMA } from '../../../NonSignatoryRecipientsFormField';
import { SignatureDocumentFromFileFormValuesType } from './SignatureDocumentFromFileForm.types';

export const SignatureDocumentFromFileFormSchema =
  Yup.object().shape<SignatureDocumentFromFileFormValuesType>({
    title: Yup.string().required(
      getI18nErrorMessage(
        'modal.signatureDocument.form.fromFile.error.title.required',
      ),
    ),
    files: AT_LEAST_ONE_FILE_SCHEMA,
    completeInOrder: Yup.boolean().required(),
    signers: SIGNERS_SCHEMA,
    text: Yup.string(),
    nonSignatoryRecipients: NON_SIGNATORY_RECIPIENTS_SCHEMA,
  });
