import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { history, Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { CONTACT_OVERVIEW_ROUTE } from 'constants/routes';
import notification from 'controllers/notification';
import { INITIAL_CONTACT, INITIAL_ENTITY } from 'constants/contacts';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';
import { useIntl } from 'i18n';

import {
  CreateContactMutation,
  CreateContactMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchProfileQuery,
  FetchProfileQueryVariables,
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from './__generated__/ContactModal.gql';
import ContactForm, {
  ContactAndEntityFormActionsType,
  ContactAndEntityFormValuesType,
} from './components/ContactForm';
import {
  CREATE_CONTACT,
  FETCH_CONTACT,
  FETCH_PROFILE,
  UPDATE_CONTACT,
} from './ContactModal.gql';
import {
  ContactModalModalTabs,
  ContactModalPropsType,
} from './ContactModal.types';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function ContactModal({
  id,
  onClose,
  defaultTab,
  openNew = true,
}: ContactModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.CONTACT);
  const isEdit = !!id;
  const { trackModalClose } = useModalTracking(
    PermissionResources.CONTACT,
    isEdit ? ActionType.EDIT : ActionType.CREATE,
  );

  const { loading: profileLoading, data: profileData } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);
  const [createContact] = useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CREATE_CONTACT);
  const [updateContact] = useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UPDATE_CONTACT);
  const { loading: contactLoading, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: id as string },
    fetchPolicy: 'network-only',
    skip: !isEdit,
  });

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    async (
      { contact, entity, selectedTab, files }: ContactAndEntityFormValuesType,
      { setFieldValue }: ContactAndEntityFormActionsType,
    ) => {
      const variables =
        selectedTab === ContactModalModalTabs.ENTITY
          ? {
              input: {
                entity: {
                  ...(isEdit ? { id } : {}),
                  ...omit(entity, ['uploadFiles', 'originatingAttorney']),
                  originatingAttorney: entity.originatingAttorney.id,
                },
              },
            }
          : {
              input: {
                contact: {
                  ...(isEdit ? { id } : {}),
                  ...omit(contact, ['uploadFiles', 'originatingAttorney']),
                  originatingAttorney: contact.originatingAttorney.id,
                },
              },
            };

      let contactId = id;

      if (isEdit) {
        await updateContact({
          variables: variables as UpdateContactMutationVariables,
        });

        notification().entityUpdated(
          t('modal.contact.notification.update.success'),
        );
      } else {
        const result = await createContact({
          variables: variables as CreateContactMutationVariables,
        });
        contactId = result.data?.createContact.id;

        notification().entityCreated(
          t('modal.contact.notification.create.success'),
        );
      }

      if (contactId && files) {
        await onUpdateFiles({ entityId: contactId, ...files });
      }

      onClose();
      trackModalClose();

      if (openNew && !isEdit && contactId) {
        history.push(generatePath(CONTACT_OVERVIEW_ROUTE, { id: contactId }));
      }
    },
    [
      t,
      onClose,
      trackModalClose,
      isEdit,
      id,
      openNew,
      updateContact,
      createContact,
      onUpdateFiles,
    ],
  );

  let initialValues = {
    selectedTab: defaultTab || ContactModalModalTabs.INDIVIDUAL,
    entity: {
      ...INITIAL_ENTITY,
      originatingAttorney: profileData?.getProfile,
    },
    contact: {
      ...INITIAL_CONTACT,
      originatingAttorney: profileData?.getProfile,
    },
  } as ContactAndEntityFormValuesType;

  if (isEdit && !contactLoading && contactData?.getContact) {
    const {
      firstName,
      lastName,
      middleName,
      gender,
      email,
      website,
      billingContacts,
      id,
      addresses,
      phoneNumbers,
      isEntity,
      name,
      ...rest
    } = contactData.getContact;

    if (!isEntity) {
      initialValues = {
        id,
        selectedTab: ContactModalModalTabs.INDIVIDUAL,
        contact: {
          ...initialValues.contact,
          ...rest,
          firstName,
          lastName,
          middleName,
          gender,
          email,
          addresses,
          phoneNumbers,
        },
      } as ContactAndEntityFormValuesType;
    } else {
      initialValues = {
        id,
        selectedTab: ContactModalModalTabs.ENTITY,
        entity: {
          ...initialValues.entity,
          ...rest,
          name,
          website,
          billingContacts,
          addresses,
          phoneNumbers,
        },
      } as ContactAndEntityFormValuesType;
    }
  }

  const title = !isEdit
    ? t('modal.contact.title')
    : !!contactData?.getContact && contactData.getContact.name;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {contactLoading || profileLoading ? (
          <Loader />
        ) : (
          <ContactForm
            initialValues={initialValues}
            isEdit={isEdit}
            defaultTab={defaultTab}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
