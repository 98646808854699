import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  Amount,
  FormGroup,
  Loader,
  StaticField,
  Fieldset,
} from '@appclose/core';
import { DownloadIcon, EmailIcon } from '@appclose/icons';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import InvoiceInfo from 'components/common/InvoiceInfo';
import { PermissionGuard } from 'components/common/PermissionGuard';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { openModal } from 'controllers/modal';
import {
  INVOICE_DETAILS_MODAL,
  SEND_CREDIT_MEMO_MODAL,
} from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { TransactionTypes } from '__generated__/globalTypes';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import usePaymentActions from 'hooks/usePaymentActions';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import useBankAccountClasses from 'hooks/useBankAccountClasses';
import { I18n, useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import { CreditMemoDetailsModalPropsType } from './CreditMemoDetailsModal.types';
import { FETCH_CREDIT_MEMO } from './CreditMemoDetailsModal.gql';
import {
  FetchCreditMemoQuery,
  FetchCreditMemoQueryVariables,
} from './__generated__/CreditMemoDetailsModal.gql';
import styles from './CreditMemoDetailsModal.module.scss';
import PaymentStatus from 'components/common/PaymentStatus';
import { ActionType, useModalTracking } from 'hooks/useModalTracking';

export default function CreditMemoDetailsModal({
  id,
  onClose,
}: CreditMemoDetailsModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.CONTACT,
    ActionType.VIEW,
  );
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();
  const { getBankAccountClassLabel } = useBankAccountClasses();
  const { downloadCreditMemoPdf, downloadCreditMemoPdfLoading } =
    usePaymentActions({
      id,
      type: TransactionTypes.CREDIT_MEMO,
    });
  const { loading, data } = useQuery<
    FetchCreditMemoQuery,
    FetchCreditMemoQueryVariables
  >(FETCH_CREDIT_MEMO, { variables: { id } });
  const creditMemo = data?.creditMemo;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });

  const onSendEmail = useCallback(() => {
    openModal(SEND_CREDIT_MEMO_MODAL, {
      id,
    });
  }, [id]);

  const memoNumber = creditMemo?.memoNumber || '';

  return (
    <ModalPage
      onClose={() => {
        onClose();
        trackModalClose();
      }}
    >
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.creditMemo.details.title" values={{ memoNumber }} />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          creditMemo && (
            <>
              <ActionsPanel>
                <PermissionGuard
                  resource={PermissionResources.CREDIT_MEMO}
                  action={PermissionActions.SEND}
                >
                  <ActionsPanelButton
                    skin="yellow"
                    icon={<EmailIcon />}
                    title={t('modal.creditMemo.details.actions.sendNotice')}
                    onClick={onSendEmail}
                  />
                </PermissionGuard>
                <PermissionGuard
                  resource={PermissionResources.CREDIT_MEMO}
                  action={PermissionActions.READ}
                >
                  <ActionsPanelButton
                    skin="secondary"
                    icon={<DownloadIcon />}
                    title={t('modal.creditMemo.details.actions.downloadPdf')}
                    onClick={downloadCreditMemoPdf}
                    loading={downloadCreditMemoPdfLoading}
                  />
                </PermissionGuard>
              </ActionsPanel>
              <Fieldset
                title={t('modal.creditMemo.details.fieldset.details.title')}
              >
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.details.contact',
                      )}
                    >
                      {creditMemo.contact.name}
                    </StaticField>
                    {memoNumber && (
                      <StaticField
                        title={t(
                          'modal.creditMemo.details.fieldset.details.referenceId',
                        )}
                      >
                        {memoNumber}
                      </StaticField>
                    )}
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.details.creditAmount',
                      )}
                    >
                      <Amount value={creditMemo.amount} />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.details.destinationAccount',
                      )}
                    >
                      {getBankAccountClassLabel(creditMemo.bankAccount.class)}
                    </StaticField>
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.details.paymentMethod',
                      )}
                    >
                      {getPaymentMethodsTypeLabel(creditMemo.paymentMethod)}
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.details.date',
                      )}
                    >
                      <Date value={creditMemo.createdAt} />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                {creditMemo.status && (
                  <FormGroup>
                    <FormGrid>
                      <StaticField
                        title={t(
                          'modal.creditMemo.details.fieldset.details.paymentStatus',
                        )}
                      >
                        <PaymentStatus status={creditMemo.status} />
                      </StaticField>
                    </FormGrid>
                  </FormGroup>
                )}
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t(
                        'modal.creditMemo.details.fieldset.qboSyncStatus',
                      )}
                    >
                      <EntityQboSyncStatus
                        entityId={creditMemo.id}
                        qboSyncState={creditMemo?.qboSyncState || undefined}
                        entityType="creditMemo"
                      />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
              </Fieldset>
              {creditMemo.memoInvoice && (
                <div className={styles.invoice}>
                  <p>
                    <I18n id="modal.creditMemo.details.associatedInvoices" />
                  </p>
                  <InvoiceInfo
                    invoice={creditMemo.memoInvoice}
                    onViewButtonClick={() => {
                      openModal(INVOICE_DETAILS_MODAL, {
                        id: creditMemo.memoInvoice?.id,
                      });
                    }}
                  />
                </div>
              )}
              <AdditionalInfoDetailsFieldset
                notes={creditMemo.memoText}
                files={files?.items}
              />
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
