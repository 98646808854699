import {
  fork,
  takeLeading,
  takeLatest,
  take,
  put,
  call,
} from 'redux-saga/effects';
import noop from 'lodash/noop';

import { ConfirmActionType } from '../../../types/confirm.types';

import {
  cancelConfirmAction,
  closeConfirmAction,
  confirmConfirmAction,
  openConfirmAction,
  errorConfirmAction,
} from './actions';
import { Action } from 'redux-actions';

function* handleConfirmSaga({
  payload: {
    onConfirm = noop,
    onCancel = noop,
    onClose = noop,
    onError = noop,
  },
}: {
  payload: ConfirmActionType;
}) {
  const resultAction: Action<any> = yield take([
    confirmConfirmAction,
    cancelConfirmAction,
  ]);

  try {
    if (resultAction.type === `${confirmConfirmAction}`) {
      yield call(onConfirm, resultAction.payload);
    }

    if (resultAction.type === `${cancelConfirmAction}`) {
      yield call(onCancel);
    }

    yield call(onClose);
    yield put(closeConfirmAction());
  } catch (e) {
    yield put(
      errorConfirmAction({
        onConfirm,
        onCancel,
        onClose,
        onError,
        error: onError(e),
      }),
    );
  }
}

function* watch() {
  yield takeLeading(openConfirmAction, handleConfirmSaga);
  yield takeLatest(errorConfirmAction, handleConfirmSaga);
}

export default function* confirmSagas() {
  yield fork(watch);
}
