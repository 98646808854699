import { useIntl } from 'i18n';
import { PaymentMethodsTypes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function usePaymentMethodsTypes() {
  const { t } = useIntl();
  const paymentMethodsTypes: Record<PaymentMethodsTypes, string> = useMemo(
    () => ({
      [PaymentMethodsTypes.CASH]: t('enum.paymentMethodsTypes.cash'),
      [PaymentMethodsTypes.CHECK]: t('enum.paymentMethodsTypes.check'),
      [PaymentMethodsTypes.CC]: t('enum.paymentMethodsTypes.cc'),
      [PaymentMethodsTypes.ACH]: t('enum.paymentMethodsTypes.ach'),
      [PaymentMethodsTypes.WIRE_TRANSFER]: t(
        'enum.paymentMethodsTypes.wireTransfer',
      ),
      [PaymentMethodsTypes.CREDIT_ISSUED]: t(
        'enum.paymentMethodsTypes.creditIssued',
      ),
      [PaymentMethodsTypes.OTHER]: t('enum.paymentMethodsTypes.other'),
      [PaymentMethodsTypes.TRANSFER_FROM_TRUST]: t(
        'enum.paymentMethodsTypes.transferFromTrust',
      ),
    }),
    [t],
  );

  const paymentMethodsTypesOptions: {
    label: string;
    value: PaymentMethodsTypes;
  }[] = useMemo(
    () =>
      Object.entries(paymentMethodsTypes)
        .filter(([value]) => {
          if (value === PaymentMethodsTypes.CREDIT_ISSUED) {
            return false;
          }

          if (value === PaymentMethodsTypes.TRANSFER_FROM_TRUST) {
            return false;
          }

          return true;
        })
        .map(([value, label]) => ({
          value: value as PaymentMethodsTypes,
          label,
        })),
    [paymentMethodsTypes],
  );

  const getPaymentMethodsTypeLabel = useCallback(
    (type?: PaymentMethodsTypes | null) => {
      if (!type) {
        return '';
      }

      return paymentMethodsTypes[type];
    },
    [paymentMethodsTypes],
  );

  return { getPaymentMethodsTypeLabel, paymentMethodsTypesOptions };
}
