import { ReactComponent as QboIcon } from 'assets/quickbooks.svg';
import { ReactComponent as AppCloseIcon } from 'assets/appclose.svg';

import { HintWithTracking } from 'components/common/HintWithTracking';
import { SystemLabelPropsType } from './SystemLabel.types';
import styles from './SystemLabel.module.scss';

export default function SystemLabel({
  text,
  tooltip,
  type,
}: SystemLabelPropsType) {
  return (
    <div className={styles.label}>
      {type === 'esq' ? (
        <AppCloseIcon width={20} height={20} />
      ) : (
        <QboIcon width={20} height={20} />
      )}
      <div className={styles.textContainer}>
        <span>{text}</span>
        {tooltip && (
          <HintWithTracking content={tooltip} className={styles.tooltip} />
        )}
      </div>
    </div>
  );
}
