import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop, { Crop as CropConfigType } from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { getFileDataUrl } from '@appclose/lib';
import { Button, ButtonGroup } from '@appclose/ui';
import { Modal, useIsTabletDevice } from '@appclose/core';

import { CropModalPropsType } from './CropModal.types';
import styles from './CropModal.module.scss';

export default function CropModal({
  file,
  onSave,
  onClose,
}: CropModalPropsType) {
  const isTabletDevice = useIsTabletDevice();
  const [src, setSrc] = useState<string>();
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [crop, setCrop] = useState<CropConfigType>({
    unit: '%',
    aspect: 1,
    width: 30,
  });

  useEffect(() => {
    getFileDataUrl(file).then(setSrc);
  }, [file]);

  const cropImg = useCallback(
    (image: HTMLImageElement, crop: CropConfigType): Promise<File> => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width || 0;
      canvas.height = crop.height || 0;

      const ctx = canvas.getContext('2d');

      ctx?.drawImage(
        image,
        (crop.x || 0) * scaleX,
        (crop.y || 0) * scaleY,
        (crop.width || 0) * scaleX,
        (crop.height || 0) * scaleY,
        0,
        0,
        crop.width || 0,
        crop.height || 0,
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name, {
                  lastModified: new Date().getTime(),
                  type: file.type,
                }),
              );
            } else {
              reject();
            }
          },
          undefined,
          1,
        );
      });
    },
    [file.name, file.type],
  );
  const handleOnImageLoaded = useCallback(
    (image: HTMLImageElement) => setImage(image),
    [],
  );
  const handleOnChange = useCallback((c: CropConfigType) => setCrop(c), []);
  const handleOnSave = useCallback(
    () =>
      image &&
      cropImg(image, crop).then((file) => {
        onSave(file);
        onClose();
      }),
    [image, crop, cropImg, onSave, onClose],
  );

  return src ? (
    <Modal onClose={onClose} theme={isTabletDevice ? 'page' : undefined}>
      <ReactCrop
        src={src}
        crop={crop}
        onImageLoaded={handleOnImageLoaded}
        onChange={handleOnChange}
      />
      <ButtonGroup className={styles.buttons}>
        <Button onClick={onClose}>Cancel</Button>
        <Button skin="brand" onClick={handleOnSave}>
          Save
        </Button>
      </ButtonGroup>
    </Modal>
  ) : null;
}
