import { ApolloError } from '@apollo/client';

import { CommonAppErrors } from '__generated__/globalTypes';

import { GQL_ERRORS_TO_SKIP_NOTIFICATION } from './error.constants';

type ErrorType = ApolloError | null | undefined;

export function hasGqlErrorCode(e: ErrorType, errorCode: CommonAppErrors) {
  const error = e?.graphQLErrors?.find(
    (err) => err?.extensions?.code === errorCode,
  );

  return Boolean(error);
}

export function hasEmailNotVerifiedError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.EMAIL_NOT_VERIFIED);
}

export function hasRefundUnsettledTransactionError(e: ErrorType) {
  return hasGqlErrorCode(
    e,
    CommonAppErrors.PAYMENTS_REFUND_UNSETTLED_TRANSACTION,
  );
}

export function hasPromoCodeExpiredError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.PROMO_CODE_EXPIRED);
}

export function hasPromoCodeNotExistsError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.PROMO_CODE_NOT_EXISTS);
}

export function hasNotAllowedPhoneNumberError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.NOT_ALLOWED_PHONE_NUMBER);
}

export function hasInvalidCustomDomainError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_INVALID);
}

export function hasFlatFeeOverflowError(e: ErrorType): boolean {
  return hasGqlErrorCode(
    e,
    CommonAppErrors.SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW,
  );
}

export function hasFileStorageFolderNotFoundError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.FILE_STORAGE_FOLDER_NOT_FOUND);
}

export function hasAuthTwoFactorTokenNotFoundError(e: ErrorType): boolean {
  return hasGqlErrorCode(e, CommonAppErrors.AUTH_TWO_FACTOR_TOKEN_NOT_FOUND);
}

export function shouldSkipErrorNotification(e: ErrorType) {
  const errorToSkip = e?.graphQLErrors?.find((err) => {
    return GQL_ERRORS_TO_SKIP_NOTIFICATION.includes(
      err?.extensions?.code as CommonAppErrors,
    );
  });

  return Boolean(errorToSkip);
}

export function isCardExpDateError(error: { message: string }) {
  return error?.message === 'Invalid Exp Date. Must use MM/YY format';
}
