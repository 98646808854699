import React, { ChangeEvent, useCallback } from 'react';
import { Field } from 'formik';
import { Radio as UIRadio } from '@appclose/ui';

import { RadioFormFieldType, RadioType } from './RadioFormField.types';

const Radio = ({ field, form, onChange, ...rest }: RadioFormFieldType) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      form.setFieldValue(field.name, event.target.value, true);
    },
    [form, field, onChange],
  );

  return (
    <UIRadio
      {...field}
      {...rest}
      onChange={handleOnChange}
      checked={field.value === rest.value}
    />
  );
};

const RadioFormField = (props: RadioType) => {
  return <Field {...props} component={Radio} />;
};

export default RadioFormField;
