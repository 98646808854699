import {
  useLayoutEffect,
  useMemo,
  useCallback,
  useState,
  MouseEvent,
} from 'react';
import throttle from 'lodash/throttle';
import { TourStepElementType } from '../../Tour.types';

import styles from './TourStepElement.module.scss';

type TourStepElementPropsType = {
  type: TourStepElementType;
  element: Element | null;
  padding?: number;
  className?: string;
};

const config = { attributes: false, childList: true, subtree: true };

const getStyleByType = (type: TourStepElementType) => {
  switch (type) {
    case TourStepElementType.Block:
      return styles.block;
    case TourStepElementType.WithBackground:
      return styles.withBackground;
    default:
      return '';
  }
};

export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;

type Rect = Pick<UseMeasureRect, 'width' | 'height' | 'top' | 'left'> & {
  diffLeft: number;
  diffTop: number;
};
const defaultState: Rect = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  diffLeft: 0,
  diffTop: 0,
};

const getElementPadding = (element: Element | null) => {
  if (!element) {
    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
  }

  const style = getComputedStyle(element);
  const top = parseInt(style.paddingTop, 10);
  const left = parseInt(style.paddingLeft, 10);
  const right = parseInt(style.paddingRight, 10);
  const bottom = parseInt(style.paddingBottom, 10);

  return {
    top,
    left,
    right,
    bottom,
  };
};

const getElementRect = (
  element: Element | null,
  type: TourStepElementType,
  padding: number,
): Rect => {
  if (!element) {
    return defaultState;
  }

  const { top, left, width, height } = element.getBoundingClientRect();

  if (type === TourStepElementType.WithBackground) {
    const paddings = getElementPadding(element);

    return {
      top,
      left,
      width: width - paddings.left - paddings.right,
      height: height - paddings.top - paddings.bottom,
      diffLeft: padding - paddings.left,
      diffTop: padding - paddings.top,
    };
  }

  return {
    top,
    left,
    width,
    height,
    diffLeft: 0,
    diffTop: 0,
  };
};

export const TourStepElement = ({
  element,
  type,
  padding = 12,
  className,
}: TourStepElementPropsType) => {
  const [rect, setRect] = useState<Rect>(
    getElementRect(element, type, padding),
  );

  const resizeObserver = useMemo(
    () =>
      new (window as any).ResizeObserver((entries: any) => {
        if (entries[0]) {
          setRect(getElementRect(entries[0].target, type, padding));
        }
      }),
    [type, padding],
  );

  const mutationObserver = useMemo(
    () =>
      new window.MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList' && element) {
            setRect(getElementRect(element, type, padding));
          }
        }
      }),
    [element, type, padding],
  );

  const onWindowResize = useCallback(() => {
    if (!element) {
      return;
    }

    setRect(getElementRect(element, type, padding));
  }, [element, type, padding]);

  const throttledResize = useMemo(
    () => throttle(onWindowResize, 16),
    [onWindowResize],
  );

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    resizeObserver.observe(element);
    mutationObserver.observe(document.body, config);

    window.addEventListener('resize', throttledResize);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener('resize', throttledResize);
    };
  }, [element, resizeObserver, mutationObserver, throttledResize]);

  if (!element) {
    return null;
  }

  const clone = element.cloneNode(true) as HTMLElement;
  clone.style.width = `${rect.width}px`;
  clone.style.height = `${rect.height}px`;
  clone.classList.add(styles.element);
  const typeStyle = getStyleByType(type);

  if (typeStyle) {
    clone.classList.add(typeStyle);
  }

  if (className) {
    clone.classList.add(className);
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const offset = type === TourStepElementType.WithBackground ? padding : 0;
  const top = rect.top - (rect.diffTop ? rect.diffTop : offset);
  const left = rect.left - (rect.diffLeft ? rect.diffLeft : offset);
  const width = rect.width + offset * 2;
  const height = rect.height + offset * 2;

  return (
    <div
      style={{
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
      onClick={handleClick}
      className={styles.container}
      dangerouslySetInnerHTML={{ __html: clone.outerHTML }}
    />
  );
};
