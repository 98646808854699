import { ChangeEvent, useCallback } from 'react';
import { Field } from 'formik';
import { Flex, Toggle, ToggleTypes } from '@appclose/ui';

import { ToggleFormFieldType } from './ToggleFormField.types';

const ToggleFormFieldWrapper = ({
  field,
  form,
  label,
  onChange,
  className,
  ...rest
}: ToggleFormFieldType) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      form.setFieldValue(field.name, event.target.checked, true);
    },
    [form, field, onChange],
  );

  return (
    <Toggle
      {...field}
      {...rest}
      className={className}
      label={
        <Flex alignItems="center" gap={[14]}>
          {label}
        </Flex>
      }
      active={field.value || false}
      onChange={handleOnChange}
    />
  );
};

const ToggleFormField = (props: Omit<ToggleTypes, 'active'>) => {
  return (
    <Field
      {...props}
      className={props.className}
      component={ToggleFormFieldWrapper}
    />
  );
};

export default ToggleFormField;
