import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';

import hellosign from 'controllers/hellosign';
import { SignerTypes } from 'constants/esign';

import { SignatureDocumentFromPropsType } from './SignatureDocumentFromFile.types';
import {
  CreateNewDocumentToSignFromFileMutation,
  CreateNewDocumentToSignFromFileMutationVariables,
} from './__generated__/SignatureDocumentFromFile.gql';
import { CREATE_NEW_DOCUMENT_TO_SIGN_FROM_FILE } from './SignatureDocumentFromFile.gql';
import SignatureDocumentFromFileForm, {
  SignatureDocumentFromFileFormValuesType,
} from './components/SignatureDocumentFromFileForm';
import useProfile from 'hooks/useProfile';

export default function SignatureDocumentFromFile({
  initialValues: propsInitialValues,
  onCreate,
  onCancel,
  onChange,
}: SignatureDocumentFromPropsType) {
  const [createNewDocumentToSignFromFile] = useMutation<
    CreateNewDocumentToSignFromFileMutation,
    CreateNewDocumentToSignFromFileMutationVariables
  >(CREATE_NEW_DOCUMENT_TO_SIGN_FROM_FILE);
  const initialValues = useMemo<SignatureDocumentFromFileFormValuesType>(
    () => ({
      title: '',
      signers: [{ type: SignerTypes.CLIENT }],
      completeInOrder: false,
      files: { newFiles: [], fileIdsToDelete: [] },
      nonSignatoryRecipients: [],
      ...propsInitialValues,
    }),
    [propsInitialValues],
  );
  const { profile } = useProfile();
  const profileId = profile?.id;

  const handleOnSubmit = useCallback(
    async ({
      files,
      signers,
      nonSignatoryRecipients,
      ...input
    }: SignatureDocumentFromFileFormValuesType) => {
      const { data } = await createNewDocumentToSignFromFile({
        variables: {
          input: {
            signers: signers.map(
              ({ externalSigner, contact, teamMember, client, type }) => ({
                externalSigner,
                contactId: contact?.id,
                teamMemberId:
                  type === SignerTypes.YOU ? profileId : teamMember?.id,
                clientId: client?.id,
              }),
            ),
            files: files.newFiles,
            nonSignatoryRecipients: nonSignatoryRecipients.map(
              (recipient) => recipient.email,
            ),
            ...input,
          },
        },
      });

      onCreate(data?.document.signature.id as string);
      hellosign().open(data?.document.signatureUrl.url as string);
    },
    [createNewDocumentToSignFromFile, onCreate, profileId],
  );

  return (
    <SignatureDocumentFromFileForm
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      onChange={onChange}
    />
  );
}
