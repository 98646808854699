import * as Yup from 'yup';
import {
  createPasswordConfirmValidationSchema,
  PASSWORD_VALIDATION_SCHEMA,
} from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';

import { ChangePasswordFormValuesType } from './ChangePasswordForm.types';

export const ChangePasswordFormSchema =
  Yup.object().shape<ChangePasswordFormValuesType>({
    oldPassword: Yup.string().required(
      getI18nErrorMessage(
        'modal.changePassword.form.oldPassword.error.required',
      ),
    ),
    newPassword: PASSWORD_VALIDATION_SCHEMA,
    newPasswordConfirm: createPasswordConfirmValidationSchema('newPassword'),
  });
