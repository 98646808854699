import { NO_BREAK_SPACE } from '@appclose/lib';
import { formatPhoneNumber } from '@appclose/core';

import {
  AddressInput,
  AddressTypes,
  BillingContactTypes,
  CombinedContactTypes,
  PhoneNumberInput,
} from '__generated__/globalTypes';

export function getContactName({
  name,
  firstName,
  lastName,
  email,
}: {
  name?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}): string {
  const contactName = name
    ? name
    : [firstName, lastName].filter(Boolean).join(NO_BREAK_SPACE);

  return contactName || email || '';
}

export function getCombinedContactType({
  __typename,
}: {
  __typename?: string;
}): CombinedContactTypes {
  if (__typename === 'ContactType') {
    return CombinedContactTypes.CONTACT;
  } else if (__typename === 'EntityType') {
    return CombinedContactTypes.ENTITY;
  }

  throw new Error('Unknown contact type');
}

export function getContactAddress({
  address1,
  address2,
  city,
  state,
  zipCode,
}: Omit<AddressInput, 'type'>): string {
  return [address1, address2, city, state, zipCode].filter(Boolean).join(', ');
}

export function getContactPrimaryAddress(addresses: AddressInput[]): string {
  const primaryAddress = addresses.find(
    ({ type }) => type === AddressTypes.PRIMARY,
  );

  return primaryAddress ? getContactAddress(primaryAddress) : '';
}

export function getContactPrimaryPhone(
  phoneNumbers: PhoneNumberInput[],
): string {
  const primaryPhone = phoneNumbers.find(({ type, isPrimary }) => isPrimary);

  return primaryPhone ? formatPhoneNumber(primaryPhone.number) : '';
}

export function getContactInitials(
  {
    firstName,
    lastName,
  }: {
    firstName?: string | null;
    lastName?: string | null;
  },
  withSpace = false,
): string {
  return [firstName, lastName]
    .filter(Boolean)
    .map((value) => (value as string)[0].toUpperCase())
    .join(withSpace ? ' ' : '');
}

export function getEntityPrimaryBillingContactEmail({
  billingContacts,
}: {
  billingContacts?:
    | {
        type: BillingContactTypes;
        email: string;
      }[]
    | null;
}): string {
  return (
    (
      (billingContacts || []).find(
        ({ type }) => type === BillingContactTypes.PRIMARY,
      ) || {}
    ).email || ''
  );
}

export function getEntityPrimaryContact<
  TEntity extends {
    contact: {
      isEntity: boolean;
      billingContacts?:
        | {
            type: BillingContactTypes;
          }[]
        | null;
    };
  },
>(
  entity: TEntity,
):
  | undefined
  | NonNullable<TEntity>['contact']
  | NonNullable<NonNullable<TEntity>['contact']['billingContacts']>[0] {
  if (!entity.contact.isEntity) {
    return entity.contact;
  }

  return entity.contact.billingContacts?.find(
    ({ type }) => type === BillingContactTypes.PRIMARY,
  ) as NonNullable<NonNullable<TEntity>['contact']['billingContacts']>[0];
}
