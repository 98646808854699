import { useMemo, useCallback } from 'react';
import { SelectFormField, SelectFormFieldPropsType } from '@appclose/core';

import { ClientStatus } from '__generated__/globalTypes';
import { useClientListQuery } from 'hooks/client/useClientListQuery';
import { Flex, Text3 } from '@appclose/ui';

import styles from './ClientSelectFormField.module.scss';
import { useCalendars } from 'hooks/calendar/useCalendars';

export type SelectClientType = {
  id: string;
  name: string;
  email?: string;
  status: ClientStatus;
};

export type ClientSelectFormFieldPropsType = Omit<
  SelectFormFieldPropsType<SelectClientType | undefined>,
  'name' | 'defaultOptions' | 'onAdd'
> & {
  name?: string;
  allowedIds?: string[];
  excludedIds?: string[];
};

export default function ClientSelectFormField({
  name = 'client',
  label = 'Client',
  placeholder = 'Select a client',
  showLabel = 'onFocus',
  showPlaceholder = 'always',
  allowedIds,
  excludedIds,
  onChange,
  ...rest
}: ClientSelectFormFieldPropsType) {
  const clientListQuery = useClientListQuery();
  const calendarsQuery = useCalendars();

  const { items: clients = [] } = clientListQuery.data?.clients || {};

  const optionValueResolver = useCallback(
    (client: SelectClientType) => client,
    [],
  );

  const optionLabelResolver = useCallback(
    (client: SelectClientType) => {
      const calendar = calendarsQuery.data?.calendars.find(
        (c) => c.clientId === client.id,
      );

      return (
        <Flex alignItems="center" gap={[12]}>
          <div
            style={{ backgroundColor: calendar?.color || 'transparent' }}
            className={styles.colorCircle}
          />
          <Text3 weight="semibold">{client.name}</Text3>
        </Flex>
      );
    },
    [calendarsQuery.data?.calendars],
  );

  const selectedOptionsResolver = useCallback(
    (selectedOptions: SelectClientType[]) =>
      selectedOptions.map((option) => option?.name).join(', '),
    [],
  );

  const options = useMemo(
    () =>
      clients.filter((client) => {
        return (
          (!allowedIds || allowedIds.includes(client.id)) &&
          (!excludedIds || !excludedIds.includes(client.id))
        );
      }),
    [clients, allowedIds, excludedIds],
  );

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      {...rest}
      clearable
      isLoading={clientListQuery.loading}
      options={options}
      selectedOptionsResolver={selectedOptionsResolver}
      optionLabelResolver={optionLabelResolver}
      optionValueResolver={optionValueResolver}
      onChange={onChange}
    />
  );
}
