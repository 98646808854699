import React from 'react';

import { InputFormField } from '../../../Input/components/InputFormField';
import { UpperFirstInputFormFieldPropsType } from './UpperFirstInputFormField.types';

export default function UpperFirstInputFormField(
  props: UpperFirstInputFormFieldPropsType,
) {
  return <InputFormField {...props} upperFirst />;
}
