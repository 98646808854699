import {
  TourConfig,
  TourStepElementType,
} from 'components/common/Tour/Tour.types';
import { I18n } from 'i18n';

export const esignDocumentModalTourConfig: TourConfig = {
  name: 'esign-documents',
  steps: [
    {
      name: 'esign-upload-files',
      elements: [
        {
          selector: '[data-tour="esign-upload-files"]',
          type: TourStepElementType.WithBackground,
        },
      ],
      popoverPosition: 'bottom',
      content: <I18n id="tour.esignature.create.upload" />,
    },
    {
      name: 'esign-recipient',
      elements: [
        {
          selector: '[data-tour="esign-recipient"]',
          type: TourStepElementType.WithBackground,
        },
      ],
      popoverPosition: 'bottom',
      content: <I18n id="tour.esignature.create.recipient" />,
    },
    {
      name: 'esign-continue',
      elements: [
        {
          selector: '[data-tour="esign-continue"]',
          type: TourStepElementType.Raw,
        },
      ],
      popoverPosition: 'top',
      content: <I18n id="tour.esignature.create.continue" />,
    },
  ],
};
