import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import { permissionProvider, SelectFormField } from '@appclose/core';

import { TeamMemberStatuses } from '__generated__/globalTypes';
import { PermissionActions, PermissionResources } from 'constants/permissions';

import TeamMember from './components/TeamMember';
import {
  FetchProfileQuery,
  FetchTeamMembersQuery,
  FetchTeamMembersQueryVariables,
} from './__generated__/TeamMemberSelectFormField.gql';
import {
  FETCH_PROFILE,
  FETCH_TEAM_MEMBERS,
} from './TeamMemberSelectFormField.gql';
import {
  SelectTeamMemberType,
  TeamMemberSelectFormFieldPropsType,
} from './TeamMemberSelectFormField.types';

export default function TeamMemberSelectFormField({
  name = 'user',
  exclude,
  accessLevels,
  hasLeads = false,
  ...rest
}: TeamMemberSelectFormFieldPropsType) {
  const [search, setSearch] = useState('');
  const hasPermissions = permissionProvider().hasPermission(
    PermissionResources.TEAM_MANAGEMENT,
    PermissionActions.LIST,
  );
  const { loading: loadingProfile, data: profileData } =
    useQuery<FetchProfileQuery>(FETCH_PROFILE);
  const {
    loading: loadingTeamMembers,
    data: usersData,
    fetchMore,
  } = useQuery<FetchTeamMembersQuery, FetchTeamMembersQueryVariables>(
    FETCH_TEAM_MEMBERS,
    {
      variables: {
        filter: {
          search,
          exclude,
          statuses: [TeamMemberStatuses.ACTIVE],
          accessLevels,
          hasLeads,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !hasPermissions,
    },
  );
  const users = hasPermissions
    ? usersData?.users.items || []
    : profileData?.profile
      ? [profileData?.profile]
      : [];

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: users.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        users: {
          ...previousResult.users,
          items: [
            ...previousResult.users.items,
            ...(fetchMoreResult?.users.items || []),
          ],
        },
      }),
    });

    return users.length !== data.users.total;
  }, [users.length, fetchMore]);

  const renderTeamMember = useCallback(
    ({ id, picture, firstName, lastName }: SelectTeamMemberType) => {
      const isYou = profileData?.profile.id === id;

      return (
        <TeamMember
          picture={picture}
          firstName={firstName}
          lastName={lastName}
          isYou={isYou}
        />
      );
    },
    [profileData],
  );
  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch],
  );
  const optionValueResolver = useCallback(
    (user: SelectTeamMemberType) => user,
    [],
  );
  const optionLabelResolver = useCallback(
    (user: SelectTeamMemberType) => renderTeamMember(user),
    [renderTeamMember],
  );
  const selectedOptionsResolver = useCallback(
    (users: SelectTeamMemberType[]) => {
      if (users.length) {
        return renderTeamMember(users[0]);
      }
    },
    [renderTeamMember],
  );

  return (
    <SelectFormField
      placeholder=""
      {...rest}
      name={name}
      options={users}
      withSearch
      disabled={!hasPermissions}
      isLoading={loadingProfile || loadingTeamMembers}
      onSearchChange={onSearchChange}
      optionValueResolver={optionValueResolver}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
      onLoadOptions={onLoadMore}
    />
  );
}
