import styles from './TourOverlay.module.scss';

type TourOverlayPropsType = {
  children: React.ReactNode;
};

export const TourOverlay: React.FC<TourOverlayPropsType> = ({ children }) => (
  <div className={styles.container} onClick={(e) => e.stopPropagation()}>
    {children}
  </div>
);
