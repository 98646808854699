import { EventNames } from 'constants/analytics';
import { PermissionResources } from 'constants/permissions';
import { trackWithPageMeta } from 'controllers/analytics';
import { useEffect } from 'react';

export enum ActionType {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
}

export enum ResolutionType {
  SAVE = 'save',
  CANCEL = 'cancel',
}

export const useModalTracking = (
  entityType: PermissionResources | string,
  actionType: ActionType,
) => {
  useEffect(() => {
    trackWithPageMeta(EventNames.SCREEN_OPENED, {
      entity_type: entityType,
      action_type: actionType,
    });
  }, [entityType, actionType]);

  const trackModalClose = (
    resolution?: ResolutionType,
    action?: ActionType,
  ) => {
    trackWithPageMeta(EventNames.SCREEN_CLOSED, {
      entity_type: entityType,
      action_type: action || actionType,
      resolution_type:
        actionType === ActionType.VIEW || !resolution
          ? ResolutionType.SAVE
          : resolution,
    });
  };

  return { trackModalClose };
};
