import { FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import classnames from 'classnames';

import { Input } from '../Input';
import { Flex } from '../Flex';
import { Text2 } from '../Typography';

import { ChipsInputPropsType } from './ChipsInput.types';

import styles from './ChipsInput.module.scss';
import { ClearIcon } from '@appclose/icons';
import uniq from 'lodash/uniq';

const ChipsInput = (props: ChipsInputPropsType) => {
  const { className, items = [], onChange, onBlur, ...rest } = props;
  const [text, setText] = useState('');

  const handleRemoveItem = (index: number) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const appendItem = (item: string) => {
    onChange(uniq([...items, text]));
    setText('');
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const trimmedText = text.trim();

    if ((e.key === ' ' || e.key === 'Enter') && trimmedText) {
      e.stopPropagation();
      e.preventDefault();
      appendItem(text);
    }

    if (e.key === 'Backspace' && trimmedText === '') {
      onChange(items.slice(0, -1));
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    const trimmedText = text.trim();

    if (text) {
      appendItem(trimmedText);
    }
  };

  return (
    <Flex
      className={styles.container}
      alignItems="center"
      gap={[12, 12]}
      wrap="wrap"
    >
      {items.map((item, index) => (
        <Flex key={item} className={styles.chipContainer} alignItems="center">
          <Text2 weight="semibold">{item}</Text2>
          <ClearIcon onClick={() => handleRemoveItem(index)} />
        </Flex>
      ))}
      <Input
        className={classnames(styles.input, className)}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        {...rest}
      />
    </Flex>
  );
};

export default ChipsInput;
