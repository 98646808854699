import { createContext } from 'react';

export type TourContextType = {
  startTour: () => void;
  endTour: () => void;
  skipTour: () => void;
  nextStep: () => void;
  prevStep: () => void;
  setStepSeen: (stepName: string) => void;
  getElements: () => (Element | null)[][];
  tourName: string;
  currentStepName: string;
  tourStarted: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  areAllStepsSeen: boolean;
};

export const TourContext = createContext<TourContextType>({
  startTour: () => {},
  endTour: () => {},
  skipTour: () => {},
  nextStep: () => {},
  prevStep: () => {},
  setStepSeen: () => {},
  getElements: () => [],
  tourName: '',
  currentStepName: '',
  tourStarted: false,
  isFirstStep: false,
  isLastStep: false,
  areAllStepsSeen: false,
});
