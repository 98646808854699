import * as Yup from 'yup';
import {
  URL_VALIDATION_SCHEMA,
  PHONE_NUMBER_VALIDATION_SCHEMA,
  PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  PROFILE_LAST_NAME_VALIDATION_SCHEMA,
  dateManager,
  EMAIL_VALIDATION_SCHEMA,
} from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import {
  PrincipalTitles,
  BankAccountTypes,
  BankAccountInput,
  KybBusinessInfoInput,
  KybBusinessActivityType,
  KybCompanyType,
  UsaStates,
  KybPersonInput,
  KybInput,
  PersonRelationship,
  KybBusinessAddressInput,
} from '__generated__/globalTypes';
import { E_CHECK_BANK_ROUTING_NUMBER_VALIDATION_SCHEMA } from 'schemas/validations/e-check';
import {
  FIRM_BUSINESS_START_DATE_VALIDATION_SCHEMA,
  FIRM_LEGAL_NAME_VALIDATION_SCHEMA,
  FIRM_TAX_ID_VALIDATION_SCHEMA,
} from 'schemas/validations/firm';

const corpTypes = [
  KybCompanyType.LLC,
  KybCompanyType.PRIVATE_PARTNERSHIP,
  KybCompanyType.PRIVATE_CORPORATION,
];

export const MerchantApplicationFormSchema = Yup.object().shape<KybInput>({
  businessInfo: Yup.object()
    .shape<KybBusinessInfoInput>({
      businessName: Yup.string()
        .required(
          getI18nErrorMessage(
            'form.merchantApplication.error.dbaName.required',
          ),
        )
        .min(2, ({ min }) =>
          getI18nErrorMessage(
            'form.merchantApplication.error.dbaName.minLength',
            {
              min,
            },
          ),
        )
        .max(50, ({ max }) =>
          getI18nErrorMessage(
            'form.merchantApplication.error.dbaName.maxLength',
            {
              max,
            },
          ),
        ),
      legalBusinessName: FIRM_LEGAL_NAME_VALIDATION_SCHEMA,
      businessStartDate: FIRM_BUSINESS_START_DATE_VALIDATION_SCHEMA,
      businessActivity: Yup.mixed<KybBusinessActivityType>(),
      structure: Yup.mixed<KybCompanyType>().required(
        getI18nErrorMessage(
          'form.merchantApplication.error.businessStructure.required',
        ),
      ),
      url: Yup.string().when(['noWebsite'], (noWebsite: boolean) => {
        if (noWebsite) {
          return Yup.string();
        }

        return URL_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.businessWebsite.required',
          ),
        );
      }),
      taxId: FIRM_TAX_ID_VALIDATION_SCHEMA,
      phone: Yup.string().when(['structure'], (structure: KybCompanyType) => {
        if (corpTypes.includes(structure)) {
          return PHONE_NUMBER_VALIDATION_SCHEMA.required(
            getI18nErrorMessage('core.error.phone.required'),
          );
        }

        return Yup.string();
      }),
    })
    .required(),

  controlPerson: Yup.object<KybPersonInput>()
    .when('businessInfo.structure', (structure: KybCompanyType) => {
      let schema = Yup.object().shape<KybPersonInput>({
        firstName: PROFILE_FIRST_NAME_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.firstName.required',
          ),
        )
          .min(2, ({ min }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.firstName.minLength',
              { min },
            ),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.firstName.maxLength',
              { max },
            ),
          ),
        lastName: PROFILE_LAST_NAME_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.lastName.required',
          ),
        )
          .min(2, ({ min }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.lastName.minLength',
              { min },
            ),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.lastName.maxLength',
              { max },
            ),
          ),
        dateOfBirth: Yup.string()
          .nullable()
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.required',
            ),
          )
          .test(
            'minPrincipalAge',
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.minPrincipalAge',
            ),
            (value) => (value ? dateManager().getAge(value) : 0) >= 18,
          )
          .test(
            'maxPrincipalAge',
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.maxPrincipalAge',
            ),
            (value) => (value ? dateManager().getAge(value) : 0) <= 100,
          ),
        ssn: Yup.string()
          .matches(
            /^\d{4}$/,
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ssn.notMatch',
            ),
          )
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ssn.required',
            ),
          ),
        phoneNumber: PHONE_NUMBER_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.phoneNumber.required',
          ),
        ),
        title: Yup.mixed<PrincipalTitles>().required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.title.required',
          ),
        ),
        email: EMAIL_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.email.required',
          ),
        ),
        ownershipPercentage: Yup.number()
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ownershipPercent.required',
            ),
          )
          .max(100, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ownershipPercent.max',
              { max },
            ),
          ),
        zip: Yup.string()
          .required(getI18nErrorMessage('error.address.zipCode.required'))
          .min(5, ({ min }) =>
            getI18nErrorMessage('error.address.zipCode.minLength', { min }),
          ),
        state: Yup.mixed<UsaStates>().required(
          getI18nErrorMessage('error.address.state.required'),
        ),
        city: Yup.string()
          .required(getI18nErrorMessage('error.address.city.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.city.minLength', { min }),
          )
          .max(38, ({ max }) =>
            getI18nErrorMessage('error.address.city.maxLength', { max }),
          ),
        address1: Yup.string()
          .required(getI18nErrorMessage('error.address.address1.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address1.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address1.maxLength', { max }),
          ),
        address2: Yup.string()
          .nullable()
          .optional()
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address2.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address2.maxLength', { max }),
          ),
        relationship: Yup.mixed<PersonRelationship>().optional(),
      });

      const schemaWithRequiredRelationship = schema.shape({
        relationship: Yup.mixed<PersonRelationship>().required(
          getI18nErrorMessage('error.relationship.required'),
        ),
      });

      const availableStatuses = [
        KybCompanyType.LLC,
        KybCompanyType.PRIVATE_PARTNERSHIP,
        KybCompanyType.PRIVATE_CORPORATION,
      ];
      const isAvailableStatus = availableStatuses.includes(structure);

      return isAvailableStatus
        ? schema.concat(schemaWithRequiredRelationship)
        : schema;
    })
    .required(),

  businessAddress: Yup.object<KybBusinessAddressInput>().when(
    'businessInfo.structure',
    (structure: KybCompanyType) => {
      let schema = Yup.object().shape<KybBusinessAddressInput>({
        zip: Yup.string()
          .required(getI18nErrorMessage('error.address.zipCode.required'))
          .min(5, ({ min }) =>
            getI18nErrorMessage('error.address.zipCode.minLength', { min }),
          ),
        state: Yup.mixed<UsaStates>().required(
          getI18nErrorMessage('error.address.state.required'),
        ),
        city: Yup.string()
          .required(getI18nErrorMessage('error.address.city.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.city.minLength', { min }),
          )
          .max(38, ({ max }) =>
            getI18nErrorMessage('error.address.city.maxLength', { max }),
          ),
        address1: Yup.string()
          .required(getI18nErrorMessage('error.address.address1.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address1.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address1.maxLength', { max }),
          ),
        address2: Yup.string()
          .nullable()
          .optional()
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address2.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address2.maxLength', { max }),
          ),
      });

      const availableStatuses = [
        KybCompanyType.LLC,
        KybCompanyType.PRIVATE_PARTNERSHIP,
        KybCompanyType.PRIVATE_CORPORATION,
      ];
      const isAvailableStatus = availableStatuses.includes(structure);

      if (isAvailableStatus) {
        return schema;
      }

      return;
    },
  ),

  owners: Yup.array<KybPersonInput>(
    Yup.object()
      .shape<KybPersonInput>({
        firstName: PROFILE_FIRST_NAME_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.firstName.required',
          ),
        )
          .min(2, ({ min }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.firstName.minLength',
              { min },
            ),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.firstName.maxLength',
              { max },
            ),
          ),
        lastName: PROFILE_LAST_NAME_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.lastName.required',
          ),
        )
          .min(2, ({ min }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.lastName.minLength',
              { min },
            ),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.lastName.maxLength',
              { max },
            ),
          ),
        dateOfBirth: Yup.string()
          .nullable()
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.required',
            ),
          )
          .test(
            'minPrincipalAge',
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.minPrincipalAge',
            ),
            (value) => (value ? dateManager().getAge(value) : 0) >= 18,
          )
          .test(
            'maxPrincipalAge',
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.dateOfBirth.maxPrincipalAge',
            ),
            (value) => (value ? dateManager().getAge(value) : 0) <= 100,
          ),
        ssn: Yup.string()
          .matches(
            /^\d{4}$/,
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ssn.notMatch',
            ),
          )
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ssn.required',
            ),
          ),
        phoneNumber: PHONE_NUMBER_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.phoneNumber.required',
          ),
        ),
        title: Yup.mixed<PrincipalTitles>().required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.title.required',
          ),
        ),
        email: EMAIL_VALIDATION_SCHEMA.required(
          getI18nErrorMessage(
            'form.merchantApplication.error.principals.email.required',
          ),
        ),
        ownershipPercentage: Yup.number()
          .required(
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ownershipPercent.required',
            ),
          )
          .max(100, ({ max }) =>
            getI18nErrorMessage(
              'form.merchantApplication.error.principals.ownershipPercent.max',
              { max },
            ),
          ),
        zip: Yup.string()
          .required(getI18nErrorMessage('error.address.zipCode.required'))
          .min(5, ({ min }) =>
            getI18nErrorMessage('error.address.zipCode.minLength', { min }),
          ),
        state: Yup.mixed<UsaStates>().required(
          getI18nErrorMessage('error.address.state.required'),
        ),
        city: Yup.string()
          .required(getI18nErrorMessage('error.address.city.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.city.minLength', { min }),
          )
          .max(38, ({ max }) =>
            getI18nErrorMessage('error.address.city.maxLength', { max }),
          ),
        address1: Yup.string()
          .required(getI18nErrorMessage('error.address.address1.required'))
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address1.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address1.maxLength', { max }),
          ),
        address2: Yup.string()
          .nullable()
          .optional()
          .min(2, ({ min }) =>
            getI18nErrorMessage('error.address.address2.minLength', { min }),
          )
          .max(50, ({ max }) =>
            getI18nErrorMessage('error.address.address2.maxLength', { max }),
          ),
      })
      .required(
        getI18nErrorMessage(
          'form.merchantApplication.error.principals.required',
        ),
      ),
  ).nullable(),

  bankAccount: Yup.object()
    .shape<BankAccountInput>({
      accountNumber: Yup.string()
        .required(
          getI18nErrorMessage(
            'form.merchantApplication.error.bankAccountInfo.accountNumber.required',
          ),
        )
        .matches(
          /\d{4,17}/,
          getI18nErrorMessage(
            'form.merchantApplication.error.bankAccountInfo.accountNumber.notMatch',
          ),
        ),
      routingNumber: E_CHECK_BANK_ROUTING_NUMBER_VALIDATION_SCHEMA.required(
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.routingNumber.required',
        ),
      ),
      holderName: Yup.string()
        .min(5, ({ min }) =>
          getI18nErrorMessage(
            'form.merchantApplication.error.bankAccountInfo.holderName.minLength',
            { min },
          ),
        )
        .max(50, ({ max }) =>
          getI18nErrorMessage(
            'form.merchantApplication.error.bankAccountInfo.holderName.maxLength',
            { max },
          ),
        )
        .required(
          getI18nErrorMessage(
            'form.merchantApplication.error.bankAccountInfo.holderName.required',
          ),
        ),
      type: Yup.mixed<BankAccountTypes>().required(
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.type.required',
        ),
      ),
    })
    .required(),
});
