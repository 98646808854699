import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import {
  permissionProvider,
  SelectFormField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';

import { ContactStatuses, OrderTypes } from '__generated__/globalTypes';
import ContactPopupModal from 'components/modals/popups/ContactPopupModal';
import { PermissionActions, PermissionResources } from 'constants/permissions';

import {
  FetchContactOptionsQuery,
  FetchContactOptionsQueryVariables,
} from './__generated__/ContactSelectFormField.gql';
import { FETCH_CONTACT_OPTIONS } from './ContactSelectFormField.gql';
import {
  ContactSelectFormFieldPropsType,
  SelectContactType,
} from './ContactSelectFormField.types';

export default function ContactSelectFormField({
  name = 'contact',
  label = 'Contact',
  placeholder = 'Select a contact',
  showLabel = 'onFocus',
  showPlaceholder = 'always',
  contactType,
  exclude = [],
  statuses = [ContactStatuses.ACTIVE],
  allowAddNew,
  onChange,
  ...rest
}: ContactSelectFormFieldPropsType) {
  const [search, setSearch] = useState('');
  const queryVariables: FetchContactOptionsQueryVariables = useMemo(
    () => ({
      filter: { search, statuses, combinedContactType: contactType },
      order: { createdAt: OrderTypes.DESC },
    }),
    [contactType, search, statuses],
  );
  const { loading, data, fetchMore, refetch } = useQuery<
    FetchContactOptionsQuery,
    FetchContactOptionsQueryVariables
  >(FETCH_CONTACT_OPTIONS, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    /**
     * Apollo has an issue when "cache-and-network" or "network-only" are used
     * with "cache-only" at the same time
     * https://github.com/apollographql/apollo-client/issues/6916#issuecomment-811203002
     */
    nextFetchPolicy: 'cache-first',
  });
  const contacts = useMemo(
    () => data?.listContacts.items || [],
    [data?.listContacts.items],
  );
  const { setFieldValue } = useFormContext();

  const withAddNew =
    allowAddNew &&
    permissionProvider().hasPermission(
      PermissionResources.CONTACT,
      PermissionActions.CREATE,
    );

  const { openPopupModal } = usePopupModal(ContactPopupModal, (contact) => {
    setFieldValue(name, contact, true);
    refetch();

    if (onChange) {
      onChange(contact);
    }
  });

  const openAddNewModal = useCallback(() => {
    openPopupModal();
  }, [openPopupModal]);

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: contacts.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        listContacts: {
          ...previousResult.listContacts,
          items: [
            ...previousResult.listContacts.items,
            ...(fetchMoreResult?.listContacts.items || []),
          ],
        },
      }),
    });

    return contacts.length !== data.listContacts.total;
  }, [contacts.length, fetchMore]);

  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch],
  );
  const optionValueResolver = useCallback(
    (contact: SelectContactType) => contact,
    [],
  );
  const optionLabelResolver = useCallback(
    ({ name }: SelectContactType) => name,
    [],
  );
  const selectedOptionsResolver = useCallback(
    (selectedOptions: SelectContactType[]) =>
      selectedOptions.map((option) => option?.name).join(', '),
    [],
  );

  const contactsOptions = useMemo(
    () => contacts.filter(({ id }) => !exclude.includes(id)),
    [contacts, exclude],
  );

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      {...rest}
      withSearch
      clearable
      isLoading={loading}
      options={contactsOptions}
      onSearchChange={onSearchChange}
      selectedOptionsResolver={selectedOptionsResolver}
      optionLabelResolver={optionLabelResolver}
      optionValueResolver={optionValueResolver}
      onLoadOptions={onLoadMore}
      onAdd={withAddNew ? openAddNewModal : undefined}
      onChange={onChange}
    />
  );
}
