import React from 'react';
import { Flex } from '@appclose/ui';
import { StaticField } from '@appclose/core';

import { useIntl } from 'i18n';

import AttachedFile from '../AttachedFile/AttachedFile';
import { StaticFilesFieldPropsType } from './StaticFilesField.types';
import styles from './StaticFilesField.module.scss';

export default function StaticFilesField({
  title,
  files,
}: StaticFilesFieldPropsType) {
  const { t } = useIntl();
  const hasFiles = Boolean(files && files.length);

  return (
    <StaticField title={title || t('form.staticFilesField.attachments')}>
      {hasFiles && (
        <Flex direction="column" className={styles.files}>
          {files?.map((file, i) => <AttachedFile key={i} file={file} />)}
        </Flex>
      )}
    </StaticField>
  );
}
