import React, { useContext, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { Loader, Fieldset, traceError } from '@appclose/core';
import { Button, ButtonGroup, Flex, Grid, Title4 } from '@appclose/ui';

import { CommonAppErrors, QboSyncStatus } from '__generated__/globalTypes';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { WizardContext } from 'components/common/Wizard';
import { I18n, useIntl } from 'i18n';

import { ReactComponent as IntegrationIcon } from './assets/intergration.svg';
import {
  FetchQboQuery,
  FetchQboQueryVariables,
  QboSyncFinishedSubscription,
  QboSyncFinishedSubscriptionVariables,
} from './__generated__/ImportContactsStep.gql';
import { FETCH_QBO, ON_QBO_SYNC_FINISHED } from './ImportContactsStep.gql';
import { ImportContactsStepPropsType } from './ImportContactsStep.types';
import styles from './ImportContactsStep.module.scss';

export default function ImportContactsStep({
  onClose,
}: ImportContactsStepPropsType) {
  const { t } = useIntl();
  const { goToNextStep } = useContext(WizardContext);
  const { loading, data, refetch } = useQuery<
    FetchQboQuery,
    FetchQboQueryVariables
  >(FETCH_QBO);

  useSubscription<
    QboSyncFinishedSubscription,
    QboSyncFinishedSubscriptionVariables
  >(ON_QBO_SYNC_FINISHED, {
    onSubscriptionData: async () => {
      try {
        await refetch();
      } catch (e) {
        traceError(e as Error);
      }
    },
  });

  const status = data?.qbo.syncStatus;
  const isSuccess = status === QboSyncStatus.FINISHED && data?.qbo.connected;
  const inProgress = status === QboSyncStatus.IN_PROGRESS;

  const { expectedCompanyName = '', connectionError } = data?.qbo || {};

  const errorText = useMemo<string | null>(() => {
    if (connectionError === CommonAppErrors.QBO_CANT_SWITCH_COMPANY) {
      return t('qboIntegration.error.cantSwitchCompany', {
        companyName: expectedCompanyName,
      });
    } else if (
      connectionError === CommonAppErrors.QBO_COMPANY_ALREADY_CONNECTED
    ) {
      return t(
        'modal.qboIntegrationSetup.step.importContacts.fail.alreadyLinked',
      );
    } else {
      return t(
        'modal.qboIntegrationSetup.step.importContacts.fail.tryAgainLater',
      );
    }
  }, [connectionError, t, expectedCompanyName]);

  if (loading || inProgress) {
    return <Loader />;
  }

  if (!isSuccess) {
    return (
      <>
        <Fieldset
          title={t(
            'modal.qboIntegrationSetup.step.importContacts.fieldset.title',
          )}
        >
          <Flex direction="column" alignItems="center">
            <ErrorIcon className={styles.statusIcon} />
            <Title4>
              <I18n id="modal.qboIntegrationSetup.step.importContacts.fail.title" />
            </Title4>
            <p className={styles.text}>{errorText}</p>
          </Flex>
        </Fieldset>
        <Flex direction="column" alignItems="center">
          <Button onClick={onClose}>
            <I18n id="modal.qboIntegrationSetup.step.importContacts.fail.okButton" />
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Fieldset
        title={t(
          'modal.qboIntegrationSetup.step.importContacts.fieldset.title',
        )}
      >
        {inProgress ? (
          <Flex direction="column" alignItems="center">
            <IntegrationIcon className={styles.integrationIcon} />
            <p className={styles.text}>
              <I18n id="modal.qboIntegrationSetup.step.importContacts.inProgress" />
            </p>
            <Loader />
          </Flex>
        ) : (
          <>
            <Flex direction="column" alignItems="center">
              <SuccessIcon className={styles.statusIcon} />
              <Title4>
                <I18n id="modal.qboIntegrationSetup.step.importContacts.success.title" />
              </Title4>
              <p className={styles.text}>
                <I18n id="modal.qboIntegrationSetup.step.importContacts.success.subTitle" />
              </p>
            </Flex>
            <Grid columns="1fr auto" gap={[10]}>
              <p className={styles.integrationResult}>
                <I18n id="modal.qboIntegrationSetup.step.importContacts.success.contactsAdded" />
              </p>
              <p className={styles.integrationResultSync}>
                {data?.qbo.syncQty || 0}
              </p>
              <p className={styles.integrationResult}>
                <I18n id="modal.qboIntegrationSetup.step.importContacts.success.contactsFailed" />
                <span>
                  <I18n id="modal.qboIntegrationSetup.step.importContacts.success.contactsFailed.info" />
                </span>
              </p>
              <p className={styles.integrationResultError}>
                {data?.qbo.errorQty || 0}
              </p>
            </Grid>
            <p className={styles.integrationResultInfo}>
              <I18n id="modal.qboIntegrationSetup.step.importContacts.success.resultInfo" />
            </p>
          </>
        )}
      </Fieldset>
      <ButtonGroup>
        <Button className={styles.okButton} onClick={onClose}>
          <I18n id="modal.qboIntegrationSetup.step.importContacts.cancel" />
        </Button>
        <Button onClick={goToNextStep} skin="brand" disabled={inProgress}>
          <I18n id="modal.qboIntegrationSetup.step.importContacts.continue" />
        </Button>
      </ButtonGroup>
    </>
  );
}
