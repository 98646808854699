import React from 'react';
import classnames from 'classnames';
import { PlusMediumIcon, PlusSmallIcon } from '@appclose/icons';

import { Button } from '../Button';

import styles from './AddButton.module.scss';
import { AddButtonType } from './AddButton.types';

const ICON_PATH_SIZE = 10;
const ICON_RATIO = 24 / ICON_PATH_SIZE;

const AddButton = React.forwardRef<HTMLButtonElement, AddButtonType>(
  function AddButtonWithRef(
    {
      onClick,
      className,
      skin = 'secondary',
      icon,
      size = 40,
      iconSize,
      label,
      disabled,
      loading,
      ...props
    },
    ref,
  ) {
    const normalisedIconSize = Math.round(
      parseInt((iconSize || size * 0.4).toString(), 10),
    );

    const computedIconSize = Math.round(normalisedIconSize * ICON_RATIO);

    const PlusIcon =
      icon ??
      (normalisedIconSize > ICON_PATH_SIZE ? PlusMediumIcon : PlusSmallIcon);

    return (
      <Button
        {...props}
        disabled={disabled}
        ref={ref}
        skin="shell"
        onClick={onClick}
        className={classnames(
          styles.button,
          {
            [styles.disabled]: disabled,
            [styles.loading]: loading,
            [styles.primary]: !loading && skin === 'primary',
            [styles.success]: !loading && skin === 'success',
            [styles.secondary]: !loading && skin === 'secondary',
            [styles.brand]: !loading && skin === 'brand',
            [styles.info]: !loading && skin === 'info',
          },
          className,
        )}
      >
        <div
          className={styles.icon}
          style={{ width: size, height: size, backgroundSize: 0.6 * size }}
        >
          <PlusIcon
            className={styles.plus}
            width={computedIconSize}
            height={computedIconSize}
          />
        </div>
        {label && <p className={styles.label}>{label}</p>}
      </Button>
    );
  },
);

export default AddButton;
