import * as Yup from 'yup';

import {
  InvoiceRemindersFormValuesType,
  InvoiceStopToRemind,
} from './InvoiceRemindersForm.types';

export const InvoiceRemindersFormSchema =
  Yup.object().shape<InvoiceRemindersFormValuesType>({
    overdueReminderActive: Yup.boolean().required(),
    prepaymentReminderActive: Yup.boolean().required(),
    overdueReminderType: Yup.mixed<InvoiceStopToRemind>().required(),
    prepaymentReminder: Yup.object({
      dayQtyBefore: Yup.number().required(),
      emailText: Yup.string().required(),
    }).when('prepaymentReminderActive', {
      is: true,
      then: Yup.object({
        dayQtyBefore: Yup.number()
          .nullable()
          .required('Please enter days prior due date')
          .moreThan(0, 'Days prior due date should be more than 0'),
        emailText: Yup.string().required('Please enter an email text'),
      }),
    }),
    overdueReminder: Yup.object({
      overdueDayQty: Yup.number().required(),
      ongoingOverdueDayQty: Yup.number().required(),
      emailText: Yup.string().required(),
    })
      .when('overdueReminderActive', {
        is: true,
        then: Yup.object({
          overdueDayQty: Yup.number()
            .nullable()
            .required('Please enter days after due date passed')
            .moreThan(0, 'Days after due date passed be more than 0'),
          ongoingOverdueDayQty: Yup.number()
            .nullable()
            .required('Please enter repeat every days')
            .moreThan(0, 'Repeat every days should be more than 0'),
          emailText: Yup.string().required('Please enter an email text'),
        }),
      })
      .when('overdueReminderType', {
        is: InvoiceStopToRemind.DAYS_PASSED,
        then: Yup.object({
          stopToRemindAfterDayQty: Yup.number()
            .nullable()
            .required('Please enter days have passed'),
        }),
      })
      .when('overdueReminderType', {
        is: InvoiceStopToRemind.REMINDERS_SENT,
        then: Yup.object({
          stopToRemindAfterSentQty: Yup.number()
            .nullable()
            .required('Please enter reminders are sent'),
        }),
      }),
  });
