import React, { ChangeEvent, Ref, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { useUpdateEffect } from 'react-use';
import { Input } from '@appclose/ui';

import { Instance } from 'flatpickr/dist/types/instance';
import dateManager from '../../../controllers/dateManager';

import {
  INPUT_DATE_MASK,
  INPUT_FORMAT,
  prepareDatePickerValues,
  prepareOutputValues,
} from './DatePicker.constants';
import { DatePickerPropsType } from './DatePicker.types';
import styles from './DatePicker.module.scss';
import { ArrowDownIcon, CalendarIcon } from '@appclose/icons';

flatpickr.defaultConfig = {
  nextArrow: '',
  prevArrow: '',
  // @ts-ignore
  locale: {
    rangeSeparator: ' - ',
  },
};

const DatePicker = ({
  className,
  dateFormat = 'MM/DD/YYYY',
  disabled,
  mode = 'single',
  endOfDate = false,
  onChange,
  outputDateFormat,
  placeholder,
  value,
  timezone = '',
  hasError,
  useHumanReadableFormat,
  allowInput,
  clearable = true,
  name,
  onBlur,
  hasInputField = true,
  inputComponent,
  minDate,
  ...datePickerOptions
}: DatePickerPropsType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [flatpkrInstanse, setFlatpkrInstanse] = useState<Instance>();

  const handleRedraw = useMemo(
    () =>
      debounce(() => {
        if (flatpkrInstanse?.isOpen) {
          flatpkrInstanse?._positionCalendar(flatpkrInstanse?.element);
        }
      }, 10),
    [flatpkrInstanse],
  );

  useUpdateEffect(() => {
    document.addEventListener('scroll', handleRedraw, true);
    document.addEventListener('resize', handleRedraw, true);

    return () => {
      document.removeEventListener('scroll', handleRedraw, true);
      document.removeEventListener('resize', handleRedraw, true);
    };
  }, [handleRedraw]);

  if (mode !== 'single' && allowInput) {
    throw new Error('Manually input has been allowed only for a single mode');
  }

  const getDefaultDate = useCallback(
    () =>
      clearable ? (null as any as Date) : dateManager().parse(minDate).toDate(),
    [clearable, minDate],
  );

  const options = useMemo(
    () => ({
      ...datePickerOptions,
      allowInput,
      mode,
      disableMobile: true,
      minDate,
      formatDate: (date: Date) => {
        if (isOpen && allowInput) {
          return dateManager().parse(date).format(INPUT_FORMAT);
        }

        if (useHumanReadableFormat) {
          return dateManager().getHumanReadableDate(date, {
            format: dateFormat,
          });
        }

        return dateManager().parse(date).format(dateFormat);
      },
      parseDate: (inputDate: string) => {
        if (!inputDate) {
          return getDefaultDate();
        }

        const input = useHumanReadableFormat
          ? flatpkrInstanse?.latestSelectedDateObj
          : inputDate;

        const parsedInputDate = dateManager().parse(input).format(dateFormat);

        const format =
          input && INPUT_DATE_MASK.test(parsedInputDate)
            ? INPUT_FORMAT
            : dateFormat;

        const date = dateManager().parse(parsedInputDate, format, true);

        if (!date.isValid()) {
          return getDefaultDate();
        }

        return date.toDate();
      },
    }),
    [
      datePickerOptions,
      allowInput,
      mode,
      isOpen,
      useHumanReadableFormat,
      dateFormat,
      flatpkrInstanse?.latestSelectedDateObj,
      getDefaultDate,
      minDate,
    ],
  );

  const datePickerValue = useMemo(
    () => prepareDatePickerValues(value, mode, outputDateFormat),
    [value, mode, outputDateFormat],
  );

  const showClearIcon = useMemo(() => {
    return (
      !disabled &&
      clearable &&
      (Array.isArray(datePickerValue)
        ? !!datePickerValue.filter(Boolean).length
        : !!datePickerValue)
    );
  }, [clearable, datePickerValue, disabled]);

  const handleOnChange = useCallback(
    (dates: Date[]) => {
      const resultDates = prepareOutputValues(
        dates,
        mode,
        timezone,
        outputDateFormat,
        endOfDate,
      );

      onChange?.(resultDates);
    },
    [mode, timezone, onChange, outputDateFormat, endOfDate],
  );

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const clearedValue = value.replace(/[^0-9\\/]+/, '');

      if (!clearedValue) {
        flatpkrInstanse?.setDate(getDefaultDate(), true);

        return;
      }

      const [, month, firstSlash, day, secondSlash, year] = [
        ...(clearedValue.match(INPUT_DATE_MASK) || []),
      ];

      const resultDate =
        month &&
        month +
          (day
            ? '/' + day + (year ? '/' + year : secondSlash || '')
            : firstSlash || '');

      if (resultDate) {
        e.target.value = resultDate;
      }

      const date = dateManager().parse(resultDate, INPUT_FORMAT, true);

      if (date.isValid()) {
        flatpkrInstanse?.setDate(date.toDate(), true);
      }
    },
    [flatpkrInstanse, getDefaultDate],
  );

  const handleOnOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOnClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnReady = useCallback(
    (dates: Date[], currentDateString: string, self: Instance) => {
      setFlatpkrInstanse(self);
    },
    [],
  );

  const handleOnClear = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      flatpkrInstanse?.setDate(null as any as Date, true);
    },
    [flatpkrInstanse],
  );

  const handleOnBlur = (e: FocusEvent) => {
    onBlur?.(e);
  };

  useUpdateEffect(() => {
    const dayContainerEl =
      flatpkrInstanse?.element.querySelector<HTMLDivElement>('.dayContainer');

    dayContainerEl?.addEventListener('blur', handleOnBlur, true);

    return () => {
      dayContainerEl?.removeEventListener('blur', handleOnBlur, true);
    };
  }, [flatpkrInstanse?.element]);

  return (
    <Flatpickr
      onChange={handleOnChange}
      options={options}
      // @ts-ignore
      value={datePickerValue}
      onOpen={handleOnOpen}
      onReady={handleOnReady}
      onClose={handleOnClose}
      render={(_, ref: Ref<HTMLInputElement>) => {
        if (inputComponent) {
          return <div ref={ref}>{inputComponent()}</div>;
        }

        return hasInputField ? (
          <div className={classnames(styles.datePicker, className)}>
            <Input
              className={classnames(styles.datePickerInput, {
                [styles.withClear]: showClearIcon,
              })}
              containerClassName={styles.datePickerInputContainer}
              readOnly={!allowInput}
              disabled={disabled}
              hasError={hasError}
              placeholder={placeholder}
              onChange={handleOnInputChange}
              maxLength={INPUT_FORMAT.length}
              name={name}
              ref={ref}
            />
            <ArrowDownIcon
              className={classnames(styles.arrowIcon, isOpen && styles.isOpen)}
            />
            {showClearIcon && (
              <span className={styles.clearIcon} onClick={handleOnClear} />
            )}
          </div>
        ) : (
          <div ref={ref} className={styles.icon}>
            <CalendarIcon />
          </div>
        );
      }}
    />
  );
};

export default DatePicker;
