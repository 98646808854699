import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { BankAccountTypes } from '__generated__/globalTypes';

import { E_CHECK_BANK_ROUTING_NUMBER_VALIDATION_SCHEMA } from 'schemas/validations/e-check';

import { BankAccountFormValuesType } from './BankAccountForm.types';

export const BankAccountFormSchema =
  Yup.object().shape<BankAccountFormValuesType>({
    accountNumber: Yup.string()
      .required(
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.accountNumber.required',
        ),
      )
      .matches(
        /\d{4,17}/,
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.accountNumber.notMatch',
        ),
      ),
    routingNumber: E_CHECK_BANK_ROUTING_NUMBER_VALIDATION_SCHEMA.required(
      getI18nErrorMessage(
        'form.merchantApplication.error.bankAccountInfo.routingNumber.required',
      ),
    ),
    holderName: Yup.string()
      .min(5, ({ min }) =>
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.holderName.minLength',
          { min },
        ),
      )
      .max(50, ({ max }) =>
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.holderName.maxLength',
          { max },
        ),
      )
      .required(
        getI18nErrorMessage(
          'form.merchantApplication.error.bankAccountInfo.holderName.required',
        ),
      ),
    type: Yup.mixed<BankAccountTypes>().required(
      getI18nErrorMessage(
        'form.merchantApplication.error.bankAccountInfo.type.required',
      ),
    ),
  });
