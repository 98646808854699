import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import notification from 'controllers/notification';
import hellosign from 'controllers/hellosign';
import { HelloSignEvents } from 'classes/HelloSign';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import { SignatureTemplateModalPropsType } from './SignatureTemplateModal.types';
import SignatureTemplateForm, {
  SignatureTemplateFormActionsType,
  SignatureTemplateFormValuesType,
} from './components/SignatureTemplateForm';
import { CREATE_SIGNATURE_TEMPLATE } from './SignatureTemplateModal.gql';
import {
  CreateSignatureTemplateMutation,
  CreateSignatureTemplateMutationVariables,
} from './__generated__/SignatureTemplateModal.gql';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function SignatureTemplateModal({
  onClose,
}: SignatureTemplateModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.SIGNATURE_TEMPLATE,
    ActionType.CREATE,
  );
  const initialValues = useMemo<SignatureTemplateFormValuesType>(
    () => ({
      title: '',
      signerRoles: [{ roleName: '' }],
      completeInOrder: false,
      files: { newFiles: [], fileIdsToDelete: [] },
    }),
    [],
  );
  const [createSignatureTemplate] = useMutation<
    CreateSignatureTemplateMutation,
    CreateSignatureTemplateMutationVariables
  >(CREATE_SIGNATURE_TEMPLATE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    async (
      { files, ...values }: SignatureTemplateFormValuesType,
      { setSubmitting }: SignatureTemplateFormActionsType,
    ) => {
      try {
        const result = await createSignatureTemplate({
          variables: {
            input: { ...values, files: files.newFiles },
          },
        });

        onClose();
        hellosign().open(result.data?.template.templateUrl.url as string);

        hellosign().onceOn(HelloSignEvents.CLOSE, () =>
          notification().entityCreated(
            t('modal.signatureTemplate.notification.created'),
          ),
        );

        trackModalClose();
      } catch (e) {
        traceError(e as Error);
        setSubmitting(false);
        throw e;
      }
    },
    [createSignatureTemplate, onClose, trackModalClose, t],
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.signatureTemplate.create.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <SignatureTemplateForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={onConfirmClose}
          onChange={onFormChange}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
