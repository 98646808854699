import { gql } from '@apollo/client';

export const FETCH_BANK_ACCOUNTS = gql`
  query FetchBankAccounts {
    bankAccounts: listBankAccounts {
      id
      holderName
      routingNumber
      accountNumberLast4
      class
      stripeBankAccountStatus
      feeBankAccount {
        truncatedNumber
      }
    }
  }
`;

export const ON_BANK_ACCOUNT_UPDATED = gql`
  subscription BankAccountUpdated {
    bankAccountUpdated {
      id
      holderName
      routingNumber
      accountNumberLast4
      class
    }
  }
`;

export const ON_BANK_ACCOUNT_CREATED = gql`
  subscription BankAccountCreated {
    bankAccountCreated {
      id
    }
  }
`;

export const ON_BANK_ACCOUNT_DELETED = gql`
  subscription BankAccountDeleted {
    bankAccountDeleted {
      id
    }
  }
`;
