import { useContext } from 'react';
import { useFormikContext } from 'formik';

import { FormContext } from '../../Form.context';
import { UseFormContextReturnType } from './useFormContext.types';

export default function useFormContext<
  TValues,
>(): UseFormContextReturnType<TValues> {
  const { addProcess } = useContext(FormContext);
  const formikContext = useFormikContext<TValues>();

  return {
    ...formikContext,
    addProcess,
  };
}
