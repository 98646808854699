import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext, useIsTabletDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { ReceivePaymentFormValuesType } from '../../../../../../PaymentInfoFormsBlock.types';
import { InvoicesFieldTableHeaderPropsType } from './InvoicesFieldTableHeader.types';

export default function InvoicesFieldTableHeader({
  invoices,
}: InvoicesFieldTableHeaderPropsType) {
  const isMobile = useIsTabletDevice();
  const { values, setFieldValue } =
    useFormContext<ReceivePaymentFormValuesType>();

  const selectedAll = useMemo(
    () =>
      (invoices?.length &&
        invoices.every(({ id }) =>
          values.invoices?.some(({ id: valuesId }) => valuesId === id),
        )) ||
      false,
    [invoices, values.invoices],
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('invoices', e.target.checked ? invoices : [], true);
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        {!isMobile && <TableCell />}
        <TableCell>
          <I18n id="modal.receivePayment.form.invoices.table.invoice" />
        </TableCell>
        {!isMobile && (
          <TableCell>
            <I18n id="modal.receivePayment.form.invoices.table.dueDate" />
          </TableCell>
        )}
        <TableCell>
          <I18n id="modal.receivePayment.form.invoices.table.status" />
        </TableCell>
        <TableCell align="right">
          <I18n id="modal.receivePayment.form.invoices.table.balanceDue" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
