import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Flex } from '@appclose/ui';
import { useIntl } from 'i18n';
import { ChevronLeftIcon, ChevronRightIcon } from '@appclose/icons';
import { openConfirmAction } from '@appclose/core';
import { TourContext } from '../../Tour.context';
import styles from './TourNavigation.module.scss';
import { trackWithPageMeta } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';

export const TourNavigation: React.FC = () => {
  const { t } = useIntl();
  const {
    nextStep,
    prevStep,
    endTour,
    skipTour,
    isFirstStep,
    isLastStep,
    tourName,
    currentStepName,
  } = useContext(TourContext);
  const dispatch = useDispatch();

  const handleSkipTour = () => {
    dispatch(
      openConfirmAction({
        name: t('tour.skip.confirmation.title'),
        content: t('tour.skip.confirmation.description'),
        cancelButtonTitle: t('form.common.cancel'),
        okButtonTitle: t('tour.skip.confirmation.action'),
        okButtonSkin: 'brand',
        onConfirm: () => {
          skipTour();

          trackWithPageMeta(EventNames.TOUR_SKIPPED, {
            section: tourName,
            element: currentStepName,
          });
        },
        overlayClassName: styles.modalOverlay,
      }),
    );
  };

  const handleEndTour = () => {
    endTour();

    trackWithPageMeta(EventNames.TOUR_SECTION_COMPLETED, {
      section: tourName,
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap={[8]}>
        <Button
          skin="shell"
          className={styles.iconButton}
          onClick={prevStep}
          disabled={isFirstStep}
        >
          <ChevronLeftIcon className={styles.icon} />
        </Button>
        <Button
          skin="shell"
          className={styles.iconButton}
          onClick={isLastStep ? handleEndTour : nextStep}
        >
          <ChevronRightIcon className={styles.icon} />
        </Button>
      </Flex>
      <Button
        skin="shell"
        className={styles.button}
        onClick={isLastStep ? handleEndTour : handleSkipTour}
      >
        {isLastStep ? t('tour.end') : t('tour.skip')}
      </Button>
    </Flex>
  );
};
