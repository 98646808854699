import React, { ReactElement } from 'react';
import { Loader, Modal, useIsDesktopDevice, Fieldset } from '@appclose/core';

import {
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { ModalSendEmailPreviewPanel } from 'components/common/ModalSendEmailPage';
import { useIntl } from 'i18n';

import { ModalSendEmailPagePropsType } from './ModalSendEmailPage.types';
import styles from './ModalSendEmailPage.module.scss';

export default function ModalSendEmailPage({
  children,
  onClose,
  loading,
  title,
}: ModalSendEmailPagePropsType) {
  const { t } = useIntl();
  const isDesktop = useIsDesktopDevice();
  const [sendEmail, emailPreview] = React.Children.toArray(
    children,
  ) as ReactElement[];

  const previewPanel = !loading && (
    <ModalSendEmailPreviewPanel>
      {React.cloneElement(emailPreview)}
    </ModalSendEmailPreviewPanel>
  );

  return (
    <Modal theme="page" className={styles.modal} onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.content}>
          <ModalPageContent>
            {React.cloneElement(sendEmail, {
              children: isDesktop ? undefined : (
                <Fieldset
                  className={styles.fieldset}
                  title={t('modal.sendEmailPage.emailPreview')}
                  foldable={true}
                  initiallyFolded={true}
                >
                  {previewPanel}
                </Fieldset>
              ),
            })}
          </ModalPageContent>
          {isDesktop && previewPanel}
        </div>
      )}
    </Modal>
  );
}
