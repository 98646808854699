import { useCallback, useMemo } from 'react';
import { useIntl } from 'i18n';
import { QboEntitySyncStatus } from '../../__generated__/globalTypes';

export default function useEntityQboSyncStatusTypes() {
  const { t } = useIntl();

  const entityQboSyncStatusTypes: Record<QboEntitySyncStatus, string> = useMemo(
    () => ({
      [QboEntitySyncStatus.SUCCESS]: t('qboSync.status.success'),
      [QboEntitySyncStatus.FAILED]: t('qboSync.status.failed'),
      [QboEntitySyncStatus.PENDING]: t('qboSync.status.pending'),
    }),
    [t],
  );

  const getEntityQboSyncStatus = useCallback(
    (type?: QboEntitySyncStatus | null) => {
      if (!type) {
        return t('qboSync.status.null');
      }

      return entityQboSyncStatusTypes[type];
    },
    [entityQboSyncStatusTypes, t],
  );

  return { getEntityQboSyncStatus };
}
