import { useCallback } from 'react';
import { BellIcon, EditIcon, DownloadIcon, CloseIcon } from '@appclose/icons';

import useSignatureDocumentActions from 'hooks/useSignatureDocumentActions';
import { useIntl } from 'i18n';

import { ActionsPropsType } from './Actions.types';
import { ActionCard } from 'components/common/ActionCard';
import { Flex } from '@appclose/ui';
import styles from './Actions.module.scss';
import classnames from 'classnames';

export default function Actions({
  id,
  status,
  hasWithoutServiceInfoVersion,
  onClose,
}: ActionsPropsType) {
  const { t } = useIntl();
  const onCancelSignatureDocumentSuccess = useCallback(() => {
    onClose();
  }, [onClose]);
  const {
    onSendSignatureDocumentReminder,
    onEditSignatureDocument,
    onDownloadSignatureDocumentWithAuditTrail,
    onDownloadSignatureDocumentWithoutAuditTrail,
    onDownloadSignatureDocumentAuditTrailOnly,
    onCancelSignatureDocument,
    signatureDocumentAllowedActions: {
      canBeDownloadedWithoutTrail,
      canBeDownloadedTrailOnly,
      canBeDownloaded,
      canBeCanceled,
      canReminderBeSent,
      canBeEdited,
    },
  } = useSignatureDocumentActions({
    id,
    status,
    hasWithoutServiceInfoVersion,
    onCancelSuccess: onCancelSignatureDocumentSuccess,
  });

  const actionCards = [
    canReminderBeSent && (
      <ActionCard
        icon={<BellIcon />}
        title={t('modal.signatureDocumentDetails.action.reminder')}
        onClick={onSendSignatureDocumentReminder}
        key="reminder"
      />
    ),
    canBeEdited && (
      <ActionCard
        icon={<EditIcon />}
        title={t('modal.signatureDocumentDetails.action.edit')}
        onClick={onEditSignatureDocument}
        key="edit"
      />
    ),
    canBeDownloadedWithoutTrail && (
      <ActionCard
        icon={<DownloadIcon />}
        title={t('modal.signatureDocumentDetails.action.downloadWithoutTrail')}
        onClick={onDownloadSignatureDocumentWithoutAuditTrail}
        key="downloadWithoutTrail"
      />
    ),
    canBeDownloaded && (
      <ActionCard
        icon={<DownloadIcon />}
        title={t('modal.signatureDocumentDetails.action.download')}
        onClick={onDownloadSignatureDocumentWithAuditTrail}
        key="download"
      />
    ),
    canBeDownloadedTrailOnly && (
      <ActionCard
        icon={<DownloadIcon />}
        title={t('modal.signatureDocumentDetails.action.downloadAuditOnly')}
        onClick={onDownloadSignatureDocumentAuditTrailOnly}
        key="downloadAuditOnly"
      />
    ),
    canBeCanceled && (
      <ActionCard
        icon={<CloseIcon />}
        title={t('modal.signatureDocumentDetails.action.cancel')}
        onClick={onCancelSignatureDocument}
        key="cancel"
      />
    ),
  ].filter(Boolean);

  if (actionCards.length === 0) {
    return null;
  }

  return (
    <Flex
      gap={[4, 0]}
      justify="space-between"
      alignItems="center"
      className={classnames(styles.container, {
        [styles.single]: actionCards.length === 1,
      })}
    >
      {actionCards}
    </Flex>
  );
}
