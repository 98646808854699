import { memo } from 'react';
import { FormGroup, TextAreaFormField, Fieldset } from '@appclose/core';

import { useIntl } from 'i18n';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';

import { InvoiceAdditionalInfoFieldsetPropsType } from './InvoiceAdditionalInfoFieldset.types';

const InvoiceAdditionalInfoFieldset = memo(
  function InvoiceAdditionalInfoFieldset({
    id,
  }: InvoiceAdditionalInfoFieldsetPropsType) {
    const { t } = useIntl();

    return (
      <Fieldset title={t('modal.invoice.form.fieldset.additionalInfo')}>
        <FormGroup>
          <TextAreaFormField
            name="internalMemo"
            label={t('modal.invoice.form.field.internalMemo')}
          />
        </FormGroup>
        <FormGroup>
          <FilesFormField entityType={Entities.INVOICE} entityId={id} />
        </FormGroup>
      </Fieldset>
    );
  },
);

export default InvoiceAdditionalInfoFieldset;
