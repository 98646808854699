import { CommonAppErrors } from '__generated__/globalTypes';

export const GQL_ERRORS_TO_SKIP_NOTIFICATION = [
  CommonAppErrors.AUTH_SIGNIN_INVALID_CREDENTIALS,
  CommonAppErrors.AUTH_SIGNIN_EMAIL_NOT_ALLOWED,
  CommonAppErrors.AUTH_SIGNIN_INVALID_ACCOUNT_TYPE,
  CommonAppErrors.AUTH_SIGNUP_EMAIL_ALREADY_USED,
  CommonAppErrors.AUTH_SIGNUP_NOT_ALLOWED_COUNTRY,
  CommonAppErrors.AUTH_SIGNUP_NOT_ALLOWED_STATE,
  CommonAppErrors.AUTH_TWO_FACTOR_TOKEN_INVALID,
  CommonAppErrors.AUTH_TWO_FACTOR_CODE_INVALID,
  CommonAppErrors.EMAIL_NOT_VERIFIED,
  CommonAppErrors.FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND,
  CommonAppErrors.NOT_ACTIVE_ACCOUNT,
  CommonAppErrors.NOT_ALLOWED_EMAIL_DOMAIN,
  CommonAppErrors.NOT_ALLOWED_PHONE_NUMBER,
  CommonAppErrors.NOT_ALLOWED_PHONE_NUMBER,
  CommonAppErrors.PAYMENTS_REFUND_UNSETTLED_TRANSACTION,
  CommonAppErrors.PROMO_CODE_EXPIRED,
  CommonAppErrors.PROMO_CODE_NOT_EXISTS,
  CommonAppErrors.SIGNATURE_ALREADY_SIGNED,
  CommonAppErrors.SIGNATURE_DECLINED,
  CommonAppErrors.SIGNATURE_NOT_FOUND,
  CommonAppErrors.SIGNATURE_EMPTY_OR_ENCRYPTED_FILE,
  CommonAppErrors.SIGNATURE_PDF_NOT_UPDATED,
  CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_INVALID,
  CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_INVALID,
  CommonAppErrors.SUB_EXPENSE_CATEGORY_ALREADY_EXISTS,
  CommonAppErrors.SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE,
  CommonAppErrors.SUB_EXPENSE_CATEGORY_HAS_RELATIONS,
  CommonAppErrors.SUB_EXPENSE_CATEGORY_NOT_FOUND,
  CommonAppErrors.THIRD_PARTY_PAYEE_ALREADY_EXISTS,
  CommonAppErrors.THIRD_PARTY_PAYEE_IN_USE,
  CommonAppErrors.TIMER_ACTIVITY_ALREADY_EXISTS,
  CommonAppErrors.TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE,
  CommonAppErrors.TIMER_ACTIVITY_HAS_RELATIONS,
  CommonAppErrors.TIMER_ACTIVITY_NOT_FOUND,
  CommonAppErrors.NOT_ALLOWED_PHONE_NUMBER,
  CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_INVALID,
  CommonAppErrors.SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW,
  CommonAppErrors.CLIENTS_REQUEST_ALREADY_APPROVED,
  CommonAppErrors.CLIENTS_REQUEST_ALREADY_DECLINED,
  CommonAppErrors.UNIQUE_VIOLATION,
  CommonAppErrors.INTERNAL_SERVER_ERROR,
];

export const GQL_ERRORS_TO_SKIP_TRACING = [
  CommonAppErrors.AUTH_SIGNIN_INVALID_CREDENTIALS,
  CommonAppErrors.AUTH_SIGNUP_EMAIL_ALREADY_USED,
];
