import { createIntlCache, createIntl, IntlShape } from 'react-intl';
import { ConfigureIntlType } from './intl.types';
import { IntlMessageIdType } from '../types';
import { defaultIntlValueResolvers } from '../constants';

let intlInstance: IntlShape | null = null;

export function getIntlMessageId<T extends string | IntlMessageIdType>(id: T) {
  return id;
}

export async function configureIntl({ locale, messages }: ConfigureIntlType) {
  const coreMessages = await import(`../messages/${locale}.json`)
    .then((module) => module.default)
    .catch(() => ({}));

  return createIntl(
    {
      locale,
      messages: {
        ...coreMessages,
        ...messages,
      },
    },
    createIntlCache(),
  );
}

export function getI18nErrorMessage<T extends string | IntlMessageIdType>(
  id: T,
  values?: Record<string, string | number>,
) {
  return JSON.stringify({ id, values });
}

export function parseI18nErrorMessage<T extends string | IntlMessageIdType>(
  error: string,
): { id: T; values?: Record<string, string | number> } {
  return JSON.parse(error);
}

export function setIntlInstance(intl: IntlShape) {
  intlInstance = intl;
}

export function i18nMessage<T extends string | IntlMessageIdType>(
  id: T,
  values?: Record<string, string | number>,
) {
  if (!intlInstance) {
    throw Error('I18n has not been initialized');
  }

  return intlInstance.formatMessage(
    { id },
    { ...defaultIntlValueResolvers, ...values },
  );
}
