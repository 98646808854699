import React from 'react';
import { useQuery } from '@apollo/client';
import { FormGroup, Loader, StaticField, Fieldset } from '@appclose/core';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text4,
  Text5,
} from '@appclose/ui';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import SignatureTemplateStatus from 'components/common/SignatureTemplateStatus';
import StaticFilesField from 'components/common/StaticFilesField';

import {
  FetchSignatureTemplateQuery,
  FetchSignatureTemplateQueryVariables,
} from './__generated__/SignatureTemplateDetailsModal.gql';
import { SignatureTemplateDetailsModalPropsType } from './SignatureTemplateDetailsModal.types';
import { FETCH_SIGNATURE_TEMPLATE } from './SignatureTemplateDetailsModal.gql';
import SignatureTemplateActions from './components/SignatureTemplateActions';
import { PermissionResources } from 'constants/permissions';
import { ActionType, useModalTracking } from 'hooks/useModalTracking';

export default function SignatureTemplateDetailsModal({
  id,
  onClose,
}: SignatureTemplateDetailsModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.SIGNATURE_TEMPLATE,
    ActionType.VIEW,
  );
  const handleClose = () => {
    onClose();
    trackModalClose();
  };

  const { loading, data } = useQuery<
    FetchSignatureTemplateQuery,
    FetchSignatureTemplateQueryVariables
  >(FETCH_SIGNATURE_TEMPLATE, { variables: { id } });
  const { title, signerRoles, createdAt, status, completeInOrder } =
    (data?.signatureTemplate ||
      {}) as FetchSignatureTemplateQuery['signatureTemplate'];

  return (
    <ModalPage onClose={handleClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <SignatureTemplateActions
              status={status}
              id={id}
              onClose={handleClose}
            />
            <Fieldset title={t('modal.signatureTemplateDetails.signersRoles')}>
              <FormGroup>
                <Table>
                  <TableBody>
                    {signerRoles.map(({ id, roleName }, index) => (
                      <TableRow key={id} withHover={false}>
                        <TableCell width="50px" align="center">
                          <Text5 color="secondary">{index + 1}</Text5>
                        </TableCell>
                        <TableCell align="left">
                          <Text4>{roleName}</Text4>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </FormGroup>

              <Checkbox
                readOnly
                checked={completeInOrder}
                label={t('modal.signatureTemplateDetails.completeInOrder')}
              />
            </Fieldset>
            <Fieldset
              title={t('modal.signatureTemplateDetails.documentDetails')}
            >
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t('modal.signatureTemplateDetails.createdAt')}
                  >
                    <Date value={createdAt} />
                  </StaticField>
                  <StaticField
                    title={t('modal.signatureTemplateDetails.status')}
                  >
                    <SignatureTemplateStatus status={status} />
                  </StaticField>
                  <StaticFilesField
                    title={t('modal.signatureTemplateDetails.files')}
                    files={[]}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
