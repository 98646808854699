import { memo, useMemo } from 'react';
import { useUpdateEffect, usePrevious } from 'react-use';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';

import { useFormContext } from '../../../../../components/common/Form';

import { FormOnChangeEffectPropsType } from './FormOnChangeEffect.types';
import { FORM_COMPARED_PROPS } from './FormOnChangeEffect.constants';

const FormOnChangeEffect = memo(function FormOnChangeEffect({
  onChange,
}: FormOnChangeEffectPropsType) {
  const data = useFormContext<any>();
  const previousData = usePrevious(data);
  const handleOnChange = useMemo(() => debounce(onChange, 250), [onChange]);

  useUpdateEffect(() => {
    if (
      !isEqual(
        pick(previousData, FORM_COMPARED_PROPS),
        pick(data, FORM_COMPARED_PROPS),
      )
    ) {
      handleOnChange(data);
    }
  }, [data, handleOnChange, previousData]);

  return null;
});

export default FormOnChangeEffect;
