import {
  FormikConfig,
  FormikProps,
  FormikHelpers,
  Field,
  FieldProps,
} from 'formik';
import { Ref, ReactNode } from 'react';
import { ApolloError } from '@apollo/client';

export type { FormikErrors as FormErrors } from 'formik';

export interface FormDataType<FormValues> extends FormikProps<FormValues> {}

export interface FormActions<FormValues> extends FormikHelpers<FormValues> {}

export interface FormPropsType<FormValues>
  extends Pick<
    FormikConfig<FormValues>,
    'initialValues' | 'validationSchema' | 'validate'
  > {
  onSubmit?(
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ): void | Promise<any>;
  children(
    props: FormDataType<FormValues> & { submissionError: FormSubmissionError },
  ): ReactNode;
  unsafeEnableReinitialize?: boolean;
  validateChange?: boolean;
  className?: string;
  withScrollToError?: boolean;
  innerRef?: Ref<FormikProps<FormValues>>;
  onChange?(data: FormDataType<FormValues>): void;
}

export type FormSubmissionError = ApolloError | null;

export type FormFieldProps = FieldProps;

export interface FormContextType {
  submissionError: FormSubmissionError;
  processes: Promise<void>[];
  addProcess(process: Promise<void>): void;
}

export const FormField = Field;
