import { useState } from 'react';
import { DocumentNode } from 'graphql';
import {
  OperationVariables,
  ApolloQueryResult,
  useQuery,
} from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

export default function useImperativeQuery<
  TData extends any,
  TVariables extends OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
): [
  (variables?: TVariables) => Promise<ApolloQueryResult<TData>>,
  { loading: boolean },
] {
  const [loading, setLoading] = useState(false);
  const { refetch } = useQuery<TData, TVariables>(query, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const imperativeQuery = async (
    variables?: TVariables,
  ): Promise<ApolloQueryResult<TData>> => {
    try {
      setLoading(true);

      return (await refetch(variables)) as ApolloQueryResult<TData>;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return [imperativeQuery, { loading }];
}
