import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { setTracingUser } from '@appclose/core';

import { FETCH_PROFILE } from './useProfile.gql';
import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
} from './__generated__/useProfile.gql';

export default function useProfile() {
  const { loading, data, client, error, refetch } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);

  const profile = useMemo(() => data?.profile, [data]);

  useEffect(() => {
    if (profile?.id) {
      setTracingUser(profile.id, {
        onboardStatus: profile.onboardStatus,
      });
    }
  }, [profile?.id, profile?.onboardStatus]);

  return {
    loading,
    profile,
    client,
    error,
    refetch,
    isOwner: profile?.isOwner,
  };
}
