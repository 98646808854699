import { AddButton, Title5 } from '@appclose/ui';

import { checkEsqPermission } from 'controllers/permission';
import { I18n } from 'i18n';

import { EmptyContentPropsType } from './EmptyContent.types';
import styles from './EmptyContent.module.scss';

export default function EmptyContent({
  title,
  text,
  onAdd,
  loading,
  ...permissions
}: EmptyContentPropsType) {
  return (
    <div className={styles.content}>
      <p className={styles.text}>{text || <I18n id="emptyContent.empty" />}</p>
      {onAdd && checkEsqPermission(permissions) && (
        <>
          <AddButton
            size={46}
            skin="primary"
            className={styles.button}
            onClick={onAdd}
            loading={loading}
          />
          {title && <Title5>{title}</Title5>}
        </>
      )}
    </div>
  );
}
