import * as Yup from 'yup';
import { ADDRESS_INPUT_SCHEMA } from 'schemas/validations/address';
import { AddressInput, AddressTypes } from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';

export const ADDRESSES_FIELDSET_SCHEMA = Yup.array<AddressInput>(
  ADDRESS_INPUT_SCHEMA.required(),
).test(
  'hasOnePrimaryAddress',
  getI18nErrorMessage('form.addressesFieldset.error.hasOnePrimaryAddress'),
  (addresses) => {
    if (!addresses?.length) {
      return true;
    }

    const primaryAddresses =
      addresses?.filter(({ type }) => type === AddressTypes.PRIMARY) || [];

    return primaryAddresses.length === 1;
  },
);
