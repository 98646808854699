import * as Yup from 'yup';

import {
  BankAccountClasses,
  PeriodTypes,
  ScheduledPaymentsSettingsInput,
} from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { FetchInvoiceQuery } from '../../__generated__/InvoiceModal.gql';
import {
  INVOICE_PERIOD_MAX,
  INVOICE_PERIOD_MIN,
} from './InvoiceForm.constants';
import { InvoiceFormValuesType } from './InvoiceForm.types';

export const InvoiceFormSchema = Yup.object().shape<
  Omit<InvoiceFormValuesType, 'contactId' | 'createdAt'>
>({
  destinationAccount: Yup.mixed<BankAccountClasses>().required(
    getI18nErrorMessage('modal.invoice.form.error.destinationAccount.required'),
  ),
  contact: Yup.object<FetchInvoiceQuery['invoice']['contact']>().when(
    'destinationAccount',
    {
      is: (destinationAccount) =>
        destinationAccount === BankAccountClasses.OPERATING,
      then: Yup.object<FetchInvoiceQuery['invoice']['contact']>().required(
        getI18nErrorMessage('modal.invoice.form.error.contact.required'),
      ),
    },
  ),
  amount: Yup.number()
    .required(getI18nErrorMessage('modal.invoice.form.error.amount.required'))
    .min(
      1,
      getI18nErrorMessage('modal.invoice.form.error.amount.moreThan', {
        min: 1,
      }),
    ),
  dueDate: Yup.string()
    .nullable()
    .required(getI18nErrorMessage('modal.invoice.form.error.dueDate.required')),
  message: Yup.string()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.invoice.form.error.message.max', { max }),
    )
    .nullable(),
  internalMemo: Yup.string()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.invoice.form.error.internalMemo.max', { max }),
    )
    .nullable(),
  percentageDiscount: Yup.boolean().required(),
  discount: Yup.number()
    .required()
    .when(
      ['destinationAccount', 'total', 'percentageDiscount'],
      (
        destinationAccount: BankAccountClasses,
        total: number,
        percentageDiscount: boolean,
      ) => {
        switch (true) {
          case destinationAccount !== BankAccountClasses.OPERATING:
          case percentageDiscount:
          case !total:
            return Yup.number();
          default:
            return Yup.number().lessThan(
              total,
              getI18nErrorMessage('modal.invoice.form.error.discount.lessThan'),
            );
        }
      },
    ),
  scheduleRecurringPayments: Yup.boolean().required(),
  scheduleLoading: Yup.boolean().required(),
  scheduleSettings: Yup.object<ScheduledPaymentsSettingsInput>()
    .nullable()
    .when('scheduleRecurringPayments', {
      is: true,
      then: Yup.object({
        installments: Yup.number()
          .required(
            getI18nErrorMessage(
              'modal.invoice.form.error.installments.required',
            ),
          )
          .moreThan(1, ({ more }) =>
            getI18nErrorMessage(
              'modal.invoice.form.error.installments.moreThan',
              { more },
            ),
          )
          .max(1000, ({ max }) =>
            getI18nErrorMessage('modal.invoice.form.error.installments.max', {
              max,
            }),
          )
          .nullable(),
        startDate: Yup.string()
          .nullable()
          .required(
            getI18nErrorMessage('modal.invoice.form.error.startDate.required'),
          ),
        invoiceEvery: Yup.number()
          .required(
            getI18nErrorMessage(
              'modal.invoice.form.error.invoiceEvery.required',
            ),
          )
          .moreThan(0, ({ more }) =>
            getI18nErrorMessage(
              'modal.invoice.form.error.invoiceEvery.moreThan',
              { more },
            ),
          )
          .when('invoicePeriod', (invoicePeriod: PeriodTypes | null) => {
            let schema = Yup.number()
              .required(
                getI18nErrorMessage(
                  'modal.invoice.form.error.invoiceEvery.required',
                ),
              )
              .nullable();

            if (!invoicePeriod) {
              return schema;
            }

            return schema
              .min(INVOICE_PERIOD_MIN[invoicePeriod], () =>
                getI18nErrorMessage(
                  'modal.invoice.form.error.invoiceEvery.min',
                ),
              )
              .max(INVOICE_PERIOD_MAX[invoicePeriod], () =>
                getI18nErrorMessage(
                  'modal.invoice.form.error.invoiceEvery.max',
                ),
              );
          }),
        invoicePeriod: Yup.mixed<PeriodTypes>().required(
          getI18nErrorMessage(
            'modal.invoice.form.error.invoicePeriod.required',
          ),
        ),
      }),
    }),
  invoiceNumber: Yup.string()
    .matches(
      /^[A-Za-z0-9].*/,
      getI18nErrorMessage(
        'modal.invoice.form.error.invoiceNumber.firstCharacter',
      ),
    )
    .min(1, ({ min }) =>
      getI18nErrorMessage('modal.invoice.form.error.invoiceNumber.min', {
        min,
      }),
    )
    .max(11, ({ max }) =>
      getI18nErrorMessage('modal.invoice.form.error.invoiceNumber.max', {
        max,
      }),
    ),
  files: FILES_SCHEMA,
});
