import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import {
  ClientCreatedSubscription,
  ClientCreatedSubscriptionVariables,
} from './__generated__/useClientCreatedSubscription.gql';
import { ON_CLIENT_CREATED } from './useClientCreatedSubscription.gql';

export const useClientCreatedSubscription = (
  options: SubscriptionHookOptions<
    ClientCreatedSubscription,
    ClientCreatedSubscriptionVariables
  >,
) => {
  useSubscription<
    ClientCreatedSubscription,
    ClientCreatedSubscriptionVariables
  >(ON_CLIENT_CREATED, options);
};
