import React from 'react';
import classnames from 'classnames';
import { v1 as uuid } from 'uuid';

import { ToggleTypes } from './Toggle.types';
import toggle from './Toggle.module.scss';

export default function Toggle({
  id = undefined,
  active,
  activeDefault,
  name,
  label,
  onChange,
  theme,
  readOnly,
  disabled,
  onBlur,
  className,
}: ToggleTypes): React.ReactElement {
  const toggleId = id || uuid();

  return (
    <div
      className={classnames(
        theme?.container,
        {
          [toggle.readOnly]: readOnly,
          [toggle.disabled]: disabled,
        },
        className,
      )}
    >
      <input
        id={toggleId}
        name={name}
        type="checkbox"
        disabled={disabled}
        checked={active}
        defaultChecked={activeDefault}
        className={toggle.checkbox}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
      <label
        htmlFor={toggleId}
        className={classnames(toggle.label, theme?.label)}
      >
        <div className={classnames(toggle.control, theme?.control)}>
          <div className={classnames(toggle.controlLabel, theme?.controlLabel)}>
            <div
              className={classnames(toggle.controlInner, theme?.controlInner)}
            />
          </div>
          <div
            className={classnames(toggle.controlSwitch, theme?.controlSwitch)}
          />
        </div>
        {label}
      </label>
    </div>
  );
}
