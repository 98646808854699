import * as Yup from 'yup';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

import { getI18nErrorMessage } from 'i18n';

import { SignerTypes } from 'constants/esign';

import { getSignerData } from './SignerFormField.utils';

import { Signer } from './SignersFormField.types';
import { SelectContactType } from 'components/fields/ContactSelectFormField';

export const SIGNERS_SCHEMA = Yup.array()
  .of(
    Yup.object()
      .shape({
        type: Yup.mixed<SignerTypes>().required(),
        roleId: Yup.string(),
        email: Yup.string(),
        client: Yup.object<SelectContactType>().when('type', {
          is: SignerTypes.CLIENT,
          then: Yup.object<SelectContactType>().required(
            getI18nErrorMessage(
              'modal.signatureDocument.form.signers.error.client.required',
            ),
          ),
        }),
        // contact: Yup.object<SelectContactType>().when('type', {
        //   is: SignerTypes.CONTACT,
        //   then: Yup.object<SelectContactType>().required(
        //     getI18nErrorMessage(
        //       'modal.signatureDocument.form.signers.error.contact.required',
        //     ),
        //   ),
        // }),
        // teamMember: Yup.object<SelectTeamMemberType>().when('type', {
        //   is: SignerTypes.MEMBER,
        //   then: Yup.object<SelectTeamMemberType>().required(
        //     getI18nErrorMessage(
        //       'modal.signatureDocument.form.signers.error.teamMember.required',
        //     ),
        //   ),
        // }),
        // externalSigner: Yup.object<
        //   NonNullable<SignerTemplateInput['externalSigner']>
        // >().when('type', {
        //   is: SignerTypes.CUSTOM,
        //   then: Yup.object().shape<
        //     NonNullable<SignerTemplateInput['externalSigner']>
        //   >({
        //     name: Yup.string().required(
        //       getI18nErrorMessage(
        //         'modal.signatureDocument.form.signers.error.externalSigner.name.required',
        //       ),
        //     ),
        //     email: Yup.string()
        //       .test(
        //         'isEmail',
        //         getI18nErrorMessage(
        //           'modal.signatureDocument.form.signers.error.externalSigner.email.test',
        //         ),
        //         (email) => Boolean(email && isEmail(email)),
        //       )
        //       .required(
        //         getI18nErrorMessage(
        //           'modal.signatureDocument.form.signers.error.externalSigner.email.required',
        //         ),
        //       ),
        //   }),
        // }),
      })
      .required(),
  )
  .test(
    'uniqSigner',
    getI18nErrorMessage('modal.signatureDocument.form.signers.error.unique'),
    (signers) => {
      const signersData = signers?.map((signer) =>
        getSignerData(signer as Signer),
      );
      const uniqueSignersCount = uniqWith(signersData, isEqual).length;

      return uniqueSignersCount === signers?.length;
    },
  )
  .required() as Yup.ArraySchema<Signer>;
