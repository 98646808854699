import { useContext, useMemo } from 'react';

import { PermissionsContext } from 'components/common/App/components/PermissionsProvider';

import { PermissionGuardPropsType } from './PermissionsGuard.types';

export default function PermissionsGuard({
  allow,
  children,
}: PermissionGuardPropsType) {
  const { hasPermissions } = useContext(PermissionsContext);
  const isAllowed = useMemo(
    () => hasPermissions(allow),
    [allow, hasPermissions],
  );

  return isAllowed ? children : null;
}
