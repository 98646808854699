import { AllowPermissionType, PermissionsType } from './permission.types';

const hasActionPermission = (actions: string[], action: string) => {
  if (actions.includes('*')) {
    return true;
  }

  return actions.includes(action);
};

export function hasPermission(
  permissions: PermissionsType,
  resource: string,
  action: string,
  resourceId?: string,
): boolean {
  const resourcePermissions = permissions[resource];

  if (!resourcePermissions) {
    return false;
  }

  let resourcePermission = resourcePermissions['*'];

  if (resourceId && resourcePermissions[resourceId]) {
    resourcePermission = resourcePermissions[resourceId];
  }

  if (!resourcePermission) {
    return false;
  }

  const deniedActions = resourcePermission.permissions.deny;

  if (deniedActions) {
    const actionDenied = hasActionPermission(deniedActions, action);

    if (actionDenied) {
      return false;
    }
  }

  return hasActionPermission(resourcePermission.permissions.allow, action);
}

export function hasPermissions(
  permissions: PermissionsType,
  allowPermissions: AllowPermissionType,
): boolean {
  const resources = Object.keys(allowPermissions);

  return resources.some((resource) => {
    const resourcePermission = allowPermissions[resource];

    return Object.keys(resourcePermission).some((resourceId) => {
      const actions = resourcePermission[resourceId].actions;

      return actions.some((action) =>
        hasPermission(permissions, resource, action, resourceId),
      );
    });
  });
}
