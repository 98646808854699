import React from 'react';
import {
  FormGroup,
  InputFormField,
  USAStateSelectFormField,
  ZipCodeFormField,
  MediaQueryDesktop,
  MediaQueryMobile,
  Fieldset,
  UpperFirstInputFormField,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid/FormGrid';
import { useIntl } from 'i18n';

export default function BusinessAddressFieldset() {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t('form.merchantApplication.businessAddressFieldset.title')}
    >
      <FormGroup>
        <MediaQueryDesktop>
          <FormGrid>
            <UpperFirstInputFormField
              name="businessAddress.address1"
              label={t(
                'form.merchantApplication.businessAddressFieldset.address1',
              )}
            />
            <InputFormField
              name="businessAddress.address2"
              label={t(
                'form.merchantApplication.businessAddressFieldset.address2',
              )}
            />
            <InputFormField
              name="businessAddress.city"
              label={t('form.merchantApplication.businessAddressFieldset.city')}
            />
            <USAStateSelectFormField
              showPlaceholder="onFocus"
              name="businessAddress.state"
            />
            <ZipCodeFormField name="businessAddress.zip" />
          </FormGrid>
        </MediaQueryDesktop>
        <MediaQueryMobile>
          <FormGrid>
            <UpperFirstInputFormField
              name="businessAddress.address1"
              label={t(
                'form.merchantApplication.businessAddressFieldset.address1',
              )}
            />
            <InputFormField
              name="businessAddress.address2"
              label={t(
                'form.merchantApplication.businessAddressFieldset.address2',
              )}
            />
            <InputFormField
              name="businessAddress.city"
              label={t('form.merchantApplication.businessAddressFieldset.city')}
            />
            <USAStateSelectFormField
              showPlaceholder="onFocus"
              name="businessAddress.state"
            />
            <ZipCodeFormField name="businessAddress.zip" />
          </FormGrid>
        </MediaQueryMobile>
      </FormGroup>
    </Fieldset>
  );
}
