import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';
import { InputType } from './Input.types';

const Input = React.forwardRef<HTMLInputElement, InputType>(
  (
    {
      autoComplete,
      autoFocus = false,
      className,
      containerClassName,
      defaultValue,
      disabled = false,
      disabledPaste = false,
      hasError = false,
      max,
      maxLength = 255,
      min,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      prefix,
      readOnly = false,
      tabIndex,
      type = 'text',
      value,
      step,
    },
    ref,
  ) => {
    const prefixRef = useRef<HTMLSpanElement>(null);
    const [prefixOffset, setPrefixOffset] = useState<number | undefined>(
      undefined,
    );

    const handleOnPaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (disabledPaste) {
          e.preventDefault();
        }
      },
      [disabledPaste],
    );

    useEffect(() => {
      if (prefixRef.current) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setPrefixOffset(Math.ceil(entry.contentRect.width || 15));
          }
        });

        resizeObserver.observe(prefixRef.current);
      }
    }, []);

    return (
      <div
        className={classnames(
          styles.container,
          {
            [styles.disabled]: disabled,
          },
          containerClassName,
        )}
      >
        {prefix && (
          <span className={styles.prefix} ref={prefixRef}>
            {prefix}
          </span>
        )}
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classnames(
            styles.input,
            {
              [styles.hasError]: hasError,
            },
            className,
          )}
          defaultValue={defaultValue}
          disabled={disabled}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          name={name}
          onPaste={handleOnPaste}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          tabIndex={tabIndex}
          type={type}
          value={value}
          step={step}
          style={{ paddingLeft: prefixOffset }}
        />
      </div>
    );
  },
);

export default Input;
