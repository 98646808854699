import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';

import { getI18nErrorMessage } from '../../i18n';

export const EMAIL_VALIDATION_SCHEMA_OPTIONAL = Yup.string()
  .test(
    'isEmail',
    getI18nErrorMessage('core.error.email.incorrectFormat'),
    (email) => (email ? isEmail(email) : true),
  )
  .max(100, ({ max }) =>
    getI18nErrorMessage('core.error.email.maxLength', { max }),
  );

export const EMAIL_VALIDATION_SCHEMA =
  EMAIL_VALIDATION_SCHEMA_OPTIONAL.required(
    getI18nErrorMessage('core.error.email.required'),
  );
