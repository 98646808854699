import { ScheduledPaymentsSettingsInput } from '__generated__/globalTypes';

import { INVOICE_PERIOD_MIN } from '../../../../../../InvoiceForm.constants';

export function isScheduledPaymentsAllowed(
  scheduleSettings: ScheduledPaymentsSettingsInput | undefined | null,
  amount: number | undefined | null,
) {
  const { installments, invoiceEvery, invoicePeriod, startDate } =
    scheduleSettings || {};

  return !!(
    amount &&
    installments &&
    invoiceEvery &&
    invoicePeriod &&
    startDate &&
    INVOICE_PERIOD_MIN[invoicePeriod] <= invoiceEvery
  );
}
