import { useQuery } from '@apollo/client';
import React from 'react';
import { Grid, Button, Input, Text3 } from '@appclose/ui';
import { useCopyToClipboard, Loader, useIsTabletDevice } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Block from 'components/common/Block';
import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';
import notification from 'controllers/notification';
import { I18n, useIntl } from 'i18n';

import {
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
} from './__generated__/InvoiceLinkModal.gql';
import { FETCH_INVOICE } from './InvoiceLinkModal.gql';
import { InvoiceLinkModalPropsType } from './InvoiceLinkModal.types';
import styles from './InvoiceLinkModal.module.scss';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function InvoiceLinkModal({
  id,
  onClose,
}: InvoiceLinkModalPropsType) {
  const { t } = useIntl();
  const isMobile = useIsTabletDevice();
  const { trackModalClose } = useModalTracking(
    PermissionResources.INVOICE,
    ActionType.VIEW,
  );

  const [, copyToClipboard] = useCopyToClipboard();

  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id },
  });

  const link = data?.invoice?.link || '';

  const handleOnCopyToClipboard = () => {
    copyToClipboard(link);

    track(EventNames.INVOICE_MANUALLY_SENT, {
      uuid_invoice: id,
      method: 'link',
    });

    notification().linkCopied(t('common.linkCopied'));
  };

  return (
    <ModalPage
      onClose={() => {
        onClose();
        trackModalClose(ResolutionType.CANCEL);
      }}
    >
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.invoiceLink.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <Text3 weight="semibold" offset={{ bottom: 36 }}>
          <I18n id="modal.invoiceLink.info" />
        </Text3>
        {loading ? (
          <Loader />
        ) : (
          <Block className={styles.block}>
            <Grid
              align="center"
              gap={[25, 25]}
              columns={isMobile ? 'auto' : '1fr max-content'}
            >
              <Input readOnly className={styles.input} value={link} />
              <Button skin="brand" onClick={handleOnCopyToClipboard}>
                <I18n id="modal.invoiceLink.copy" />
              </Button>
            </Grid>
          </Block>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
