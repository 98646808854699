export enum EventNames {
  ADD_NEW_ENTRY = 'ADD_NEW_ENTRY',
  CANCEL_ENTRY = 'CANCEL_ENTRY',
  USER_LOGOUT = 'USER_LOGOUT',
  INVOICE_MANUALLY_SENT = 'INVOICE_MANUALLY_SENT',
  MAF_FIELD_EDITED = 'MAF_FIELD_EDITED',
  PRIVACY_SWITCHED = 'PRIVACY_SWITCHED',
  SESSION_END = 'SESSION_END',
  SESSION_START = 'SESSION_START',
  SUBSECTION_VISITED = 'SUBSECTION_VISITED',
  PAGE_OPENED = 'PAGE_OPENED',
  MSG_INFO_VIEWED = 'MSG_INFO_VIEWED',
  TOUR_SECTION_COMPLETED = 'TOUR_SECTION_COMPLETED',
  TOUR_SKIPPED = 'TOUR_SKIPPED',
  TOUR_RESTARTED = 'TOUR_RESTARTED',
  TOUR_BY_PAGE_RESTARTED = 'TOUR_BY_PAGE_RESTARTED',
  CALENDAR_TODAY = 'CALENDAR_TODAY',
  CALENDAR_VIEW_CHANGED = 'CALENDAR_VIEW_CHANGED',
  HINT_VIEWED = 'HINT_VIEWED',
  SCREEN_OPENED = 'SCREEN_OPENED',
  SCREEN_CLOSED = 'SCREEN_CLOSED',
  FILTERS_APPLIED = 'FILTERS_APPLIED',
}
