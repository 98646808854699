import React from 'react';
import { StaticField, Fieldset, EmailExternalLink } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import SignatureDocumentStatus from 'components/common/SignatureDocumentStatus';
import { useIntl } from 'i18n';

import { DetailsPropsType } from './Details.types';
import Signers from './components/Signers';
import { Checkbox, Grid, Offset, Text4 } from '@appclose/ui';

export default function Details({ document }: DetailsPropsType) {
  const { t } = useIntl();

  return (
    <>
      <Fieldset
        title={t('modal.signatureDocumentDetails.details.fieldset.signers')}
      >
        <Signers signers={document.signers} />
        {document.completeInOrder && (
          <Offset top="s">
            <Grid
              gap={[12]}
              autoFlow="column"
              alignContent="center"
              justifyContent="start"
            >
              <Checkbox readOnly checked={document.completeInOrder} />
              <Text4 weight="semibold">Signers order is applied</Text4>
            </Grid>
          </Offset>
        )}
      </Fieldset>
      <Fieldset
        title={t('modal.signatureDocumentDetails.details.fieldset.details')}
      >
        <FormGrid adaptive={false}>
          <StaticField
            title={t('modal.signatureDocumentDetails.details.field.date')}
          >
            <Date value={document.createdAt} />
          </StaticField>
          <StaticField
            title={t('modal.signatureDocumentDetails.details.field.status')}
          >
            <SignatureDocumentStatus status={document.status} />
          </StaticField>
        </FormGrid>
      </Fieldset>
      <Fieldset
        title={t('modal.signatureDocumentDetails.details.fieldset.email')}
      >
        <StaticField
          title={t('modal.signatureDocumentDetails.details.fieldset.message')}
        >
          {document.text}
        </StaticField>
        {document.nonSignatoryRecipients && (
          <StaticField
            title={t(
              'modal.signatureDocumentDetails.details.fieldset.recipients',
            )}
          >
            {document.nonSignatoryRecipients.map((r) => (
              <div key={r}>
                <EmailExternalLink email={r} />
              </div>
            ))}
          </StaticField>
        )}
      </Fieldset>
    </>
  );
}
