import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FormGroup,
  PhoneNumberFormField,
  SelectFormField,
  useFormContext,
  useIsDesktopDevice,
} from '@appclose/core';
import { Grid } from '@appclose/ui';
import set from 'lodash/set';
import get from 'lodash/get';

import { useIntl } from 'i18n';
import usePhoneTypes from 'hooks/usePhoneTypes';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import { INITIAL_PHONE, INITIAL_PRIMARY_PHONE } from 'constants/phone';
import { ContactAndEntityFormValuesType } from 'components/modals/pages/ContactModal/components/ContactForm';
import { PhoneNumberInput } from '__generated__/globalTypes';

import { PhoneFieldsetPropsType } from './PhoneFieldset.types';
import { PrimaryCheckbox } from '../PrimaryCheckbox';

export default function PhoneFieldset({
  name = 'phoneNumbers',
  title,
  allowRemoveLast = false,
  limit = 5,
}: PhoneFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.phoneFieldset.defaultTitle');
  const { phoneTypesOptions } = usePhoneTypes();
  const isDesktopDevice = useIsDesktopDevice();
  const { setValues, values } =
    useFormContext<ContactAndEntityFormValuesType>();
  const phoneNumbers: PhoneNumberInput[] = useMemo(
    () => get(values, name),
    [values, name],
  );

  const setPrimaryByIndex = useCallback(
    (primaryIndex: number) => {
      setValues((values) => {
        return set(
          values,
          name,
          phoneNumbers?.map((phoneNumber, index) => ({
            ...phoneNumber,
            isPrimary: index === primaryIndex,
          })),
        );
      });
    },
    [phoneNumbers, name, setValues],
  );

  useEffect(() => {
    if (!phoneNumbers?.some(({ isPrimary }) => isPrimary)) {
      setPrimaryByIndex(0);
    }
  }, [phoneNumbers, setPrimaryByIndex]);

  return (
    <FieldsetFormArray
      allowRemoveLast={allowRemoveLast}
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.phoneFieldset.addButtonLabel')}
      initialData={
        phoneNumbers?.length > 0 ? INITIAL_PHONE : INITIAL_PRIMARY_PHONE
      }
      limit={limit}
    >
      {({ resolveName, index, value }) => (
        <FormGroup>
          <Grid
            gap={isDesktopDevice ? [48, 20] : [0, 20]}
            columns={isDesktopDevice ? 'repeat(2, minmax(0, 1fr))' : '100%'}
          >
            <SelectFormField
              name={resolveName('type')}
              label={t('form.phoneFieldset.type')}
              options={phoneTypesOptions}
            />
            <PhoneNumberFormField name={resolveName('number')} />
          </Grid>
          <PrimaryCheckbox
            name="primaryNumberIndex"
            checked={value.isPrimary}
            onChange={() => setPrimaryByIndex(index)}
            label={t('form.phoneFieldset.primaryLabel')}
          />
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
