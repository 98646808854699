import { useMemo } from 'react';

import { KybBusinessActivityType } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

import { BusinessActivityTypeSelectFormFieldOptionsType } from './BusinessActivityTypeSelectFormField.types';

export function useOptions() {
  const { t } = useIntl();
  const businessActivityTypes = useMemo(
    () => ({
      [KybBusinessActivityType.LEGAL_SERVICES_AND_ATTORNEYS]: t(
        'enum.businessActivityTypes.legalServices',
      ),
    }),
    [t],
  );
  const options: BusinessActivityTypeSelectFormFieldOptionsType = useMemo(
    () =>
      Object.entries(businessActivityTypes).map(([value, label]) => ({
        value: value as KybBusinessActivityType,
        label,
      })),
    [businessActivityTypes],
  );

  return {
    options,
  };
}
