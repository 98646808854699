import { createAction } from 'redux-actions';
import { ConfirmActionType } from '../../../types/confirm.types';

const CONFIRM_ACTION = '@@appclose/core/ui/CONFIRM';
export const CONFIRM_ACTIONS = {
  OPEN: `${CONFIRM_ACTION}/OPEN`,
  CONFIRM: `${CONFIRM_ACTION}/CONFIRM`,
  CLOSE: `${CONFIRM_ACTION}/CLOSE`,
  CANCEL: `${CONFIRM_ACTION}/CANCEL`,
  ERROR: `${CONFIRM_ACTION}/ERROR`,
};

export const openConfirmAction = createAction<ConfirmActionType>(
  CONFIRM_ACTIONS.OPEN,
);
export const confirmConfirmAction = createAction(CONFIRM_ACTIONS.CONFIRM);
export const cancelConfirmAction = createAction(CONFIRM_ACTIONS.CANCEL);
export const closeConfirmAction = createAction(CONFIRM_ACTIONS.CLOSE);
export const errorConfirmAction = createAction(CONFIRM_ACTIONS.ERROR);
