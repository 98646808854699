import { PrincipalTitles } from '__generated__/globalTypes';

import { useIntl } from 'i18n';

import { PrincipalTitleSelectFormFieldOptionsType } from './PrincipalTitleSelectFormField.types';
import { useMemo } from 'react';

export function useOptions() {
  const { t } = useIntl();
  const principalTitles: Record<PrincipalTitles, string> = useMemo(
    () => ({
      [PrincipalTitles.ANALYST]: t('enum.principalTitle.analyst'),
      [PrincipalTitles.CEO]: t('enum.principalTitle.ceo'),
      [PrincipalTitles.CFO]: t('enum.principalTitle.cfo'),
      [PrincipalTitles.COO]: t('enum.principalTitle.coo'),
      [PrincipalTitles.CTO]: t('enum.principalTitle.cto'),
      [PrincipalTitles.DIRECTOR]: t('enum.principalTitle.director'),
      [PrincipalTitles.MANAGER]: t('enum.principalTitle.manager'),
      [PrincipalTitles.OTHER]: t('enum.principalTitle.other'),
      [PrincipalTitles.OWNER]: t('enum.principalTitle.owner'),
      [PrincipalTitles.SUPERVISOR]: t('enum.principalTitle.supervisor'),
    }),
    [t],
  );
  const options: PrincipalTitleSelectFormFieldOptionsType = useMemo(
    () =>
      Object.entries(principalTitles).map(([value, label]) => ({
        value: value as PrincipalTitles,
        label,
      })),
    [principalTitles],
  );

  return {
    options,
  };
}
