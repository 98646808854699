import React, { useCallback } from 'react';
import { Field } from 'formik';

import { SelectType } from '../../../../common/Select';
import { getFieldError } from '../../../../..';
import { SelectField } from '../SelectFied';

import {
  SelectFormFieldWrapperPropsType,
  SelectFormFieldPropsType,
} from './SelectFormField.types';

const SelectFormFieldWrapper = ({
  field,
  form,
  placeholder = 'Select...',
  onChange,
  ...rest
}: SelectFormFieldWrapperPropsType) => {
  const error = getFieldError(form, field.name);

  const handleOnChange = useCallback(
    (data: any) => {
      if (onChange) {
        onChange(data);
      }

      form.setFieldValue(field.name, data, true);
    },
    [form, field, onChange],
  );

  return (
    <SelectField
      {...field}
      {...rest}
      placeholder={placeholder}
      onChange={handleOnChange}
      error={error}
    />
  );
};

const SelectFormField = <TValue extends SelectType>(
  props: SelectFormFieldPropsType<TValue>,
) => {
  return <Field {...props} component={SelectFormFieldWrapper} />;
};

export default SelectFormField;
