import classnames from 'classnames';

import { LabelPropsType } from './Label.types';
import styles from './Label.module.scss';

export default function Label({
  theme = 'primary',
  className,
  children,
}: LabelPropsType) {
  return (
    <span
      className={classnames(
        styles.label,
        {
          [styles.primary]: theme === 'primary',
          [styles.info]: theme === 'info',
          [styles.success]: theme === 'success',
          [styles.warning]: theme === 'warning',
          [styles.default]: theme === 'default',
          [styles.brand]: theme === 'brand',
          [styles.tertiary]: theme === 'tertiary',
          [styles.white]: theme === 'white',
          [styles.secondary]: theme === 'secondary',
          [styles.meta]: theme === 'meta',
        },
        className,
      )}
    >
      {children}
    </span>
  );
}
