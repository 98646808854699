import * as Yup from 'yup';
import { URL_VALIDATION_SCHEMA } from '@appclose/core';

import { ADDRESSES_FIELDSET_SCHEMA } from 'components/form/AddressesFieldset';
import { PHONE_FIELDSET_SCHEMA } from 'components/form/PhoneFieldset';
import { NOTES_VALIDATION_SCHEMA } from 'schemas/validations/notes';
import { CONTACTS_VALIDATION_SCHEMA } from 'schemas/validations/contacts';
import { CONTACT_ENTITY_NAME_VALIDATION_SCHEMA } from 'schemas/validations/contact';
import { getI18nErrorMessage } from 'i18n';

import { EntityFieldsetValuesType } from '../../ContactForm.types';
import { FetchProfileQuery } from '../../../../__generated__/ContactModal.gql';

export const EntityFieldsetSchema =
  Yup.object().shape<EntityFieldsetValuesType>({
    name: CONTACT_ENTITY_NAME_VALIDATION_SCHEMA,
    website: URL_VALIDATION_SCHEMA.nullable(),
    billingContacts: CONTACTS_VALIDATION_SCHEMA.required(),
    addresses: ADDRESSES_FIELDSET_SCHEMA.nullable(),
    phoneNumbers: PHONE_FIELDSET_SCHEMA.nullable(),
    taxable: Yup.boolean().required(),
    conflictsCheckCompleted: Yup.boolean().required(),
    engagementHasBeenSigned: Yup.boolean().required(),
    w9FormSentToClient: Yup.boolean().required(),
    notes: NOTES_VALIDATION_SCHEMA,
    originatingAttorney: Yup.object<FetchProfileQuery['getProfile']>().required(
      getI18nErrorMessage(
        'modal.contact.form.entity.error.originatingAttorney',
      ),
    ),
  });
