import { SignerTypes } from 'constants/esign';
import { Signer } from './SignersFormField.types';

export const getSignerData = (signer: Signer) => {
  // if (signer.type === SignerTypes.CONTACT) {
  //   return {
  //     email: signer.email,
  //     name: signer.contact?.name?.toLowerCase(),
  //   };
  // }

  if (signer.type === SignerTypes.CLIENT) {
    return {
      email: signer.email,
      name: signer.client?.name?.toLowerCase(),
    };
  }

  // if (signer.type === SignerTypes.MEMBER) {
  //   const { firstName = '', lastName = '' } = signer.teamMember || {};

  //   return {
  //     email: signer.email,
  //     name: `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`,
  //   };
  // }

  // if (signer.type === SignerTypes.CUSTOM) {
  //   return {
  //     email: signer.externalSigner?.email,
  //     name: signer.externalSigner?.name?.toLowerCase(),
  //   };
  // }
};
