import React from 'react';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';

import { SignatureDocumentDetailsModalPropsType } from './SignatureDocumentDetailsModal.types';
import { useQuery } from '@apollo/client';
import { FETCH_SIGNATURE_DOCUMENT } from './SignatureDocumentDetailsModal.gql';
import {
  FetchSignatureDocumentQuery,
  FetchSignatureDocumentQueryVariables,
} from './__generated__/SignatureDocumentDetailsModal.gql';
import Details from './components/Details';
import Actions from './components/Actions';
import { ActionType, useModalTracking } from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function SignatureDocumentDetailsModal({
  id,
  onClose,
}: SignatureDocumentDetailsModalPropsType) {
  const { trackModalClose } = useModalTracking(
    PermissionResources.SIGNATURE,
    ActionType.VIEW,
  );
  const { loading, data } = useQuery<
    FetchSignatureDocumentQuery,
    FetchSignatureDocumentQueryVariables
  >(FETCH_SIGNATURE_DOCUMENT, {
    variables: { id },
  });
  const document = data?.document as FetchSignatureDocumentQuery['document'];
  const handleClose = () => {
    onClose();
    trackModalClose();
  };

  return (
    <ModalPage onClose={handleClose}>
      <ModalPageHeader>
        <ModalPageTitle>{document?.title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Actions
              id={document.id}
              status={document.status}
              hasWithoutServiceInfoVersion={
                document.hasWithoutServiceInfoVersion
              }
              onClose={handleClose}
            />
            <Details document={document} />
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
