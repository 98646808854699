import classnames from 'classnames';

import styles from './ExternalLink.module.scss';
import { ExternalLinkPropsType } from './ExternalLink.types';
import { Ellipsis } from '../Ellipsis';
import { isEmptyReactNode } from '../../../utils/isEmptyReactNode';

export default function ExternalLink({
  link,
  openNewTab = true,
  download,
  className,
  style,
  children,
  onClick,
  onAuxClick,
}: ExternalLinkPropsType) {
  if (!link || isEmptyReactNode(children)) {
    return null;
  }

  return (
    <a
      href={link}
      target={openNewTab ? '_blank' : undefined}
      rel="noopener noreferrer"
      download={download}
      onClick={onClick}
      onAuxClick={onAuxClick}
      className={classnames(styles.externalLink, className)}
      style={style}
    >
      {children}
    </a>
  );
}

export function EmailExternalLink({
  email,
}: {
  email: string | undefined | null;
}) {
  if (!email) {
    return null;
  }

  return (
    <ExternalLink link={`mailto:${email}`} openNewTab={false}>
      <Ellipsis as="span">{email}</Ellipsis>
    </ExternalLink>
  );
}

export function PhoneExternalLink({
  phone,
}: {
  phone: string | undefined | null;
}) {
  if (!phone) {
    return null;
  }

  return (
    <ExternalLink link={`tel:${phone}`} openNewTab={false}>
      <Ellipsis as="span">{phone}</Ellipsis>
    </ExternalLink>
  );
}
