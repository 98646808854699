import { StaticField } from '@appclose/core';
import { Flex } from '@appclose/ui';

import { HintWithTracking } from 'components/common/HintWithTracking';
import { QboMappingTargetFieldPropsType } from './QboMappingTargetField.types';
import styles from './QboMappingTargetField.module.scss';

export default function QboMappingTargetField({
  title,
  id,
  tooltip,
}: QboMappingTargetFieldPropsType) {
  return (
    <StaticField className={styles.field}>
      <Flex alignItems="center" className={styles.fieldContent}>
        <p className={styles.title}>{title}</p>
        {tooltip && (
          <HintWithTracking
            iconClassName={styles.hint}
            className={styles.tooltip}
            id={id}
            content={tooltip}
          />
        )}
      </Flex>
    </StaticField>
  );
}
