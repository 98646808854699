import { useCallback, useMemo } from 'react';
import set from 'lodash/set';
import { Button, ButtonGroup } from '@appclose/ui';
import { Tab, TabList, TabPanel, Tabs, useFormContext } from '@appclose/core';

import {
  BankAccountClasses,
  StripeBankAccountStatus,
} from '__generated__/globalTypes';
import BankAccountNotLinked from 'components/common/BankAccountNotLinked';
import { I18n } from 'i18n';

import { InvoiceFormValuesType } from '../../InvoiceForm.types';
import { InvoiceFormContentPropsType } from './InvoiceFormContent.types';
import InvoiceAmountFieldset from './components/InvoiceAmountFieldset';
import PaymentDetailsFieldset from './components/PaymentDetailsFieldset';
import InvoiceAdditionalInfoFieldset from './components/InvoiceAdditionalInfoFieldset';

export default function InvoiceFormContent({
  isEdit,
  bankAccounts,
  onCancel,
}: InvoiceFormContentPropsType) {
  const {
    values: { id, destinationAccount, scheduleLoading },
    setValues,
    isSubmitting,
  } = useFormContext<InvoiceFormValuesType>();
  const tabs = useMemo(
    () =>
      isEdit
        ? [destinationAccount]
        : [BankAccountClasses.OPERATING, BankAccountClasses.TRUST],
    [isEdit, destinationAccount],
  );

  const isOperatingAllowed =
    !isEdit || destinationAccount === BankAccountClasses.OPERATING;
  const account = useMemo(
    () => (bankAccounts || []).find((acc) => acc.class === destinationAccount),
    [bankAccounts, destinationAccount],
  );
  const isAccountConnected =
    account?.stripeBankAccountStatus === StripeBankAccountStatus.CONNECTED;

  const commonFormContent = (
    <>
      <InvoiceAmountFieldset />
      <PaymentDetailsFieldset isEdit={isEdit} />
      <InvoiceAdditionalInfoFieldset id={id} />
      <ButtonGroup>
        <Button onClick={onCancel}>
          <I18n id="form.common.cancel" />
        </Button>
        <Button
          type="submit"
          skin="brand"
          loading={isSubmitting}
          disabled={scheduleLoading}
        >
          <I18n id="form.common.saveAndContinue" />
        </Button>
      </ButtonGroup>
    </>
  );

  const handleOnResetValues = useCallback(() => {
    setValues((values) => {
      let newValues = set(values, 'total', 0);
      newValues = set(newValues, 'amount', 0);
      newValues = set(newValues, 'discount', 0);
      newValues = set(newValues, 'percentageDiscount', false);
      newValues = set(newValues, 'timeEntries', []);
      newValues = set(newValues, 'flatFees', []);
      newValues = set(newValues, 'expenses', []);

      return newValues;
    }, true);
  }, [setValues]);

  const handleOnTabSelect = useCallback(
    (index: number) => {
      setValues(
        (values) => set(values, 'destinationAccount', tabs[index]),
        true,
      );

      handleOnResetValues();
    },
    [setValues, handleOnResetValues, tabs],
  );

  return (
    <Tabs
      selectedIndex={tabs.indexOf(destinationAccount)}
      onSelect={handleOnTabSelect}
    >
      <TabList>
        {isOperatingAllowed && (
          <Tab disabled={isEdit}>
            <I18n id="modal.invoice.form.tab.toOperating" />
          </Tab>
        )}
      </TabList>
      {isOperatingAllowed && (
        <TabPanel>
          {isAccountConnected ? (
            commonFormContent
          ) : (
            <BankAccountNotLinked type={destinationAccount} />
          )}
        </TabPanel>
      )}
    </Tabs>
  );
}
