import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import {
  FormGroup,
  StaticField,
  TextAreaFormField,
  SelectFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import { useIntl } from 'i18n';
import AttachedFilesSelectFormField from 'components/fields/AttachedFilesSelectFormField';
import { getContactName } from 'controllers/contact';
import { NOTIFICATION_EMAIL } from 'constants/env';

import { EmailInfoFieldsetPropsType } from './EmailInfoFieldset.types';
import styles from './EmailInfoFieldset.module.scss';

export default function EmailInfoFieldset({
  from,
  to,
  files,
}: EmailInfoFieldsetPropsType) {
  const { t } = useIntl();
  const formatBillingContact = useCallback(
    (id: string) => {
      const billingContact = to.billingContacts?.find((item) => item.id === id);

      return billingContact ? (
        <React.Fragment key={id}>
          {getContactName(billingContact)}{' '}
          <span className={classnames(styles.field, styles.email)}>
            {`<${billingContact.email}>`}
          </span>
        </React.Fragment>
      ) : null;
    },
    [to.billingContacts],
  );
  const CONTACT_OPTIONS = useMemo(
    () =>
      to.billingContacts?.map((billingContact) => ({
        value: billingContact.id,
        label: formatBillingContact(billingContact.id),
      })),
    [to.billingContacts, formatBillingContact],
  );
  const selectedOptionsResolver = useCallback(
    (selectedOptions: { value: string }[]) =>
      selectedOptions.map(({ value }) => formatBillingContact(value)),
    [formatBillingContact],
  );

  return (
    <>
      <Fieldset title={t('form.sendEmail.emailInfoFieldset.title')}>
        <FormGroup>
          <StaticField title={t('form.sendEmail.emailInfoFieldset.from')}>
            {from}
            <span className={classnames(styles.field, styles.email)}>
              {` <${NOTIFICATION_EMAIL}>`}
            </span>
          </StaticField>
        </FormGroup>
        <FormGroup>
          {to.isEntity ? (
            <span>
              <SelectFormField
                label={t('form.sendEmail.emailInfoFieldset.to')}
                name="contactId"
                options={CONTACT_OPTIONS}
                selectedOptionsResolver={selectedOptionsResolver}
              />
            </span>
          ) : (
            <StaticField title={t('form.sendEmail.emailInfoFieldset.to')}>
              {getContactName(to)}
              <span className={classnames(styles.field, styles.email)}>
                {to.email && ` <${to.email}>`}
              </span>
            </StaticField>
          )}
        </FormGroup>
        <FormGroup>
          <TextAreaFormField
            className={styles.field}
            name="subject"
            label={t('form.sendEmail.emailInfoFieldset.subject')}
          />
        </FormGroup>
        <FormGroup>
          <TextAreaFormField
            className={styles.field}
            name="text"
            label={t('form.sendEmail.emailInfoFieldset.text')}
          />
        </FormGroup>
        {!!files?.length && (
          <AttachedFilesSelectFormField
            name="files"
            label={t('form.sendEmail.emailInfoFieldset.files')}
            files={files}
          />
        )}
      </Fieldset>
      <FormGroup className={styles.accountStatementLinkToggle}>
        <ToggleFormField
          name="includeAccountStatementLink"
          label={t(
            'form.sendEmail.emailInfoFieldset.includeAccountStatementLink',
          )}
        />
      </FormGroup>
    </>
  );
}
