import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormGroup,
  USAStateSelectFormField,
  ZipCodeFormField,
  useFormContext,
  MediaQueryDesktop,
  MediaQueryMobile,
  UpperFirstInputFormField,
} from '@appclose/core';
import { useIntl } from 'i18n';
import set from 'lodash/set';
import get from 'lodash/get';

import FormGrid from 'components/common/FormGrid/FormGrid';
import FieldsetFormArray from 'components/form/FieldsetFormArray';

import { ContactAndEntityFormValuesType } from 'components/modals/pages/ContactModal/components/ContactForm';
import { INITIAL_ADDRESS } from 'constants/address';
import { AddressInput, AddressTypes } from '__generated__/globalTypes';
import { AddressesFieldsetPropsType } from './AddressesFieldset.types';

import { PrimaryCheckbox } from '../PrimaryCheckbox';

export default function AddressesFieldset({
  name = 'addresses',
  title,
  allowRemoveLast = false,
  limit = 5,
}: AddressesFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.addressesFieldset.defaultTitle');
  const { getFieldMeta, setValues, values } =
    useFormContext<ContactAndEntityFormValuesType>();
  const { value } = getFieldMeta<(typeof INITIAL_ADDRESS)[]>(name);
  const primary = value?.find(({ type }) => type === AddressTypes.PRIMARY);
  const [hasPrimary, setHasPrimary] = useState(!!primary);
  const addresses: AddressInput[] = useMemo(
    () => get(values, name),
    [values, name],
  );

  const setPrimaryByIndex = useCallback(
    (primaryIndex: number) => {
      setValues((values) => {
        return set(
          values,
          name,
          addresses?.map((address, index) => ({
            ...address,
            type:
              index === primaryIndex
                ? AddressTypes.PRIMARY
                : AddressTypes.SECONDARY,
          })),
        );
      });
    },
    [addresses, name, setValues],
  );

  useEffect(() => {
    setHasPrimary(!!primary);

    if (!primary) {
      setPrimaryByIndex(0);
    }
  }, [primary, setPrimaryByIndex]);

  const initialData = useMemo(
    () =>
      !hasPrimary
        ? INITIAL_ADDRESS
        : { ...INITIAL_ADDRESS, type: AddressTypes.SECONDARY },
    [hasPrimary],
  );

  return (
    <FieldsetFormArray
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.addressesFieldset.addButtonLabel')}
      allowRemoveLast={allowRemoveLast}
      initialData={initialData}
      limit={limit}
    >
      {({ resolveName, value, index }) => (
        <FormGroup>
          <MediaQueryDesktop>
            <FormGrid>
              <UpperFirstInputFormField
                name={resolveName('city')}
                label={t('form.addressesFieldset.city')}
              />
              <UpperFirstInputFormField
                name={resolveName('address1')}
                label={t('form.addressesFieldset.address1')}
              />
              <USAStateSelectFormField
                showPlaceholder="onFocus"
                name={resolveName('state')}
              />
              <UpperFirstInputFormField
                name={resolveName('address2')}
                label={t('form.addressesFieldset.address2')}
              />
              <ZipCodeFormField name={resolveName('zipCode')} />
            </FormGrid>
            <PrimaryCheckbox
              name={resolveName('type')}
              checked={value.type === AddressTypes.PRIMARY}
              onChange={() => setPrimaryByIndex(index)}
              label={t('form.addressesFieldset.primaryLabel')}
            />
          </MediaQueryDesktop>
          <MediaQueryMobile>
            <FormGrid>
              <UpperFirstInputFormField
                name={resolveName('address1')}
                label={t('form.addressesFieldset.address1')}
              />
              <UpperFirstInputFormField
                name={resolveName('address2')}
                label={t('form.addressesFieldset.address2')}
              />
              <UpperFirstInputFormField
                name={resolveName('city')}
                label={t('form.addressesFieldset.city')}
              />
              <USAStateSelectFormField
                showPlaceholder="onFocus"
                name={resolveName('state')}
              />
              <ZipCodeFormField name={resolveName('zipCode')} />
            </FormGrid>
            <PrimaryCheckbox
              name={resolveName('type')}
              checked={value.type === AddressTypes.PRIMARY}
              onChange={() => setPrimaryByIndex(index)}
              label={t('form.addressesFieldset.primaryLabel')}
            />
          </MediaQueryMobile>
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
