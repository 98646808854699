import { createContext, useCallback, useState, ReactNode } from 'react';
import { TourConfig } from './Tour.types';

type TourManagerContextType = {
  activeTours: { [tourName: string]: TourConfig };
  registerTour: (tourConfig: TourConfig) => void;
  unregisterTour: (tourName: string) => void;
};

const defaultValue: TourManagerContextType = {
  activeTours: {},
  registerTour: () => {},
  unregisterTour: () => {},
};

export const TourManagerContext =
  createContext<TourManagerContextType>(defaultValue);

export const TourManagerProvider = ({ children }: { children: ReactNode }) => {
  const [activeTours, setActiveTours] = useState<{
    [tourName: string]: TourConfig;
  }>({});

  const registerTour = useCallback((tourConfig: TourConfig) => {
    setActiveTours((prev) => ({ ...prev, [tourConfig.name]: tourConfig }));
  }, []);

  const unregisterTour = useCallback((tourName: string) => {
    setActiveTours((prev) => {
      const newTours = { ...prev };
      delete newTours[tourName];

      return newTours;
    });
  }, []);

  return (
    <TourManagerContext.Provider
      value={{ activeTours, registerTour, unregisterTour }}
    >
      {children}
    </TourManagerContext.Provider>
  );
};
