import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { SelectFormField } from '../../index';

import { USA_STATES_OPTIONS } from './USAStateSelectFormField.constants';
import { USAStateSelectFormFieldPropsType } from './USAStateSelectFormField.types';
import { defaultUSAStateOptionLabelResolver } from './USAStateSelectFormField.utils';

export default function USAStateSelect({
  name = 'state',
  label = 'State',
  placeholder = 'Select state...',
  optionLabelResolver = defaultUSAStateOptionLabelResolver,
  ...rest
}: USAStateSelectFormFieldPropsType) {
  const [options, setOptions] = useState(USA_STATES_OPTIONS);

  const onSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        if (!value) {
          setOptions(USA_STATES_OPTIONS);

          return;
        }

        const statesByCodeOptions: typeof USA_STATES_OPTIONS = [];
        const statesByNameOptions: typeof USA_STATES_OPTIONS = [];

        USA_STATES_OPTIONS.forEach((state) => {
          const { value: stateCode, label: stateName } = state;

          if ((value || '').toLowerCase() === stateCode.toLocaleLowerCase()) {
            statesByCodeOptions.push(state);
          } else if (
            (stateName || '')
              .toLowerCase()
              .includes((value || '').toLowerCase())
          ) {
            statesByNameOptions.push(state);
          }
        });

        setOptions([...statesByCodeOptions, ...statesByNameOptions]);
      }, 500),
    [setOptions],
  );
  const handleOnBlur = useCallback(() => {
    setOptions(USA_STATES_OPTIONS);
  }, []);

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      optionLabelResolver={optionLabelResolver}
      {...rest}
      withSearch
      clearable
      onSearchChange={onSearchChange}
      onBlur={handleOnBlur}
    />
  );
}
