import React from 'react';
import { Amount, StaticField, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import PaymentMethodInfo from 'components/common/PaymentMethodInfo';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { useIntl } from 'i18n';
import useBankAccountClasses from 'hooks/useBankAccountClasses';

import { PaymentDetailsFieldsetPropsType } from './PaymentDetailsFieldset.types';
import PaymentStatus from 'components/common/PaymentStatus';

export default function PaymentDetailsFieldset({
  payment: {
    id,
    contact: { name },
    paymentMethod,
    amount,
    bankAccount,
    paymentDate,
    truncatedPAN,
    qboSyncState,
    status,
  },
}: PaymentDetailsFieldsetPropsType) {
  const { t } = useIntl();
  const { getBankAccountClassLabel } = useBankAccountClasses();

  return (
    <Fieldset title={t('modal.paymentDetails.transactionDetails')}>
      <FormGrid>
        <StaticField title={t('modal.paymentDetails.contact')}>
          {name}
        </StaticField>
        <StaticField title={t('modal.paymentDetails.paymentAmount')}>
          <Amount value={amount} />
        </StaticField>
        <StaticField title={t('modal.paymentDetails.destinationAccount')}>
          {getBankAccountClassLabel(bankAccount.class)}
        </StaticField>
        <StaticField title={t('modal.paymentDetails.paymentMethod')}>
          <PaymentMethodInfo
            paymentMethod={paymentMethod}
            truncatedPAN={truncatedPAN}
          />
        </StaticField>
        <StaticField title={t('modal.paymentDetails.transactionDate')}>
          <Date value={paymentDate} />
        </StaticField>
        {status && (
          <StaticField title={t('modal.paymentDetails.paymentStatus')}>
            <PaymentStatus status={status} />
          </StaticField>
        )}
        <StaticField title={t('modal.paymentDetails.qboSyncStatus')}>
          <EntityQboSyncStatus
            entityId={id}
            entityType="payment"
            qboSyncState={qboSyncState || undefined}
          />
        </StaticField>
      </FormGrid>
    </Fieldset>
  );
}
