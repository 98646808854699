export const USA_ZIP_CODE_MASK = '99999';
export const ZIP_CODE_MASK = '999999999999999';

export const SSN_MASK = '999-99-9999';

export const SSN_FREE_MASK = '9999';

export const PHONE_MASK = '999 999 9999';

export const PHONE_LENGTH = PHONE_MASK.replace(/(\d+)[^\d]*/g, '$1').length;
