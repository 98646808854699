import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EditIcon, EsignIcon, TrashIcon } from '@appclose/icons';

import { useIntl } from 'i18n';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { SignatureTemplateStatuses } from '__generated__/globalTypes';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { SIGNATURE_DOCUMENT_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import {
  openEditSignatureTemplateConfirmAction,
  openRemoveSignatureTemplateConfirmAction,
} from 'store/modules/signature/actions';

import { SignatureTemplateActionsPropsType } from './SignatureTemplateActions.types';

export default function SignatureTemplateActions({
  id,
  status,
  onClose,
}: SignatureTemplateActionsPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();

  const openNewDocumentToSignFromTemplateModal = useCallback(
    () => openModal(SIGNATURE_DOCUMENT_MODAL, { templateId: id }),
    [id],
  );
  const onRemoveSignatureTemplate = useCallback(() => {
    dispatch(
      openRemoveSignatureTemplateConfirmAction({
        id,
        onSuccess() {
          onClose();
        },
      }),
    );
  }, [dispatch, id, onClose]);
  const onEditSignatureTemplate = useCallback(() => {
    dispatch(openEditSignatureTemplateConfirmAction({ id }));
  }, [dispatch, id]);

  return (
    <ActionsPanel>
      {status === SignatureTemplateStatuses.READY && (
        <PermissionGuard
          resource={PermissionResources.SIGNATURE}
          action={PermissionActions.CREATE}
        >
          <ActionsPanelButton
            skin="success"
            icon={<EsignIcon />}
            title={t('modal.signatureTemplateDetails.action.esign')}
            onClick={openNewDocumentToSignFromTemplateModal}
          />
        </PermissionGuard>
      )}
      {status === SignatureTemplateStatuses.READY && (
        <PermissionGuard
          resource={PermissionResources.SIGNATURE_TEMPLATE}
          action={PermissionActions.UPDATE}
        >
          <ActionsPanelButton
            skin="secondary"
            icon={<EditIcon />}
            title={t('modal.signatureTemplateDetails.action.edit')}
            onClick={onEditSignatureTemplate}
          />
        </PermissionGuard>
      )}
      <PermissionGuard
        resource={PermissionResources.SIGNATURE_TEMPLATE}
        action={PermissionActions.DELETE}
      >
        <ActionsPanelButton
          skin="warning"
          icon={<TrashIcon />}
          title={t('modal.signatureTemplateDetails.action.delete')}
          onClick={onRemoveSignatureTemplate}
        />
      </PermissionGuard>
    </ActionsPanel>
  );
}
