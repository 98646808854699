import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Flex } from '@appclose/ui';
import { permissionProvider } from '@appclose/core';

import { InvoiceStatusesTypes } from '__generated__/globalTypes';
import { getContactName } from 'controllers/contact';
import Date from 'components/common/Date';
import InvoiceDateAndPaymentStatus from 'components/common/InvoiceDateAndPaymentStatus';
import { CONTACTS_ROUTE } from 'constants/routes';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { I18n } from 'i18n';

import AmountInfo from './components/AmountInfo';
import Actions from './components/Actions';
import Footer from './components/Footer';
import Modifiers from './components/Modifiers';
import { InvoiceInfoPropsType } from './InvoiceInfo.types';
import styles from './InvoiceInfo.module.scss';

export default function InvoiceInfo({
  className,
  mode = 'embedded',
  isMinified = false,
  withoutFirmTimezone = false,
  onViewButtonClick,
  invoice,
  children,
}: InvoiceInfoPropsType) {
  const {
    createdAt,
    dueDate,
    contact,
    status,
    invoiceNumber,
    missedPaymentQty,
    overdueDayQty,
  } = invoice;
  const isCreated = status === InvoiceStatusesTypes.CREATED;
  const isPaid = status === InvoiceStatusesTypes.PAID;
  const isPartiallyPaid = status === InvoiceStatusesTypes.PARTIALLY_PAID;
  const isOverdue = (overdueDayQty || 0) > 0;
  const isMissedPayments = (missedPaymentQty || 0) > 0;
  const isVoided = status === InvoiceStatusesTypes.VOIDED;
  const contactName = getContactName(contact);

  return (
    <section className={className}>
      <div
        className={classnames(styles.cardHead, {
          [styles.cardHeadCreated]: isCreated,
          [styles.cardHeadSuccess]: isPaid || isPartiallyPaid,
          [styles.cardHeadOverdue]: !isPaid && isOverdue,
          [styles.cardHeadVoided]: isVoided,
        })}
      />
      <div
        className={classnames(styles.cardBody, {
          [styles.cardBodyEmail]: mode === 'email',
          [styles.cardBodyVoided]: isVoided,
        })}
      >
        <Flex
          alignItems="baseline"
          justify="space-between"
          className={styles.cardDates}
        >
          <Date
            withoutFirmTimezone={withoutFirmTimezone}
            className={styles.cardLabel}
            value={createdAt}
          />
          {!isPaid && !isVoided && isOverdue ? (
            <InvoiceDateAndPaymentStatus
              dueDate={dueDate}
              overdueDayQty={overdueDayQty}
            />
          ) : (
            <Flex alignItems="baseline">
              <strong className={styles.dueDateLabel}>
                <I18n id="invoiceInfo.dueDate" />
              </strong>
              <Date
                withoutFirmTimezone={withoutFirmTimezone}
                className={styles.cardLabel}
                value={dueDate}
              />
            </Flex>
          )}
        </Flex>
        <Flex alignItems="baseline" justify="space-between">
          <p>
            {!isMinified && (
              <I18n
                id="invoiceInfo.invoiceTo"
                values={{
                  invoiceNumber: <strong>{invoiceNumber}</strong>,
                  contact:
                    mode !== 'payment' &&
                    permissionProvider().hasPermission(
                      PermissionResources.CONTACT,
                      PermissionActions.READ,
                    ) ? (
                      <Link to={`${CONTACTS_ROUTE}/${contact.id}`}>
                        <strong>{contactName}</strong>
                      </Link>
                    ) : (
                      <strong>{contactName}</strong>
                    ),
                }}
              />
            )}
          </p>
          {!isPaid && !isVoided && isMissedPayments && (
            <InvoiceDateAndPaymentStatus
              dueDate={dueDate}
              missedPaymentQty={missedPaymentQty}
            />
          )}
        </Flex>
        <AmountInfo isMinified={isMinified} invoice={invoice} mode={mode} />
        <Modifiers invoice={invoice} />
        {mode === 'active' && <Actions invoice={invoice} />}
        {['email', 'payment', 'embedded'].includes(mode) && (
          <Footer
            mode={mode}
            isMinified={isMinified}
            onViewButtonClick={onViewButtonClick}
            invoice={invoice}
          >
            {children}
          </Footer>
        )}
      </div>
    </section>
  );
}
