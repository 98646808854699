import { Hint } from '@appclose/core';
import { HintPropsType } from '@appclose/core/src/components/common/Hint/Hint.types';
import { EventNames } from 'constants/analytics';
import { trackWithPageMeta } from 'controllers/analytics';

type HintWithTrackingPropsType = { id?: string } & HintPropsType;

export const HintWithTracking = ({
  id,
  onOpen,
  ...rest
}: HintWithTrackingPropsType) => {
  const handleOpen = () => {
    onOpen?.();

    trackWithPageMeta(EventNames.HINT_VIEWED, {
      id: id || rest.content?.toString(),
    });
  };

  return <Hint {...rest} onOpen={handleOpen} />;
};
