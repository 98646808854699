import React, { useMemo } from 'react';
import { SelectFormField } from '@appclose/core';

import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';

import {
  PaymentMethodSelectFormFieldPropsType,
  PaymentMethodSelectOptionsType,
  PaymentMethodSelectOptionType,
} from './PaymentMethodSelectFormField.types';

export default function PaymentMethodSelectFormField({
  name = 'paymentMethod',
  label = 'Payment method',
  availableMethods,
  unavailableMethods = [],
  ...props
}: PaymentMethodSelectFormFieldPropsType) {
  const { paymentMethodsTypesOptions } = usePaymentMethodsTypes();

  const filteredOptions = useMemo(
    () =>
      (availableMethods
        ? availableMethods.reduce<PaymentMethodSelectOptionsType>(
            (acc, method) => [
              ...acc,
              paymentMethodsTypesOptions.find(
                ({ value }) => value === method,
              ) as PaymentMethodSelectOptionType,
            ],
            [],
          )
        : paymentMethodsTypesOptions
      ).filter(({ value }) => !unavailableMethods.includes(value)),
    [availableMethods, paymentMethodsTypesOptions, unavailableMethods],
  );

  return (
    <SelectFormField
      name={name}
      label={label}
      options={filteredOptions}
      {...props}
    />
  );
}
