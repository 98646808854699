import { coreGetI18nErrorMessage, coreI18nMessage } from '@appclose/core';

import { IntlMessageIdType } from '../types';

export function getI18nErrorMessage(
  id: IntlMessageIdType,
  values?: Record<string, string | number>,
) {
  return coreGetI18nErrorMessage(id, values);
}

export function i18nMessage(
  id: IntlMessageIdType,
  values?: Record<string, string | number>,
) {
  return coreI18nMessage(id, values);
}
