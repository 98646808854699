import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'formik';

import { currency, InputField, useFormContext } from '../../../../..';
import { getFieldError } from '../../../../..';

import {
  AmountFormFieldType,
  AmountFormFieldWrapperType,
} from './AmountFormField.types';

function getFormattedValue(value: number, currencySign: string) {
  return currency().format(value).replace(currencySign, '');
}

function AmountFormFieldWrapper({
  field,
  form,
  min = 0,
  max = 9999999.99,
  withCurrency = true,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: AmountFormFieldWrapperType) {
  const { handleBlur } = useFormContext();
  const currencySign = useMemo(() => currency().format(0)[0], []);
  const [isFocused, setFocused] = useState(false);
  const [rawValue, setRawValue] = useState<string>(field.value || '');
  const [formattedValue, setFormattedValue] = useState<string>('');
  const error = getFieldError(form, field.name);

  useEffect(() => {
    setFormattedValue(
      getFormattedValue(
        Number.isNaN(Number(field.value)) ? 0 : field.value,
        currencySign,
      ),
    );
  }, [field.value, currencySign]);

  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setFocused(true);

      onFocus?.(e);
    },
    [onFocus],
  );

  const handleHeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['e', '+', '-'].includes(e.key)) {
        e.preventDefault();
      }
    },
    [],
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setFocused(false);
      handleBlur(e);

      onBlur?.(e);
    },
    [handleBlur, onBlur],
  );

  const handleOnChange = useCallback(
    ({
      target: { value: targetValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      const newRawValue = targetValue
        .replace(/^([0-9]+[\\.,]?[0-9]{0,2}).*$/, '$1')
        .replace(',', '.');
      const amount = parseFloat(newRawValue) || 0;

      if (typeof max === 'number' && amount > max) {
        return;
      }

      if (typeof min === 'number' && amount < min) {
        return;
      }

      setRawValue(newRawValue);
      form.setFieldValue(field.name, amount, true);

      if (onChange) {
        onChange(amount);
      }
    },
    [field, form, onChange, max, min],
  );

  const value = isFocused ? rawValue : formattedValue;

  return (
    <InputField
      {...field}
      {...rest}
      type="text"
      value={value}
      showLabel={value ? 'alwaysMinified' : rest.showLabel}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onKeyDown={handleHeyDown}
      error={error}
      prefix={withCurrency ? currencySign : undefined}
      step="any"
    />
  );
}

const AmountFormField = (props: AmountFormFieldType) => {
  return <Field {...props} component={AmountFormFieldWrapper} />;
};

export default AmountFormField;
