import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import {
  currency,
  FormDataType,
  openConfirmAction,
  traceError,
} from '@appclose/core';

import EmailPreview from 'components/common/EmailPreview';
import { ModalSendEmailPage } from 'components/common/ModalSendEmailPage';
import { getContactName, getEntityPrimaryContact } from 'controllers/contact';
import SendEmailForm, {
  SendEmailFormActionsType,
  SendEmailFormValuesType,
} from 'components/form/SendEmailForm';
import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchDataQuery,
  FetchDataQueryVariables,
  SendInvoiceMutation,
  SendInvoiceMutationVariables,
} from './__generated__/SendInvoiceModal.gql';
import { FETCH_DATA, SEND_INVOICE } from './SendInvoiceModal.gql';
import { SendInvoiceModalPropsType } from './SendInvoiceModal.types';
import {
  ActionType,
  ResolutionType,
  useModalTracking,
} from 'hooks/useModalTracking';
import { PermissionResources } from 'constants/permissions';

export default function SendInvoiceModal({
  id,
  onClose,
}: SendInvoiceModalPropsType) {
  const { t } = useIntl();
  const { trackModalClose } = useModalTracking(
    PermissionResources.INVOICE,
    ActionType.CREATE,
  );
  const dispatch = useDispatch();
  const { filesLoading, files } = useListFiles({
    entityType: Entities.INVOICE,
    input: { filter: { entityIds: [id] } },
  });
  const [sendEmailFormValues, setSendEmailFormValues] =
    useState<SendEmailFormValuesType>();
  const { loading, data } = useQuery<FetchDataQuery, FetchDataQueryVariables>(
    FETCH_DATA,
    { variables: { id: id as string } },
  );
  const [sendInvoice] = useMutation<
    SendInvoiceMutation,
    SendInvoiceMutationVariables
  >(SEND_INVOICE);
  const invoice = data?.invoice;
  const firm = data?.firm;
  const contact = invoice && getEntityPrimaryContact(invoice);
  const initialValues = useMemo<SendEmailFormValuesType>(
    () => ({
      id,
      to: invoice?.contact as any,
      subject: t(
        Boolean(invoice?.scheduledPayments)
          ? 'modal.sendInvoice.subject.scheduled'
          : 'modal.sendInvoice.subject',
        {
          invoice: invoice?.invoiceNumber,
          firm: firm?.name,
        },
      ),
      text: t(
        Boolean(invoice?.scheduledPayments)
          ? 'modal.sendInvoice.message.scheduled'
          : 'modal.sendInvoice.message',
        {
          contact: contact && getContactName(contact),
        },
      ),
      from: firm?.name || '',
      files: files?.items,
      contactId: contact?.id || '',
      includeAccountStatementLink: true,
    }),
    [contact, firm, id, invoice, t, files],
  );

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose: () => {
      onClose();
      trackModalClose(ResolutionType.CANCEL);
    },
  });

  const handleOnSubmit = useCallback(
    (
      { files, from, to, ...values }: SendEmailFormValuesType,
      { setSubmitting }: SendEmailFormActionsType,
    ) =>
      dispatch(
        openConfirmAction({
          content: t('modal.sendInvoice.confirm.send.content', {
            contact: getContactName(invoice?.contact || {}),
            amount: currency().format(invoice?.balanceDue || 0),
          }),
          cancelButtonTitle: t('modal.sendInvoice.confirm.send.button.cancel'),
          okButtonTitle: t('modal.sendInvoice.confirm.send.button.ok'),
          okButtonSkin: 'brand',
          async onConfirm() {
            try {
              await sendInvoice({
                variables: {
                  input: {
                    ...values,
                    files: files?.map(({ id }) => id) as string[],
                  },
                },
              });

              track(EventNames.INVOICE_MANUALLY_SENT, {
                uuid_invoice: id,
                method: 'email',
              });

              onClose();

              notification().emailSent();
              trackModalClose();
            } catch (e) {
              traceError(e as Error);
              setSubmitting(false);
            }
          },
        }),
      ),
    [dispatch, invoice, onClose, trackModalClose, sendInvoice, t, id],
  );

  const handleFormChange = useCallback(
    (data: FormDataType<SendEmailFormValuesType>) => {
      onFormChange(data);
      setSendEmailFormValues(data.values);
    },
    [onFormChange],
  );

  return (
    <ModalSendEmailPage
      title={t('modal.sendInvoice.title')}
      loading={loading || filesLoading}
      onClose={onConfirmClose}
    >
      <SendEmailForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={onConfirmClose}
        onChange={handleFormChange}
      />
      <EmailPreview
        text={sendEmailFormValues?.text || ''}
        invoice={invoice}
        firm={firm}
        files={sendEmailFormValues?.files}
        includeAccountStatementLink={
          sendEmailFormValues?.includeAccountStatementLink
        }
      />
    </ModalSendEmailPage>
  );
}
