import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import toNumber from 'lodash/toNumber';

import {
  UseUrlSearchParamsExtendType,
  UseUrlSearchParamsPropsType,
  UseUrlSearchParamsType,
} from './useUrlSearchParams.types';

export default function useUrlSearchParams<
  TParams extends UseUrlSearchParamsExtendType = {},
>(
  { parseNumbers = true }: UseUrlSearchParamsPropsType = { parseNumbers: true },
): UseUrlSearchParamsType<TParams> {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const searchString = params.toString();
  const searchParams = useMemo(
    () =>
      [...params.entries()].reduce((acc, [key, value]) => {
        const v = toNumber(value);

        return {
          ...acc,
          [key]: parseNumbers && Number.isFinite(v) ? v : value,
        };
      }, {}),
    [parseNumbers, params],
  );

  const getSearchParamsAsString = useCallback(
    (exclude?: (string | number | symbol)[]) => {
      const shadowParams = new URLSearchParams(params.toString());

      exclude?.forEach((v) => shadowParams.delete(v.toString()));

      return shadowParams.toString();
    },
    [params],
  );

  return {
    searchParams,
    searchString,
    getSearchParamsAsString,
  };
}
