import React, { useState, useCallback } from 'react';
import { Tabs as ReactTabs } from 'react-tabs';

import { TabsPropsTypes } from './Tabs.types';

const Tabs = ({
  beforeIndexChange,
  defaultIndex = 0,
  onSelect,
  ...props
}: TabsPropsTypes) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  const onSelectTab = useCallback(
    (nextIndex: number, last: number, event: Event) => {
      const changeTabIndex = () => setSelectedIndex(nextIndex);

      if (typeof beforeIndexChange === 'function') {
        beforeIndexChange(changeTabIndex);
      } else {
        changeTabIndex();
      }

      if (typeof onSelect === 'function') {
        onSelect(nextIndex, last, event);
      }
    },
    [beforeIndexChange, setSelectedIndex, onSelect],
  );

  return (
    <ReactTabs
      selectedIndex={selectedIndex}
      {...props}
      onSelect={onSelectTab}
    />
  );
};

// @ts-ignore
Tabs.tabsRole = 'Tabs';

export default Tabs;
