import React, { useMemo } from 'react';
import {
  Flex,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  AmountFormField,
  dateManager,
  DatePickerFormField,
  Fieldset,
  FormGroup,
  StaticField,
  useFormContext,
  useIsTabletDevice,
} from '@appclose/core';
import { InvoiceIcon } from '@appclose/icons';

import {
  BankAccountClasses,
  InvoiceStatusesTypes,
  PaymentMethodsTypes,
} from '__generated__/globalTypes';
import InvoiceStatus from 'components/common/InvoiceStatus';
import PrivacyValue from 'components/common/PrivacyValue';
import FormGrid from 'components/common/FormGrid';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { sanitizeAmount } from 'controllers/amount';
import { I18n, useIntl } from 'i18n';

import { splitPaymentByInvoices } from './PaymentDetailsFieldset.utils';
import styles from './PaymentDetailsFieldset.module.scss';
import { ReceivePaymentFormValuesType } from '../../../../PaymentInfoFormsBlock.types';

export default function PaymentDetailsFieldset({
  minDatepickerValue,
}: {
  minDatepickerValue?: string;
}) {
  const { t } = useIntl();
  const {
    values: { invoices = [], amount, destinationAccount, paymentMethod },
  } = useFormContext<ReceivePaymentFormValuesType>();
  const isMobile = useIsTabletDevice();
  const isDateDisabled =
    !!paymentMethod &&
    [PaymentMethodsTypes.ACH, PaymentMethodsTypes.CC].includes(paymentMethod);
  const sortedInvoices = useMemo(
    () =>
      (invoices || []).slice().sort((a, b) => {
        const dateA = dateManager().parse(a.dueDate).format(SERVER_DATE_FORMAT);
        const dateB = dateManager().parse(b.dueDate).format(SERVER_DATE_FORMAT);

        if (dateA === dateB) {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        }

        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      }),
    [invoices],
  );

  const payments = splitPaymentByInvoices(amount, sortedInvoices);

  return (
    <Fieldset title={t('modal.receivePayment.paymentDetailsFieldset.title')}>
      <FormGrid>
        <AmountFormField
          name="amount"
          label={t('modal.receivePayment.paymentDetailsFieldset.field.amount')}
        />
        {destinationAccount !== BankAccountClasses.OPERATING && (
          <DatePickerFormField
            useHumanReadableFormat
            name="paymentDate"
            disabled={isDateDisabled}
            label={t('modal.receivePayment.commonFormContent.paymentDate')}
            minDate={dateManager()
              .parse(minDatepickerValue || 0)
              .startOf('day')
              .valueOf()}
            maxDate={dateManager().parse().endOf('day').valueOf()}
            outputDateFormat={SERVER_DATE_FORMAT}
          />
        )}
      </FormGrid>
      <p className={styles.note}>
        <I18n id="modal.receivePayment.paymentDetailsFieldset.note" />
      </p>
      <FormGroup>
        <Grid gap={[0, 5]}>
          {sortedInvoices.map(
            ({ id, balanceDue, invoiceNumber, status }, i) => {
              let previewStatus: InvoiceStatusesTypes = status;

              if (
                payments[i] !== 0 &&
                sanitizeAmount(payments[i]) < sanitizeAmount(balanceDue)
              ) {
                previewStatus = InvoiceStatusesTypes.PARTIALLY_PAID;
              }

              if (sanitizeAmount(payments[i]) === sanitizeAmount(balanceDue)) {
                previewStatus = InvoiceStatusesTypes.PAID;
              }

              return (
                <Grid
                  key={`payment-${id}`}
                  gap={isMobile ? [0, 20] : [60]}
                  columns={!isMobile ? '1fr 140px' : undefined}
                  align="center"
                >
                  <Table className={styles.table}>
                    <TableBody>
                      <TableRow key={id} withHover={false}>
                        <TableCell>
                          <Flex alignItems="center">
                            <InvoiceIcon className={styles.icon} />
                            {invoiceNumber}
                          </Flex>
                        </TableCell>
                        <TableCell>
                          <PrivacyValue>
                            <Amount
                              value={balanceDue}
                              className={styles.balanceDue}
                            />
                          </PrivacyValue>
                        </TableCell>
                        <TableCell>
                          <InvoiceStatus status={previewStatus} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <StaticField
                    title={t(
                      'modal.receivePayment.paymentDetailsFieldset.field.amountToBeApplied',
                    )}
                  >
                    <Amount value={payments[i]} />
                  </StaticField>
                </Grid>
              );
            },
          )}
        </Grid>
      </FormGroup>
    </Fieldset>
  );
}
