import { I18n, useIntl } from 'i18n';

import { Modal } from '@appclose/core';
import { Button, Flex, Title4 } from '@appclose/ui';

import { ReactComponent as SuccessIcon } from 'assets/success.svg';

import { PaymentIntentSuccessPopupModalPropsType } from './PaymentIntentSuccessPopupModal.types';
import styles from './PaymentIntentSuccessPopupModal.module.scss';

export default function PaymentIntentSuccessPopupModal({
  onCancel,
}: PaymentIntentSuccessPopupModalPropsType) {
  const { t } = useIntl();

  return (
    <Modal
      title={t('modal.popup.confirmPaymentInpentSuccess.modalTitle')}
      onClose={onCancel}
    >
      <Flex direction="column" alignItems="center">
        <SuccessIcon className={styles.statusIcon} />
        <Title4>
          <I18n id="modal.popup.confirmPaymentInpentSuccess.title" />
        </Title4>
        <p className={styles.text}>
          <I18n id="modal.popup.confirmPaymentInpentSuccess.subTitle" />
        </p>

        <Button type="button" onClick={onCancel}>
          <I18n id="modal.popup.confirmPaymentInpentSuccess.actions.ok" />
        </Button>
      </Flex>
    </Modal>
  );
}
