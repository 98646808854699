import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_SIGNATURE_DOCUMENT = gql`
  query FetchSignatureDocument($id: ID!) {
    document: getSignature(signatureIdInput: { id: $id }) {
      id
      title
      createdAt
      status
      completeInOrder
      text
      hasWithoutServiceInfoVersion
      nonSignatoryRecipients
      signers {
        id
        status
        updatedAt
        contact {
          id
          name
          email
          isEntity
        }
        teamMember {
          id
          firstName
          lastName
          email
          picture {
            ...Avatar
          }
        }
        externalSigner {
          name
          email
        }
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;
