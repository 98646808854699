import { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { getFileDataUrl } from '@appclose/lib';
import { Button } from '@appclose/ui';
import { TrashIcon } from '@appclose/icons';

import Avatar from 'components/common/Avatar';
import { ALLOWED_ATTACH_IMAGES_MIME_TYPES } from 'constants/file';
import AttachFiles from '../AttachFiles';

import { AvatarAttachPropsType } from './AvatarAttach.types';
import CropModal from './components/CropModal';
import styles from './AvatarAttach.module.scss';

export default function AvatarAttach({
  avatarFile,
  avatar,
  onChange,
  onCrop,
  placeholder,
  type = 'avatar',
}: AvatarAttachPropsType) {
  const [src, setSrc] = useState<string | undefined | null>(
    typeof avatar === 'string' ? avatar : avatar?.url,
  );
  const [cropFile, setCropFile] = useState<File>();

  const hasAvatar =
    !!avatarFile || (typeof avatar === 'string' ? !!avatar : !!avatar?.url);
  const attachFileTheme = useMemo(
    () => ({
      dropZone: classnames({
        [styles.avatarDropZone]: type === 'avatar',
        [styles.logoDropZone]: type === 'logo',
        [styles.withAvatarDropZone]: hasAvatar,
      }),
      dropZoneOverlay: classnames({
        [styles.avatarDropZoneOverlay]: type === 'avatar',
      }),
      dragAndDropLabel: styles.dragAndDropLabel,
    }),
    [hasAvatar, type],
  );

  const handleOnChange = useCallback(
    (files: File[] | undefined) => {
      if (files && files[0]) {
        const file = files[0];

        onChange(file, avatar);
        setCropFile(file);

        getFileDataUrl(file).then((result) => setSrc(result));
      } else {
        onChange(undefined, avatar);
      }
    },
    [avatar, onChange],
  );

  const handleOnDelete = useCallback(() => {
    onChange(undefined, typeof avatar === 'string' ? null : avatar);
  }, [avatar, onChange]);

  const handleOnCropFileSave = useCallback(
    (file: File) => {
      onChange(file, avatar);
      onCrop?.(file, avatar);
      getFileDataUrl(file).then((result) => setSrc(result));
    },
    [avatar, onChange, onCrop],
  );
  const handleOnCropModalClose = useCallback(() => setCropFile(undefined), []);

  return (
    <>
      <AttachFiles
        className={styles.avatarAttach}
        onChange={handleOnChange}
        showAttachedFiles={false}
        accept={ALLOWED_ATTACH_IMAGES_MIME_TYPES}
        label=""
        theme={attachFileTheme}
      >
        {!hasAvatar ? (
          <div className={styles.placeholder}>{placeholder}</div>
        ) : type === 'avatar' ? (
          <div className={styles.avatarContainer}>
            <Avatar icon={src} size={parseInt(styles.avatarSize, 10)} />
            <Button
              className={styles.avatarDeleteButton}
              onClick={handleOnDelete}
              skin="shell"
            >
              <TrashIcon className={styles.deleteIcon} />
            </Button>
          </div>
        ) : (
          <div className={styles.logoContainer}>
            <img className={styles.logoImg} src={src || ''} alt="" />
            <Button
              className={styles.logoDeleteButton}
              onClick={handleOnDelete}
              skin="shell"
            >
              <TrashIcon className={styles.deleteIcon} />
            </Button>
          </div>
        )}
      </AttachFiles>
      {cropFile && (
        <CropModal
          file={cropFile}
          onSave={handleOnCropFileSave}
          onClose={handleOnCropModalClose}
        />
      )}
    </>
  );
}
